import { DataPoint } from "../../utils/prometheus";
import { useEffect, useState } from "react";
import { ParentSize } from "@visx/responsive";

import {
    Annotation,
    AnnotationLineSubject,
    EventHandlerParams,
    GlyphSeries,
    LineSeries,
    XYChart,
} from "@visx/xychart";
import { CircleSubject } from "@visx/annotation";
import {
    DISTANCE_X_THRESHOLD,
    Gridline,
    NumberSeriesProps,
    StrokeStyle,
    XDateAxis,
    YAxis,
    xAccessor,
    yAccessor,
} from "./Graph";
import AnnotationLabel from "./AnnotationLabel";
import EmptyGraph from "./EmptyGraph";
import { Loader } from "./Loader";

export interface Props extends NumberSeriesProps {
    annotationLabelFormat?: (value: number) => string;
    configs: { [key: string]: LineConfig };
    yTickFormat?: (current: string) => string;
    yTickAutoscale?: boolean;
    data?: { [key: string]: DataPoint[] };
    type?: string;
    isLoaded?: boolean;
    showColumns?: boolean;
    hideTooltip?: boolean;
}

export interface LineConfig {
    stroke: string;
    lineLabel: string;
    strokeStyle?: StrokeStyle;
    yAccessor?: (d: { y: number }) => number;
}

interface PointerDatum extends DataPoint {
    key: string;
    index: number;
}

const DEFAULT_LABEL_FORMAT = (v: number) => `${v}`;

function MultiNumberSeries({
    annotationLabelFormat = DEFAULT_LABEL_FORMAT,
    width,
    height,
    configs,
    startDate,
    endDate,
    yDomain,
    yTickFormat,
    yTickAutoscale,
    type,
    isLoaded = false,
    hideTooltip = false,
    ...restProps
}: Props): JSX.Element {
    const [pointerDatum, setPointerDatum] = useState<PointerDatum>();
    const [pointerXY, setPointerXY] = useState<{ x: number; y: number }>();
    const [distanceX, setDistanceX] = useState<number>();
    const [data, setData] = useState<Props["data"]>(restProps.data);
    const [isDataLoaded, setIsDataLoaded] = useState(isLoaded);

    useEffect(() => {
        setData(restProps.data);
    }, [restProps.data]);

    useEffect(() => {
        setIsDataLoaded(isLoaded);
    }, [isLoaded]);

    const keys: string[] = Object.keys(configs);

    if (isEmptyData(data, keys) && isDataLoaded) {
        return (
            <EmptyGraph
                startDate={startDate}
                endDate={endDate}
                width={width}
                height={height}
            />
        );
    } else if (!isEmptyData(data, keys) && !isDataLoaded) {
        //setting data true in case we have data and for some reason the wrong/no prop is passed (failsafe)
        setIsDataLoaded(true);
    }
    const yScale = yDomain
        ? {
              type: "linear" as const,
              domain: [yDomain.min, yDomain.max],
              zero: false,
          }
        : {
              type: "linear" as const,
          };
    const xScale = {
        type: "time" as const,
        domain: [startDate, endDate],
    };
    return isDataLoaded ? (
        <XYChart
            width={width}
            height={height}
            xScale={xScale}
            yScale={yScale}
            onPointerMove={(params: EventHandlerParams<DataPoint>) => {
                setPointerDatum({
                    ...params.datum,
                    key: params.key,
                    index: params.index,
                });
                if (params.svgPoint) {
                    setPointerXY(params.svgPoint);
                    if (params.distanceX) {
                        setDistanceX(params.distanceX);
                    }
                }
            }}
            onPointerOut={() => {
                setPointerDatum(undefined);
                setPointerXY(undefined);
                setDistanceX(undefined);
            }}
        >
            <XDateAxis />
            <YAxis scaleTickToFit={yTickAutoscale} tickFormat={yTickFormat} />
            <Gridline />
            {keys.map(
                (key) =>
                    data &&
                    key in data &&
                    data[key].length > 0 &&
                    (type === "scatter" ? (
                        <GlyphSeries
                            key={key}
                            dataKey={key}
                            data={data[key]}
                            xAccessor={xAccessor}
                            yAccessor={configs[key].yAccessor || yAccessor}
                            size={5}
                            colorAccessor={() =>
                                configs[key]?.stroke || "#8474DC"
                            }
                        />
                    ) : (
                        <LineSeries
                            key={key}
                            dataKey={key}
                            data={data[key]}
                            xAccessor={xAccessor}
                            yAccessor={configs[key].yAccessor || yAccessor}
                            stroke={configs[key]?.stroke}
                            strokeDasharray={
                                configs[key].strokeStyle == "dotted"
                                    ? "1,3"
                                    : undefined
                            }
                        />
                    ))
            )}
            {pointerXY ? (
                <AnnotationLineSubject
                    x={pointerXY.x}
                    stroke="#E2E1EF"
                    strokeWidth={2}
                />
            ) : null}
            {pointerDatum ? (
                <Annotation dataKey={pointerDatum.key} datum={pointerDatum}>
                    <CircleSubject
                        stroke="#FFFFFF"
                        radius={5}
                        fill={configs[pointerDatum.key]?.stroke}
                        strokeWidth={1.5}
                        filter="drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12))"
                    />
                </Annotation>
            ) : null}
            {pointerXY &&
            pointerDatum &&
            distanceX &&
            distanceX < DISTANCE_X_THRESHOLD &&
            !hideTooltip ? (
                <AnnotationLabel
                    x={pointerXY.x}
                    y={pointerXY.y}
                    graphWidth={width}
                    label={configs[pointerDatum.key]?.lineLabel}
                    stroke={configs[pointerDatum.key]?.stroke}
                    labelValue={annotationLabelFormat(
                        (configs[pointerDatum.key].yAccessor || yAccessor)(
                            pointerDatum
                        )
                    )}
                    xValue={xAccessor(pointerDatum)}
                    strokeStyle={configs[pointerDatum.key]?.strokeStyle}
                />
            ) : null}
        </XYChart>
    ) : (
        <Loader height={height} />
    );
}

function isEmptyData(data: Props["data"], keys: string[]): boolean {
    if (!data) {
        return true;
    }

    return !keys.some((key) => key in data && data[key].length > 0);
}

const MultiNumberSeriesResponsive = (props: Props) => (
    <ParentSize>
        {({ width }) => <MultiNumberSeries {...props} width={width} />}
    </ParentSize>
);

export default MultiNumberSeriesResponsive;
