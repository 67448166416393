import React, { useCallback, useMemo, useState } from "react";
import {
    Handle,
    Position,
    NodeProps,
    useUpdateNodeInternals,
    useStore,
    Node,
} from "reactflow";
import classnames from "classnames";

import sharedStyles from "./Node.module.scss";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import TableIcon from "icons/table.svg";
import type { DAGNodeData, NodeChild } from "./types";
import { useIsFocusedNode, useSelectedNode, useSetNodes } from "./DAGProvider";
import {
    getChildIcon,
    getColorset,
    getHandleStyle,
    getSuffixElement,
    getTargetIcon,
    targetHandleStyles,
} from "./node.utils";
import { useUpdateEntityParams } from "../../../context/CurrentView";

type HandleChildEvents = {
    onMouseEnter: (label: string) => void;
    onMouseLeave: () => void;
    onClick: (label: string) => void;
    onNavigate: (path: string) => void;
};

interface ChildProps {
    child: NodeChild;
    type: string;
    isSelected: boolean;
    handleChildEvents: HandleChildEvents;
    isExpandable: boolean;
    color: string;
}

const _ChildElement = ({
    child,
    type,
    isSelected,
    handleChildEvents,
    isExpandable,
    color,
}: ChildProps) => (
    <div
        className={classnames(sharedStyles.child, {
            [sharedStyles.selectedChild]: isSelected,
        })}
        onMouseEnter={() => handleChildEvents.onMouseEnter(child.label)}
        onMouseLeave={() => handleChildEvents.onMouseLeave()}
        onClick={() => handleChildEvents.onClick(child.label)}
    >
        {type === "source" && (
            <div className={sharedStyles.tableIcon}>
                <TableIcon />
            </div>
        )}
        <span className={sharedStyles.label} title={child.label}>
            {child.label}
        </span>
        <span className={sharedStyles.sublabel}>{child.sublabel}</span>
        {child.icons?.map((icon, index) => (
            <div key={index} className={sharedStyles.childIcon}>
                {getChildIcon(icon)}
            </div>
        ))}
        {child.path && (
            <div className={sharedStyles.childIcon}>
                <ArrowNarrowUpRightIcon
                    onClick={(e: Event) => {
                        e.stopPropagation();
                        handleChildEvents.onNavigate(child.path as string);
                    }}
                    color="#000"
                />
            </div>
        )}
        {!isExpandable && (
            <Handle
                type="source"
                position={Position.Right}
                id={child.label}
                style={getHandleStyle(color)}
            />
        )}
    </div>
);

const ChildElement = React.memo(_ChildElement);

const nodesSelector = (state: any) => state.getNodes;

function EntityNode({ id, data, selected, type }: NodeProps<DAGNodeData>) {
    const getNodes = useStore(nodesSelector);
    const allNodes = getNodes();
    const currentNode = useMemo(
        () => allNodes.find((node: Node) => node.id === id),
        [allNodes, id]
    );
    const { updateEntityParams: navigate } = useUpdateEntityParams();
    const updateNodeInternals = useUpdateNodeInternals();
    const focused = useIsFocusedNode(id);
    const { setLayoutNodes } = useSetNodes();
    const { selectedNode, setSelectedSourceHandle, selectedSourceHandle } =
        useSelectedNode();
    const [defaultCurrentHandle, setDefaultHandle] =
        useState(selectedSourceHandle);
    const colorset = getColorset(type);
    const Icon = getTargetIcon(type, data);

    const onNodeExpandToggle = () => {
        if (currentNode?.data) {
            const isNodeExpanded = (currentNode.data as DAGNodeData).isExpanded;
            (currentNode.data as DAGNodeData).isExpanded = isNodeExpanded
                ? !isNodeExpanded
                : true;
        }
        updateNodeInternals(id);
        setLayoutNodes({
            direction: "RIGHT",
            useInitialNodes: false,
            newNodes: allNodes,
        });
    };

    const setDefaultCurrentHandle = useCallback(
        (handle: string) => {
            setDefaultHandle(handle);
            setSelectedSourceHandle(handle);
        },
        [setDefaultHandle, setSelectedSourceHandle]
    );

    const handleChildEvents = useMemo(
        () => ({
            onMouseEnter: (label: string) =>
                selectedNode?.id === id &&
                !data.isExpandable &&
                setSelectedSourceHandle(label),
            onMouseLeave: () =>
                selectedNode?.id === id &&
                !data.isExpandable &&
                setSelectedSourceHandle(defaultCurrentHandle || ""),
            onClick: (label: string) =>
                selectedNode?.id === id &&
                !data.isExpandable &&
                setDefaultCurrentHandle(label),
            onNavigate: (path: string) => navigate("feature", path, data.path),
        }),
        [
            selectedNode,
            id,
            data.isExpandable,
            defaultCurrentHandle,
            setDefaultCurrentHandle,
            navigate,
        ]
    );

    return (
        <div
            data-type={type}
            className={classnames(sharedStyles.root, {
                [sharedStyles.selected]: selected,
                [sharedStyles.deemphasize]: !focused,
            })}
            style={{ border: `0.5px solid ${colorset.accent}` }}
            data-test={`${type}-${id}-test`}
        >
            <Handle
                type="target"
                position={Position.Left}
                id="in"
                style={targetHandleStyles(colorset.accent)}
            >
                <Icon width={14} height={14} color={colorset.primary} />
            </Handle>
            <div className={sharedStyles.header}>
                <div className={sharedStyles.title}>
                    <div className={sharedStyles.titleText} title={data.label}>
                        {data.label}
                    </div>
                    {data.path && (
                        <div
                            className={sharedStyles.titleButton}
                            title={`Go to ${type}`}
                        >
                            <ArrowNarrowUpRightIcon
                                onClick={() =>
                                    navigate(type, data.path as string)
                                }
                            />
                        </div>
                    )}
                </div>
                <div className={sharedStyles.subtitle}>
                    <span>{data.sublabel?.prefix}</span>
                    <span>{getSuffixElement(type, data.sublabel)}</span>
                </div>
            </div>
            {data.children?.length && (
                <div
                    onClick={onNodeExpandToggle}
                    className={sharedStyles.expandCollapseToggle}
                    style={{
                        color: colorset.primary,
                    }}
                >
                    {data.isExpanded
                        ? "Hide "
                        : `View All ${data.children?.length} `}
                    {type === "dataset"
                        ? "Fields"
                        : type === "featureset"
                        ? "Features"
                        : "Sources"}
                </div>
            )}
            <div className={sharedStyles.children}>
                {data.children?.length &&
                    data.isExpanded &&
                    data.children.map((child, index) => (
                        <ChildElement
                            key={index}
                            child={child}
                            type={type}
                            isSelected={defaultCurrentHandle === child.label}
                            handleChildEvents={handleChildEvents}
                            isExpandable={!!data.isExpandable}
                            color={colorset.accent}
                        />
                    ))}
            </div>
            {(!data.isExpanded || data.isExpandable) && (
                <Handle
                    type="source"
                    position={Position.Right}
                    id="out"
                    style={{
                        ...getHandleStyle(colorset.accent),
                        top: "1.75rem",
                    }}
                />
            )}
        </div>
    );
}

export default React.memo(EntityNode);
