import InfoCircleIcon from "icons/info-circle.svg";
import CheckCircleIcon from "icons/check-circle.svg";
import AlertTriangleIcon from "icons/alert-triangle.svg";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { AxiosError } from "axios";

export function toast_info(msg: string) {
    toast(msg, {
        icon: (
            <div
                style={{
                    color: "#71AFF7",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <InfoCircleIcon width={20} height={20} viewBox="0 0 24 24" />
            </div>
        ),
    });
}

export function toast_success(msg: string) {
    toast(msg, {
        icon: (
            <div
                style={{
                    color: "#4CBF8A",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <CheckCircleIcon width={20} height={20} viewBox="0 0 24 24" />
            </div>
        ),
    });
}

export function toast_error(msg: string) {
    toast(msg, {
        icon: (
            <div
                style={{
                    color: "#FF8986",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <AlertTriangleIcon width={20} height={20} viewBox="0 0 24 24" />
            </div>
        ),
    });
}

export function toast_json_error(
    e: AxiosError<{ detail?: string }>,
    fallback: string
) {
    toast_error(e.response?.data.detail || fallback);
}
