import DescriptionIcon from "icons/alignment-left-bar.svg";
import styles from "../styles/EntityPage.module.scss";

const Description = ({ description }: { description: string }) => {
    return (
        <div className={styles.metadataItem}>
            <div
                className={styles.metadataItemKey}
                data-test={"metadata-description-test"}
            >
                <DescriptionIcon />
                <span>Description</span>
            </div>
            <div
                className={styles.metadataItemValue}
                data-test={"metadata-description-value-test"}
            >
                {description ? (
                    description
                ) : (
                    <span className={styles.none}>None</span>
                )}
            </div>
        </div>
    );
};

export default Description;
