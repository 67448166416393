import { Filter, FilterConfig, FilterVerb, MultiSelectConfig } from "./filters";
import { useEffect, useRef } from "react";
import classnames from "classnames";
import styles from "./styles/FilterVerbSelect.module.scss";

interface FilterVerbSelectProps {
    verb: FilterVerb;
    filter: Filter;
    filterConfig: FilterConfig;
    onChangeVerb: (v: FilterVerb) => void;
    onCancel: () => void;
}

function FilterVerbSelect({
    verb,
    filterConfig,
    onChangeVerb,
    onCancel,
    filter,
}: FilterVerbSelectProps): JSX.Element {
    return (
        <FilterVerbMultiSelect
            verb={verb}
            filter={filter}
            multiSelectConfig={filterConfig.kind}
            onChangeVerb={onChangeVerb}
            onCancel={onCancel}
        />
    );
}

interface FilterVerbMultiSelectProps {
    verb: FilterVerb;
    filter: Filter;
    multiSelectConfig: MultiSelectConfig;
    onChangeVerb: (v: FilterVerb) => void;
    onCancel: () => void;
}

function FilterVerbMultiSelect({
    verb,
    filter,
    multiSelectConfig,
    onChangeVerb,
    onCancel,
}: FilterVerbMultiSelectProps): JSX.Element {
    let options: FilterVerbOption[] = [];
    switch (multiSelectConfig.relationship) {
        case "has-one":
            if (Array.isArray(filter.value) && filter.value.length > 1) {
                options = [{ value: "is one of", label: "Is One Of" }];
            } else {
                options = [{ value: "is", label: "Is" }];
            }
            break;
        case "has-many":
            options = [
                { value: "includes all of", label: "Includes All Of" },
                { value: "includes any of", label: "Includes Any Of" },
                {
                    value: "does not include all of",
                    label: "Does not Includes All Of",
                },
                {
                    value: "does not include any of",
                    label: "Does not Includes Any Of",
                },
            ];
            break;
    }
    return (
        <FilterVerbSelectDropdown
            verb={verb}
            options={options}
            onChangeVerb={onChangeVerb}
            onCancel={onCancel}
        />
    );
}

type FilterVerbOption = {
    label: string;
    value: FilterVerb;
    icon?: React.FC<React.SVGAttributes<SVGElement>>;
};

interface FilterVerbSelectDropdownProps {
    verb: FilterVerb;
    options: FilterVerbOption[];
    onChangeVerb: (v: FilterVerb) => void;
    onCancel: () => void;
}

function FilterVerbSelectDropdown({
    verb,
    options,
    onChangeVerb,
    onCancel,
}: FilterVerbSelectDropdownProps): JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onClick = (e: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(e.target as Node)
            ) {
                onCancel();
            }
        };

        document.addEventListener("mousedown", onClick);
        return () => {
            document.removeEventListener("mousedown", onClick);
        };
    }, [containerRef, onCancel]);

    return (
        <div className={styles.filterVerbSelectDropdown} ref={containerRef}>
            {options.map((opt) => (
                <div
                    key={opt.value}
                    className={classnames(
                        styles.item,
                        verb === opt.value && styles.active
                    )}
                    onClick={() => {
                        onChangeVerb(opt.value);
                    }}
                >
                    {opt.icon ? <opt.icon /> : null}
                    <span>{opt.label}</span>
                </div>
            ))}
        </div>
    );
}

export default FilterVerbSelect;
