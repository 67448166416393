import {
    ForwardedRef,
    KeyboardEventHandler,
    forwardRef,
    useState,
} from "react";
import type { MultiValue, GroupBase } from "react-select";
import Select from "react-select/dist/declarations/src/Select";

import { toast_error } from "shared/utils/toast";

import type { RuleFieldOption } from "./BranchRuleField";
import { MultiSelectInput } from "shared/components/MultiSelectInput";

const TagInput = (
    {
        onChange,
        ...props
    }: {
        onChange: (value: MultiValue<RuleFieldOption>) => void;
        value: MultiValue<RuleFieldOption>;
    },
    ref:
        | ForwardedRef<
              Select<RuleFieldOption, true, GroupBase<RuleFieldOption>>
          >
        | undefined
) => {
    const [inputValue, setInputValue] = useState("");

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case "Enter":
            case "Tab": {
                const hasInvalidChars = /[^A-Za-z0-9-_]/gmu.test(inputValue);

                if (hasInvalidChars) {
                    toast_error(
                        `"${inputValue}" contains disallowed characters`
                    );
                }

                if (
                    !props.value.some(({ value }) => value === inputValue) &&
                    !hasInvalidChars
                ) {
                    onChange([
                        ...props.value,
                        {
                            label: inputValue,
                            value: inputValue,
                        },
                    ]);
                }
                setInputValue("");
                event.preventDefault();
            }
        }
    };

    return (
        <MultiSelectInput<RuleFieldOption>
            ref={ref}
            {...props}
            placeholder="Enter tags"
            inputValue={inputValue}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            onChange={onChange}
            menuIsOpen={false}
        />
    );
};

export default forwardRef(TagInput);
