import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const BranchRedirectRoute = ({ defaultRoute }: { defaultRoute: string }) => {
    const { branchName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const lastPage = localStorage.getItem("autoLogoutLastPage");
        if (lastPage) {
            window.location.href = lastPage;
            localStorage.removeItem("autoLogoutLastPage");
        } else {
            navigate(`${defaultRoute}`);
        }
    }, [defaultRoute, navigate]);

    return null;
};

export default BranchRedirectRoute;
