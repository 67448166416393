import { forwardRef, ForwardedRef } from "react";
import classnames from "classnames";

import Switch from "shared/components/Switch";

import styles from "./styles/OrgPermissionToggle.module.scss";

function OrgPermissionToggle(
    {
        checked,
        disabled,
        name,
        icon,
        title,
        onChange,
        description,
    }: {
        checked?: boolean;
        disabled?: boolean;
        name: string;
        title: string;
        description: string;
        onChange?: (checked: boolean) => void;
        icon: JSX.Element;
    },
    ref: ForwardedRef<HTMLButtonElement>
) {
    return (
        <div
            className={classnames(styles.root, { [styles.disabled]: disabled })}
        >
            <div className={styles.content}>
                <div className={styles.icon}>{icon}</div>
                <div className={styles.title}>
                    <p>{title}</p>
                    <p>{description}</p>
                </div>
            </div>
            <Switch
                ref={ref}
                checked={checked}
                name={name}
                onCheckedChange={onChange}
            />
        </div>
    );
}

export default forwardRef(OrgPermissionToggle);
