import classnames from "classnames";
import { useState } from "react";
import { DateValue, formatDateValue } from "./date-types";
import CalendarIcon from "icons/calendar.svg";
import DropdownIcon from "icons/chevron-selector-vertical.svg";

import styles from "./styles/DateControl.module.scss";
import DateValuePicker from "./DateValuePicker";
import Button from "../Button";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    onValueChange: (d: DateValue) => void;
    initialValue: DateValue;
    alignment: "left" | "right";
}

function DateControl({
    onValueChange,
    className,
    initialValue,
    alignment,
}: Props): JSX.Element {
    const [value, setValue] = useState<DateValue>(initialValue);
    const [showPickerMenu, setShowPickerMenu] = useState(false);

    return (
        <div className={styles.container}>
            <Button
                onClick={() => setShowPickerMenu(true)}
                size="small"
                color="alt"
                variant="outline"
                icon={<CalendarIcon />}
            >
                <div className={classnames(className, styles.innerContainer)}>
                    {formatDateValue(value)}
                    <DropdownIcon width={8} height={8} />
                </div>
            </Button>

            {showPickerMenu ? (
                <DateValuePicker
                    initialValue={initialValue}
                    onValueChange={(v) => {
                        setValue(v);
                        onValueChange(v);
                    }}
                    onDismiss={() => setShowPickerMenu(false)}
                    alignment={alignment}
                />
            ) : null}
        </div>
    );
}

export default DateControl;
