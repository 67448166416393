import classnames from "classnames";
import CloseIcon from "icons/close.svg";
import { useEffect, useRef } from "react";

import styles from "./styles/Modal.module.scss";
import IconButton from "./IconButton";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    header: React.ReactNode;
    children: React.ReactNode;
    onClose: () => void;
}

function Modal({ children, className, onClose, header }: Props): JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onPressEsc = (e: KeyboardEvent) => {
            if (e.key == "Escape") {
                onClose();
            }
        };

        document.addEventListener("keydown", onPressEsc);
        return () => {
            document.removeEventListener("keydown", onPressEsc);
        };
    }, [onClose]);

    useEffect(() => {
        const onClickOutside = (e: MouseEvent) => {
            if (!modalRef.current?.contains(e.target as Node)) {
                onClose();
            }
        };

        containerRef.current?.addEventListener("click", onClickOutside);
        return () => {
            containerRef.current?.removeEventListener("click", onClickOutside);
        };
    }, [onClose, containerRef]);

    return (
        <div className={styles.container} ref={containerRef}>
            <div className={classnames(styles.modal, className)} ref={modalRef}>
                <div className={styles.titleRow}>
                    <div className={styles.titleLeft}>{header}</div>
                    <div className={styles.titleRight}>
                        <IconButton
                            onClick={onClose}
                            size="small"
                            icon={
                                <CloseIcon
                                    width={"1.25rem"}
                                    height={"1.25rem"}
                                />
                            }
                        />
                    </div>
                </div>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
}

export default Modal;
