import styles from "./styles/MetaField.module.scss";

type MetaFieldProps = {
    name: string;
    icon: React.ReactNode;
    meta?: string;
};

function MetaField({ name, meta, icon }: MetaFieldProps) {
    return (
        <div className={styles.root}>
            {icon}
            <div className={styles.content}>
                <p className={styles.name}>{name}</p>
                {meta && <p className={styles.meta}>{meta}</p>}
            </div>
        </div>
    );
}

export default MetaField;
