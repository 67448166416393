import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import SearchBar from "../../components/search/SearchBar";
import { useState } from "react";
import MenuItem from "../../components/MenuItem";
import styles from "./Combobox.module.scss";

export default function ComboxBox({
    items,
    trigger,
    onItemClick,
}: {
    items:
        | {
              value: string;
              display: React.ReactNode;
              icon?: JSX.Element;
          }[]
        | undefined;
    trigger: React.ReactNode;
    onItemClick?: (itemVal: string) => void;
}) {
    const [filteredItems, setFilteredItems] = useState(items);
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className={styles.content}>
                    <SearchBar
                        onSearch={(text) =>
                            setFilteredItems(
                                items?.filter((i) =>
                                    i.value
                                        .toLocaleLowerCase()
                                        .includes(text.toLocaleLowerCase())
                                )
                            )
                        }
                        fill
                        inputProps={{
                            onKeyDown: (e) => e.stopPropagation(),
                        }}
                    />
                    <div className={styles.items}>
                        {filteredItems?.map((item, idx) => {
                            return (
                                <DropdownMenu.Item
                                    onClick={() => onItemClick?.(item.value)}
                                    key={idx}
                                >
                                    <MenuItem icon={item.icon} renderAsNode>
                                        {item.display}
                                    </MenuItem>
                                </DropdownMenu.Item>
                            );
                        })}
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}
