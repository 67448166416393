export const colors = {
    "5": [
        "rgb(235, 241, 253)",
        "rgb(235, 246, 242)",
        "rgb(249, 249, 250)",
        "rgb(240, 239, 254)",
        "rgb(252, 235, 236)",
        "rgb(254, 246, 231)",
    ],
    "10": [
        "rgb(228, 236, 252)",
        "rgb(228, 243, 238)",
        "rgb(235, 236, 237)",
        "rgb(235, 234, 254)",
        "rgb(251, 228, 230)",
        "rgb(253, 243, 223)",
    ],
    "20": [
        "rgb(201, 216, 248)",
        "rgb(201, 232, 221)",
        "rgb(216, 217, 219)",
        "rgb(216, 213, 253)",
        "rgb(247, 201, 204)",
        "rgb(252, 232, 191)",
    ],
    "30": [
        "rgb(173, 197, 245)",
        "rgb(174, 220, 204)",
        "rgb(197, 198, 201)",
        "rgb(196, 192, 253)",
        "rgb(243, 173, 179)",
        "rgb(250, 220, 158)",
    ],
    "40": [
        "rgb(146, 178, 242)",
        "rgb(147, 208, 187)",
        "rgb(179, 180, 184)",
        "rgb(177, 171, 252)",
        "rgb(239, 146, 154)",
        "rgb(248, 209, 126)",
    ],
    "50": [
        "rgb(119, 158, 238)",
        "rgb(120, 196, 170)",
        "rgb(160, 162, 167)",
        "rgb(157, 150, 251)",
        "rgb(235, 119, 128)",
        "rgb(246, 197, 94)",
    ],
    "60": [
        "rgb(92, 139, 235)",
        "rgb(93, 185, 153)",
        "rgb(142, 145, 150)",
        "rgb(137, 129, 250)",
        "rgb(231, 92, 103)",
        "rgb(245, 185, 61)",
    ],
    "70": [
        "rgb(118, 108, 249)",
        "rgb(64, 120, 232)",
        "rgb(66, 173, 135)",
        "rgb(125, 127, 133)",
        "rgb(227, 64, 78)",
        "rgb(243, 174, 29)",
    ],
    "80": [
        "rgb(48, 103, 215)",
        "rgb(50, 156, 119)",
        "rgb(108, 111, 118)",
        "rgb(101, 92, 233)",
        "rgb(210, 48, 61)",
        "rgb(226, 157, 12)",
    ],
    "90": [
        "rgb(41, 88, 184)",
        "rgb(42, 134, 102)",
        "rgb(91, 94, 102)",
        "rgb(86, 78, 199)",
        "rgb(180, 41, 52)",
        "rgb(194, 134, 10)",
    ],
    "100": [
        "rgb(34, 73, 153)",
        "rgb(35, 111, 85)",
        "rgb(75, 78, 87)",
        "rgb(72, 65, 166)",
        "rgb(150, 34, 43)",
        "rgb(161, 112, 9)",
    ],
    "110": [
        "rgb(27, 59, 123)",
        "rgb(28, 89, 68)",
        "rgb(59, 63, 72)",
        "rgb(58, 52, 133)",
        "rgb(120, 27, 35)",
        "rgb(129, 90, 7)",
    ],
    "120": [
        "rgb(20, 44, 92)",
        "rgb(21, 67, 51)",
        "rgb(44, 48, 58)",
        "rgb(43, 39, 100)",
        "rgb(90, 20, 26)",
        "rgb(97, 67, 5)",
    ],
    "130": [
        "rgb(14, 29, 61)",
        "rgb(14, 44, 34)",
        "rgb(31, 34, 41)",
        "rgb(29, 26, 66)",
        "rgb(60, 14, 17)",
        "rgb(64, 45, 3)",
    ],
    "140": [
        "rgb(7, 15, 31)",
        "rgb(7, 22, 17)",
        "rgb(19, 21, 25)",
        "rgb(14, 13, 33)",
        "rgb(30, 7, 9)",
        "rgb(32, 23, 2)",
    ],
};

export const COLOR_PALETTE = colors["70"].concat(colors["40"]);
export const COLOR_PALETTE_LENGTH = COLOR_PALETTE.length;
