import { useMemo, useState } from "react";
import { Row, SortingState, createColumnHelper } from "@tanstack/react-table";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { axiosFetcher } from "shared/utils/utils";
import { ServiceAccount } from "shared/models";

import Button from "shared/components/Button";
import Chip from "shared/components/Chip";
import ReactTable from "shared/components/ReactTable";
import { AlertDialog } from "shared/components/AlertDialog";
import DropdownMenu from "shared/components/DropdownMenu";
import IconButton from "shared/components/IconButton";
import MenuItem from "shared/components/MenuItem";
import { Dialog } from "shared/components/Dialog";
import UserDisplay from "shared/components/UserDisplay";
import CreateServiceAccountDialog from "./CreateServiceAccountDialog";
import EmptyState from "shared/components/EmptyState";
import PageTitle from "../shared/PageTitle";
import ServiceAccountDeleteDialog from "./ServiceAccountDeleteDialog";

import PlusIcon from "icons/plus.svg";
import ChevronRightIcon from "icons/chevron-right.svg";
import DotsHorizontalIcon from "icons/dots-horizontal.svg";
import XSquareIcon from "icons/x-square.svg";
import EditIcon from "icons/edit.svg";

import styles from "./styles/OrganizationServiceAccountSettings.module.scss";
import { SERVICE_NAV } from "shared/constants/navigation";

const columnHelper = createColumnHelper<ServiceAccount>();

const DEFAULT_SORT = [
    {
        id: "created_at",
        desc: false,
    },
];

const OrganizationServiceAccountSettings = () => {
    const [creating, setCreating] = useState<boolean>(false);
    const [updating, setUpdating] = useState<ServiceAccount | undefined>();
    const [deleting, setDeleting] = useState<number | undefined>();
    const [sorting, _setSorting] = useState<SortingState>(DEFAULT_SORT);

    const navigate = useNavigate();

    const { data, isLoading, error, mutate } = useSWR(
        ["get", "/api/v1/auth/service_accounts"],
        axiosFetcher
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor(({ name }) => name, {
                header: "Name",
                cell: ({ renderValue }) => <p>{renderValue()}</p>,
            }),
            columnHelper.accessor(({ role }) => role.name, {
                header: "Role",
                cell: ({ renderValue }) => <Chip>{renderValue()}</Chip>,
            }),
            columnHelper.accessor(({ owner }) => owner, {
                header: "Owner",
                cell: ({ renderValue }) => (
                    <UserDisplay name={renderValue() || ""} size="small" />
                ),
            }),
            columnHelper.accessor(({ created_at }) => created_at, {
                id: "created_at",
                header: "Created at",
                cell: ({ renderValue }) =>
                    formatDistanceToNow(parseISO(`${renderValue() || ""}Z`), {
                        addSuffix: true,
                    }),
            }),
            columnHelper.display({
                id: "actions",
                header: () => null,
                cell: ({
                    row,
                }: {
                    row: { original: ServiceAccount; index: number };
                }) => (
                    <div
                        className={classnames(
                            styles.actions,
                            styles.row_actions
                        )}
                    >
                        <DropdownMenu
                            trigger={
                                <IconButton
                                    icon={<DotsHorizontalIcon />}
                                    size="small"
                                />
                            }
                        >
                            <MenuItem
                                icon={<EditIcon />}
                                onClick={() => setUpdating(row.original)}
                            >
                                Edit Service Account
                            </MenuItem>
                            <MenuItem
                                icon={<XSquareIcon />}
                                onClick={() => setDeleting(row.original.id)}
                            >
                                Delete Service Account
                            </MenuItem>
                        </DropdownMenu>
                        <ChevronRightIcon />
                    </div>
                ),
                size: 32,
            }),
        ],
        []
    );

    const tableState = useMemo(
        () => ({
            sorting,
        }),
        [sorting]
    );

    const handleRowClick = (row: Row<ServiceAccount>) =>
        navigate(`${row.original.id}`);

    return (
        <div className={styles.root}>
            <PageTitle title={SERVICE_NAV.title} titleIcon={SERVICE_NAV.icon}>
                <div className={styles.actions}>
                    <Button
                        color="primary"
                        icon={<PlusIcon />}
                        onClick={() => setCreating(true)}
                        size="small"
                    >
                        Create Service Account
                    </Button>
                </div>
            </PageTitle>
            <ReactTable
                columns={columns}
                data={data || []}
                onRowClick={handleRowClick}
                state={tableState}
                renderEmptyState={() => (
                    <EmptyState
                        loading={isLoading}
                        text={
                            error
                                ? "Something went wrong. Please refresh and try again."
                                : "No Service Accounts"
                        }
                    />
                )}
            />
            <AlertDialog
                open={deleting !== undefined}
                onOpenChange={(open) =>
                    setDeleting((prev) => (!open ? undefined : prev))
                }
            >
                <ServiceAccountDeleteDialog
                    serviceAccountId={deleting}
                    onSuccess={() => {
                        setDeleting(undefined);
                        mutate();
                    }}
                />
            </AlertDialog>
            <Dialog
                open={!!updating || creating}
                onOpenChange={(open) => {
                    setCreating(open);
                    setUpdating((prev) => (!open ? undefined : prev));
                }}
            >
                <CreateServiceAccountDialog
                    updating={updating}
                    onSuccess={() => {
                        setCreating(false);
                        setUpdating(undefined);
                        mutate();
                    }}
                />
            </Dialog>
        </div>
    );
};

export default OrganizationServiceAccountSettings;
