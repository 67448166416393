import { useState, forwardRef, ForwardedRef, useCallback } from "react";

import { RuleType, BranchPermission, BranchRule } from "shared/models";

import Button from "shared/components/Button";
import IconButton from "shared/components/IconButton";
import TierRuleDrawer, { BranchRuleFormValue } from "./BranchRuleDrawer";
import BranchRuleDisplay from "../shared/BranchRuleDisplay";

import DeleteIcon from "icons/delete.svg";
import EditIcon from "icons/edit.svg";
import PlusCircleIcon from "icons/plus-circle.svg";

import styles from "./styles/BranchRuleField.module.scss";

export type RuleFieldOption = { label: string; value: string };

function BranchRuleField(
    {
        onChange,
        onDeleteRule,
        value,
    }: {
        onChange: (idx: number, value: BranchRule) => void;
        onDeleteRule: (idx: number) => void;
        value: BranchRule[];
    },
    ref: ForwardedRef<HTMLDivElement>
) {
    const [isEditing, setIsEditing] = useState<number | undefined>();

    const handleNewRule = useCallback(() => {
        setIsEditing(value.length);
    }, [value]);

    const handleRuleDrawerChange = useCallback(
        (values: BranchRuleFormValue) => {
            let rule_type = values.ruleType;

            if (rule_type === "*") {
                rule_type = RuleType.ALLOW;
            }

            const rule: BranchRule = {
                kind: "branch",
                branch: values.branch,
                permissions: [BranchPermission.VIEW_BRANCH],
                tag_permissions: values.permissions.length
                    ? {
                          permissions: values.permissions.map(
                              ({ value }) => value as BranchPermission
                          ),
                          rule_type,
                          tags:
                              values.ruleType === "*"
                                  ? ["*"]
                                  : values.tags.map(({ value }) => value),
                      }
                    : undefined,
            };

            onChange(isEditing as number, rule);
            setIsEditing(undefined);
        },
        [onChange, isEditing, value]
    );

    return (
        <div ref={ref}>
            {value?.length ? (
                <>
                    <div className={styles.rule_list}>
                        {value.map((rule, i) => (
                            <div key={i} className={styles.rule_row}>
                                <BranchRuleDisplay
                                    className={styles.rule}
                                    rule={rule}
                                />
                                <div className={styles.actions}>
                                    <IconButton
                                        icon={<EditIcon />}
                                        onClick={() => setIsEditing(i)}
                                    />
                                    <IconButton
                                        className={styles.rule_delete_btn}
                                        icon={<DeleteIcon />}
                                        onClick={() => onDeleteRule(i)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <Button
                        size="small"
                        shape="pill"
                        variant="outline"
                        icon={<PlusCircleIcon />}
                        type="button"
                        onClick={handleNewRule}
                    >
                        Add Rule
                    </Button>
                </>
            ) : (
                <div className={styles.empty}>
                    <p>No Access Permissions</p>
                    <Button
                        size="small"
                        shape="pill"
                        variant="outline"
                        icon={<PlusCircleIcon />}
                        type="button"
                        onClick={handleNewRule}
                    >
                        Add Rule
                    </Button>
                </div>
            )}
            {isEditing !== undefined ? (
                <TierRuleDrawer
                    open
                    onSubmit={handleRuleDrawerChange}
                    onOpenChange={(open) => {
                        setIsEditing((prev) => (!open ? undefined : prev));
                    }}
                    existing={value[isEditing ?? -1]}
                />
            ) : null}
        </div>
    );
}

export default forwardRef(BranchRuleField);
