import styles from "./styles/TagFilter.module.scss";
import TagIcon from "icons/tag.svg";

function TagFilter({ name }: { name: string }): JSX.Element {
    return (
        <div className={styles.filter}>
            <TagIcon />
            <span>{name}</span>
        </div>
    );
}

export default TagFilter;
