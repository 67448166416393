import { useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

import { toast_json_error, toast_success } from "shared/utils/toast";

import Button from "shared/components/Button";
import { Field, TextInput } from "shared/components/TextInput";
import {
    Dialog,
    DialogActions,
    DialogClose,
    DialogContent,
    DialogTitle,
    DialogTrigger,
} from "shared/components/Dialog";
import SettingsCard from "../shared/SettingsCard/SettingsCard";

import styles from "./styles/UpdatePasswordForm.module.scss";

function UpdatePasswordForm() {
    const [open, setOpen] = useState(false);
    return (
        <SettingsCard>
            <div className={styles.content}>
                <div className={styles.title}>
                    <p>Password</p>
                    <p>Change the password you use to login to Fennel</p>
                </div>
                <div className={styles.input_wrapper}>
                    <Dialog open={open} onOpenChange={setOpen}>
                        <DialogTrigger>
                            <Button color="primary">Change Password</Button>
                        </DialogTrigger>
                        <UpdatePasswordDialog
                            onSuccess={() => setOpen(false)}
                        />
                    </Dialog>
                </div>
            </div>
        </SettingsCard>
    );
}

type UpdatePasswordFormValues = {
    old_password: string;
    new_password: string;
    confirm: string;
};

const defaultValues = {
    old_password: "",
    new_password: "",
    confirm: "",
};

const schema = yup
    .object({
        old_password: yup.string().required("This is required"),
        new_password: yup
            .string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                "Password is too weak"
                // Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character
            )
            .required("This is required"),
        confirm: yup
            .string()
            .oneOf([yup.ref("new_password")], "Passwords must match")
            .required("This is required"),
    })
    .required();

function UpdatePasswordDialog({ onSuccess }: { onSuccess: () => void }) {
    const [loading, setLoading] = useState(false);
    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
    } = useForm<UpdatePasswordFormValues>({
        defaultValues,
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<UpdatePasswordFormValues> = useCallback(
        ({ old_password, new_password }) => {
            setLoading(true);

            axios
                .post("/users/me/change_pwd", {
                    old_password,
                    new_password,
                })
                .then(() => {
                    setLoading(false);
                    toast_success("Password changed successfully");
                    reset();
                    onSuccess();
                })
                .catch((e) => {
                    setLoading(false);
                    toast_json_error(e, "Failed to change password");
                });
        },
        []
    );

    return (
        <DialogContent>
            <DialogTitle>Update Password</DialogTitle>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    name="old_password"
                    render={({ field, fieldState }) => (
                        <Field label="Current Password" state={fieldState}>
                            <TextInput type="password" {...field} />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="new_password"
                    render={({ field, fieldState }) => (
                        <Field label="New Password" state={fieldState}>
                            <TextInput type="password" {...field} />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="confirm"
                    render={({ field, fieldState }) => (
                        <Field label="Confirm Password" state={fieldState}>
                            <TextInput type="password" {...field} />
                        </Field>
                    )}
                />
                <DialogActions>
                    <DialogClose>
                        <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <Button
                        disabled={!isDirty || !isValid}
                        loading={loading}
                        color="primary"
                        type="submit"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    );
}

export default UpdatePasswordForm;
