import { useCallback, useState } from "react";
import styles from "./Commits.module.scss";
import axios from "axios";
import AxiosLoader from "shared/components/AxiosLoader";
import { branchedLink } from "shared/utils/utils";
import SearchBar from "shared/components/search/SearchBar";
import { Config, Filter, hasOnePred } from "shared/components/search/filters";
import ClipboardPre from "shared/components/ClipboardPre";
import { format } from "date-fns";
import Button from "shared/components/Button";
import { useParams } from "react-router-dom";
import SummaryIcon from "icons/summary.svg";
import Avatar from "shared/components/Avatar";
import OwnerFilter from "shared/components/search/OwnerFilter";
import UserCircleIcon from "icons/user-circle.svg";
import BranchIcon from "icons/branch.svg";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import Convert from "ansi-to-html";
import { useCurrentUser } from "../../context/CurrentUser";
import { Branch } from "shared/models";
import { COMMITS_NAV } from "shared/constants/navigation";
import EmptyState from "shared/components/EmptyState";
import { DOC_LINKS } from "shared/constants/docs";
import classNames from "classnames";
import SummaryModal from "shared/components/SummaryModal";
import ConsolePage from "../console-page/ConsolePage";

const convert = new Convert();

interface Commit {
    hash: string;
    created_at: number;
    owner: string;
    commit_message: string;
    diff_summary: string;
}

interface ModalProps {
    onClose: () => void;
    commit: Commit;
}

export const CommitsPage = () => {
    const { branchName } = useParams();
    const loadFunc = useCallback(
        () => axios.post(branchedLink(branchName, "commits")),
        []
    );
    return (
        <AxiosLoader
            onload={(commits) => <CommitsTableLoaded commits={commits} />}
            loadFunc={loadFunc}
        />
    );
};

function buildSearchConfig(commits?: Commit[]): Config {
    if (!commits) {
        return {
            filterOrder: [],
            filterConfigs: {},
        };
    }
    const allOwnersSet = new Set(commits.map((d) => d.owner.toString()));
    const owners = Array.from(allOwnersSet.keys()).sort();
    const ownerConfig = {
        icon: UserCircleIcon,
        propertyName: "Owner",
        kind: {
            kind: "multi-select" as const,
            options: owners.map((o) => ({ key: o, name: o, value: o })),
            valueCategory: "user",
            component: OwnerFilter,
            relationship: "has-one" as const,
        },
    };

    const filterOrder = ["owner"];

    return {
        filterOrder,
        filterConfigs: {
            owner: ownerConfig,
        },
    };
}

const filterCommits = (
    text: string,
    filters: Filter[],
    allCommits?: Commit[]
) => {
    let filtered = allCommits?.filter((commit: Commit) =>
        commit.commit_message.toLowerCase().includes(text.toLowerCase())
    );
    filters.forEach((f) => {
        if (f.key == "owner") {
            filtered = filtered?.filter((d) => hasOnePred(f, d.owner));
        }
    });
    return filtered;
};

// Function to group consecutive objects with the same 'type'
const groupConsecutive = (
    arr: {
        type: string;
        createdAt: number;
        hash: string;
        data?: Commit | Branch;
    }[]
) => {
    const result = []; // Array to hold the result
    let currentGroup: typeof arr = []; // Array to hold the current group of consecutive objects

    arr.forEach((item, index) => {
        if (
            index === 0 ||
            (item.type === arr[index - 1].type &&
                new Date(item.createdAt).toDateString() ===
                    new Date(arr[index - 1].createdAt).toDateString())
        ) {
            currentGroup.push(item);
        } else {
            // Otherwise, start a new group
            result.push({
                type: currentGroup[0].type,
                day: new Date(currentGroup[0].createdAt).toDateString(),
                data: currentGroup.map((item) => item.data),
            });
            currentGroup = [item]; // Start a new group with the current item
        }
    });

    // Add the last group to the result if it's not empty
    if (currentGroup.length > 0) {
        result.push({
            type: currentGroup[0].type,
            day: new Date(currentGroup[0].createdAt).toDateString(),
            data: currentGroup.map((item) => item.data),
        });
    }

    return result;
};

const CommitsTableLoaded = ({
    commits: allCommits,
}: {
    commits?: Commit[];
}) => {
    const [filtered, setFiltered] = useState<Commit[] | undefined>(
        allCommits?.sort((a, b) => b.created_at - a.created_at)
    );
    const { branches } = useCurrentUser();
    const { branchName } = useParams();

    const branchInfo = branches?.find((branch) => branch.name === branchName);

    if (!allCommits?.length && !branchInfo) {
        return (
            <div className={styles.emptyState}>
                <EmptyState
                    text={"You don't have any commits for this branch."}
                    learnMore={DOC_LINKS.branches}
                />
            </div>
        );
    }

    const itemsToRender: {
        type: string;
        createdAt: number;
        hash: string;
        data?: Commit | Branch;
    }[] = [];

    filtered?.forEach((commit) => {
        itemsToRender.push({
            type: "commit",
            createdAt: commit.created_at * 1000,
            hash: commit.hash,
            data: commit,
        });
    });

    if (branchInfo) {
        const branchCommit = {
            type: "branch",
            createdAt: branchInfo.created_at * 1000,
            data: branchInfo,
            hash: branchInfo.base_commit_id || "",
        };

        if (branchCommit.hash) {
            const index = itemsToRender.findIndex(
                (item) =>
                    item.type === "commit" && item.hash === branchCommit.hash
            );
            if (index !== -1) {
                itemsToRender.splice(index, 0, branchCommit);
            }
        } else {
            itemsToRender.push(branchCommit);
        }
    }

    const groupedItemsToRender = groupConsecutive(itemsToRender);

    const searchConfig = buildSearchConfig(allCommits);

    return (
        <ConsolePage
            header={{
                title: COMMITS_NAV.title,
                icon: COMMITS_NAV.icon,
            }}
            subheader={
                <SearchBar
                    config={searchConfig}
                    onSearch={(text, filters) => {
                        setFiltered(filterCommits(text, filters, allCommits));
                    }}
                />
            }
            content={
                <>
                    {groupedItemsToRender.map((item, index) => {
                        if (item.type === "commit") {
                            return (
                                <CommitsList
                                    commits={item.data as Commit[]}
                                    day={item.day}
                                    key={`Commit${index}`}
                                />
                            );
                        } else {
                            return (
                                <BranchInfo
                                    branch={item.data[0] as Branch}
                                    key={`Branch${index}`}
                                />
                            );
                        }
                    })}
                </>
            }
        />
    );
};

const BranchInfo = ({ branch }: { branch: Branch }) => {
    return (
        <div className={styles.card} data-test={branch.name + "-card"}>
            <div className={styles.content}>
                <div className={styles.contentCenter}>
                    <BranchIcon />
                    <div>Branch Created</div>
                    <div className={styles.itemMetadata}>
                        <div className={styles.itemAuthor}>
                            <Avatar
                                name={branch.owner?.toString() || "Fennel"}
                            />
                            <span className={styles.itemAuthorName}>
                                {branch.owner || "Fennel"}
                            </span>
                        </div>
                        <span>
                            {format(
                                new Date(branch.created_at * 1000),
                                "do MMMM yyyy 'at' p"
                            )}
                        </span>
                    </div>
                    {!!branch.base_branch_name && (
                        <div
                            className={classNames(
                                styles.itemMetadata,
                                styles.cloned
                            )}
                        >
                            Cloned from{" "}
                            <div className={styles.itemAuthor}>
                                <a
                                    href={`/branch/${branch.base_branch_name}/commits`}
                                >
                                    <BranchIcon /> {branch.base_branch_name}{" "}
                                    <ArrowNarrowUpRightIcon />
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const CommitsList = ({ day, commits }: { commits?: Commit[]; day: string }) => {
    const [commitState, setCommitState] = useState<Commit | null>(null);
    const [dayString, ...date] = day.split(" ");
    const dateString = date.join(" ");
    return (
        <>
            <div className={styles.card}>
                <div className={styles.content}>
                    <div className={styles.cardHeader}>
                        <div className={styles.cardHeaderTitle}>
                            <h2>{dayString}</h2>
                            <h3>{dateString}</h3>
                        </div>
                    </div>

                    {commits?.map((commit) => {
                        return (
                            <div
                                className={styles.itemContainer}
                                key={commit.hash}
                                data-test={commit.hash}
                            >
                                <div className={styles.itemMessage}>
                                    <div data-test={"message-test"}>
                                        {commit.commit_message}
                                    </div>
                                    <div className={styles.itemMetadata}>
                                        <div className={styles.itemAuthor}>
                                            Committed by
                                            <Avatar
                                                name={commit.owner.toString()}
                                            />
                                            <span
                                                className={
                                                    styles.itemAuthorName
                                                }
                                                data-test={"owner-test"}
                                            >
                                                {commit.owner}
                                            </span>
                                        </div>
                                        <span data-test={"committed-date-test"}>
                                            {format(
                                                new Date(
                                                    commit.created_at * 1000
                                                ),
                                                "p"
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.itemRight}>
                                    <CommitHash hashCode={commit.hash} />
                                </div>
                                <div className={styles.itemRight}>
                                    <Button
                                        icon={<SummaryIcon />}
                                        color="neutral"
                                        variant="outline"
                                        size="small"
                                        onClick={() => setCommitState(commit)}
                                    >
                                        Summary
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {commitState && (
                <SummaryModalContainer
                    onClose={() => setCommitState(null)}
                    commit={commitState}
                />
            )}
        </>
    );
};

const SummaryModalContainer = ({ onClose, commit }: ModalProps) => {
    const date = new Date(commit.created_at * 1000).toLocaleString();
    return (
        <SummaryModal
            header="Commit Details"
            onClose={onClose}
            containerClassName={styles.summaryModal}
            metadataCols={[
                {
                    metadataDetails: (
                        <>
                            <div>
                                <Avatar name={commit.owner.toString()} />
                            </div>
                            <div>{commit.owner.toString()}</div>
                        </>
                    ),
                    metadataTitle: "Author",
                },
                {
                    metadataDetails: date,
                    metadataTitle: "Timestamp",
                },
                {
                    metadataDetails: <CommitHash hashCode={commit.hash} />,
                    metadataTitle: "Hash",
                },
            ]}
            content={
                <div
                    dangerouslySetInnerHTML={{
                        __html: convert.toHtml(commit.diff_summary),
                    }}
                />
            }
        />
    );
};

const CommitHash = ({ hashCode }: { hashCode: string }) => {
    const [tooltipContent, setTooltipContent] = useState("Copy Hash");

    const handleCopyClick = useCallback(() => {
        if (hashCode) {
            navigator.clipboard.writeText(hashCode);
        }
        // Handle tooltip interaction
        setTooltipContent("Copied!");
        new Promise((res) => setTimeout(res, 2000)).then(() =>
            setTooltipContent("Click to copy")
        );
    }, [hashCode]);

    return (
        <ClipboardPre
            tooltip={tooltipContent}
            onCopyClick={handleCopyClick}
            variant="flat"
        >
            {hashCode.slice(0, 6)}
        </ClipboardPre>
    );
};
