import { Link } from "react-router-dom";
import classnames from "classnames";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { useState } from "react";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";

import { toast_json_error } from "shared/utils/toast";
import styles from "./styles/SignUpPage.module.scss";

import Button from "shared/components/Button";
import { Field, TextInput } from "shared/components/TextInput";
import TextDivider from "shared/components/TextDivider";
import ThirdPartyAuth from "shared/components/ThirdPartyAuth";
import OnYourWay from "./OnYourWay";
import LogoAndName from "./LogoAndName";

import GoogleIcon from "icons/google.svg";
import LockIcon from "icons/lock.svg";

type SignUpFormValues = {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
};

const defaultValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
};

const container = document.getElementById("root")!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
const data = container.dataset;
const sso_url = data.sso;

const validationSchema = yup.object({
    first_name: yup.string().required("Name is required"),
    last_name: yup.string().required("Name is required"),
    email: yup
        .string()
        .email("Invalid Email Address")
        .required("Email is required"),
    password: yup
        .string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Password is too weak"
            // Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character
        )
        .required("Password is required"),
});

function SignUpPage(): JSX.Element {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const { control, getValues, handleSubmit } = useForm<SignUpFormValues>({
        defaultValues,
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit: SubmitHandler<SignUpFormValues> = (values) => {
        setSubmitting(true);

        axios
            .post("/users/create", values)
            .then(() => {
                setSubmitted(true);
                setSubmitting(false);
            })
            .catch((e) => {
                toast_json_error(e, "Failed to sign up");
                setSubmitting(false);
            });
    };

    if (submitted) {
        return <OnYourWay email={getValues().email} />;
    }

    return (
        <div className={styles.gridContainer}>
            <div className={styles.container}>
                <div className={styles.logoAndName}>
                    <LogoAndName />
                </div>
                <div className={styles.titleRow}>
                    <span className={styles.title}>Sign Up</span>
                    <Link to="/login" className={styles.link}>
                        Already have an account?
                    </Link>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                        className={classnames(
                            styles.inputGroup,
                            styles.inputNames
                        )}
                    >
                        <Controller
                            control={control}
                            name="first_name"
                            render={({ fieldState, field }) => (
                                <Field state={fieldState}>
                                    <TextInput
                                        placeholder="First Name"
                                        {...field}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="last_name"
                            render={({ fieldState, field }) => (
                                <Field state={fieldState}>
                                    <TextInput
                                        placeholder="Last Name"
                                        {...field}
                                    />
                                </Field>
                            )}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ fieldState, field }) => (
                                <Field state={fieldState}>
                                    <TextInput
                                        placeholder="Work Email"
                                        {...field}
                                    />
                                </Field>
                            )}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <Controller
                            control={control}
                            name="password"
                            render={({ fieldState, field }) => (
                                <Field state={fieldState}>
                                    <TextInput
                                        {...field}
                                        placeholder="Password"
                                        type="password"
                                    />
                                </Field>
                            )}
                        />
                    </div>

                    <Button
                        type="submit"
                        color="primary"
                        className={styles.button}
                        disabled={submitting}
                    >
                        Create Account
                    </Button>
                </form>
                <div className={styles.orDivider}>
                    <TextDivider text="or" />
                </div>
                <div className={styles.thirdPartyAuth}>
                    {sso_url ? (
                        <ThirdPartyAuth
                            href={sso_url}
                            icon={
                                <LockIcon
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                />
                            }
                            text="Sign in with your Identity Provider"
                        />
                    ) : (
                        <ThirdPartyAuth
                            href="/signup/google"
                            icon={
                                <GoogleIcon
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                />
                            }
                            text="Sign up with Google"
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SignUpPage;
