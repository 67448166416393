import { useState } from "react";
import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import chipStyles from "./styles/Chip.module.scss";
import styles from "./styles/ProgressChip.module.scss";
import Tooltip from "./Tooltip";

const variants = {
    root: {},
    progress: {
        default: {},
        hovered: {
            height: 6,
        },
    },
};

const ProgressChip = ({
    completionRate = 0,
    failureRate = 0,
}: {
    completionRate?: number;
    failureRate?: number;
}) => {
    const [hovered, setHovered] = useState<boolean>(false);

    return (
        <motion.div
            layout
            className={classnames(
                chipStyles.root,
                chipStyles.default,
                styles.root
            )}
            whileHover="hovered"
            onHoverStart={() => setHovered(true)}
            onHoverEnd={() => setHovered(false)}
            variants={variants.root}
        >
            <AnimatePresence>
                {hovered ? null : (
                    <motion.p
                        layout
                        initial={{
                            opacity: 0,
                            x: -16,
                            width: 0,
                            marginRight: 0,
                        }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            width: "auto",
                            marginRight: 4,
                        }}
                        exit={{
                            opacity: 0,
                            x: -16,
                            width: 0,
                            marginRight: 0,
                        }}
                    >
                        {completionRate.toFixed(1)}%
                    </motion.p>
                )}
            </AnimatePresence>
            <motion.div
                layout
                className={styles.progress}
                variants={variants.progress}
            >
                <Tooltip
                    content={`${(completionRate - failureRate).toFixed(
                        2
                    )}% complete`}
                >
                    <div
                        className={styles.complete}
                        style={{ width: `${completionRate - failureRate}%` }}
                    />
                </Tooltip>
                <Tooltip content={`${failureRate.toFixed(2)}% failed`}>
                    <div
                        className={styles.failed}
                        style={{ width: `${failureRate}%` }}
                    />
                </Tooltip>
            </motion.div>
        </motion.div>
    );
};

export default ProgressChip;
