// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aaxXRGTz6NCMcofXyxaU{display:flex;flex-direction:row;width:100%}.aaxXRGTz6NCMcofXyxaU .UX_7Hu6SiNgx0vHauV5Q{width:400px;padding-left:2rem}.x5Ny9IxFZ3nCh3O76fXC{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/console/shared/components/graph/styles/InteractiveGraph.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,UAAA,CAEA,4CACI,WAAA,CACA,iBAAA,CAIR,sBACI,YAAA,CACA,qBAAA","sourcesContent":[".alignVertical {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n\n    .legends {\n        width: 400px;\n        padding-left: 2rem;\n    }\n}\n\n.alignHorizontal {\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignVertical": "aaxXRGTz6NCMcofXyxaU",
	"legends": "UX_7Hu6SiNgx0vHauV5Q",
	"alignHorizontal": "x5Ny9IxFZ3nCh3O76fXC"
};
export default ___CSS_LOADER_EXPORT___;
