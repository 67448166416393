import { useCallback, useMemo, useState } from "react";
import useSWR from "swr";
import {
    ColumnFiltersState,
    SortingState,
    TableState,
    createColumnHelper,
} from "@tanstack/react-table";
import type { UserWithRole, Role } from "shared/models";
import { axiosFetcher } from "shared/utils/utils";

import styles from "./styles/OrganizationMemberSettings.module.scss";

import { Dialog } from "shared/components/Dialog";
import DropdownMenu from "shared/components/DropdownMenu";
import UserDisplay from "shared/components/UserDisplay";
import Chip from "shared/components/Chip";
import IconButton from "shared/components/IconButton";
import MenuItem from "shared/components/MenuItem";
import ReactTable, {
    TableFilterToolbar,
    TableSearchColumnHeader,
} from "shared/components/ReactTable";
import PageTitle from "../shared/PageTitle";
import EmptyState from "shared/components/EmptyState";

import DotsHorizontalIcon from "icons/dots-horizontal.svg";
import ShieldIcon from "icons/shield.svg";

import UpdateRoleDialog from "./UpdateRoleDialog";
import { parseJSON, formatDistanceToNow, parseISO } from "date-fns";
import { MEMBERS_NAV } from "shared/constants/navigation";

const columnHelper = createColumnHelper<UserWithRole>();

const DEFAULT_SORT = [
    {
        id: "created_at",
        desc: false,
    },
];

const DEFAULT_COL_FILTER: ColumnFiltersState = [];

function OrganizationMemberSettings() {
    const [columnFilters, setColumnFilters] =
        useState<ColumnFiltersState>(DEFAULT_COL_FILTER);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, _setSorting] = useState<SortingState>(DEFAULT_SORT);

    const tableState: Partial<TableState> = useMemo(
        () => ({
            sorting,
            globalFilter,
            columnFilters,
        }),
        [sorting, globalFilter, columnFilters]
    );

    const [updatingRole, setUpdatingRole] = useState<UserWithRole | undefined>(
        undefined
    );
    const { data, error, mutate, isLoading } = useSWR<UserWithRole[]>(
        ["get", "/api/v1/auth/users"],
        axiosFetcher
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                ({ user: { first_name, last_name } }) =>
                    `${first_name} ${last_name}`,
                {
                    id: "name",
                    header: ({ table }) => (
                        <TableSearchColumnHeader
                            placeholder="Search Users..."
                            onChange={(e) =>
                                table.setGlobalFilter(e.target.value)
                            }
                            value={table.getState().globalFilter as string}
                        />
                    ),
                    filterFn: "includesString",
                    enableColumnFilter: true,
                    cell: ({ row, renderValue }) => (
                        <UserDisplay
                            name={renderValue() || ""}
                            meta={row.original.user.email}
                        />
                    ),
                }
            ),
            columnHelper.accessor(({ role }) => `${role.id}`, {
                id: "role",
                header: "Role",
                cell: ({ row }) => <Chip>{row.original.role.name}</Chip>,
                filterFn: "equals",
                minSize: 240,
            }),
            columnHelper.accessor(({ user }) => user.created_at, {
                id: "created_at",
                header: "Member since",
                cell: ({ renderValue }) =>
                    formatDistanceToNow(
                        renderValue()?.slice(-1) === "Z"
                            ? parseISO(
                                  renderValue() || new Date().toISOString()
                              )
                            : parseJSON(
                                  renderValue() || new Date().toUTCString()
                              ), // TODO: Need to add created_at to the user data from the backend.
                        {
                            addSuffix: true,
                        }
                    ),
                minSize: 240,
            }),
            columnHelper.display({
                id: "actions",
                header: () => null,
                cell: ({ row }: { row: { original: UserWithRole } }) => (
                    <DropdownMenu
                        trigger={
                            <IconButton
                                icon={<DotsHorizontalIcon />}
                                size="small"
                            />
                        }
                    >
                        <MenuItem
                            icon={<ShieldIcon />}
                            onClick={() => setUpdatingRole(row.original)}
                        >
                            Update Role
                        </MenuItem>
                        {/* <MenuItem disabled icon={<KeyIcon />}>
                            View Tokens
                        </MenuItem> */}
                        {/* <MenuItem icon={<XSquareIcon />}>Deactivate</MenuItem> */}
                    </DropdownMenu>
                ),
                size: 40,
            }),
        ],
        []
    );

    const handleFilterChange = useCallback(
        (filters: ColumnFiltersState) => setColumnFilters(filters),
        []
    );

    if (isLoading) return null;

    return (
        <div className={styles.root}>
            <PageTitle title={MEMBERS_NAV.title} titleIcon={MEMBERS_NAV.icon} />
            <MemberFilterToolbar
                onChange={handleFilterChange}
                value={columnFilters}
            />
            <ReactTable
                columns={columns}
                data={data || []}
                onGlobalFilterChange={setGlobalFilter}
                state={tableState}
                renderEmptyState={({ filtering }) => (
                    <EmptyState
                        loading={isLoading}
                        text={
                            filtering
                                ? "No members match your search"
                                : error
                                ? "Something went wrong. Please refresh and try again."
                                : "No Members in this Organization."
                        }
                    />
                )}
            />
            <Dialog
                open={updatingRole !== undefined}
                onOpenChange={(open) =>
                    setUpdatingRole((prev) => (!open ? undefined : prev))
                }
            >
                <UpdateRoleDialog
                    onSuccess={() => {
                        setUpdatingRole(undefined);
                        mutate();
                    }}
                    data={updatingRole}
                />
            </Dialog>
        </div>
    );
}

export default OrganizationMemberSettings;

function MemberFilterToolbar({
    onChange,
    value,
}: {
    onChange: (v: ColumnFiltersState) => void;
    value: ColumnFiltersState;
}) {
    const { data } = useSWR<Role[]>(["get", "/api/v1/auth/role"], axiosFetcher);

    const attributes = useMemo(() => {
        if (data?.length) {
            return [
                {
                    icon: <ShieldIcon />,
                    label: "Role",
                    column: "role",
                    pinned: true,
                    options: data.map(({ id, name }) => ({
                        value: String(id),
                        label: name,
                    })),
                },
            ];
        } else {
            return [];
        }
    }, [data]);

    return (
        <TableFilterToolbar
            onChange={onChange}
            attributes={attributes}
            value={value}
        />
    );
}
