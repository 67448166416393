import styles from "./styles/LogoLoader.module.scss";
import LogoIcon from "icons/logo.svg";

function LogoLoader(): JSX.Element {
    return (
        <div className={styles.container}>
            <LogoIcon width={80} height={80} viewBox="0 0 40 40" />
        </div>
    );
}

export default LogoLoader;
