import axios from "axios";
import AxiosLoader from "shared/components/AxiosLoader";
import Button from "shared/components/Button";
import { FormEventHandler, useCallback, useState } from "react";
import { Team } from "shared/models";
import LogoAndName from "../signup/LogoAndName";
import { toast_json_error } from "shared/utils/toast";
import styles from "./styles/JoinTeam.module.scss";

function JoinTeamContainer(): JSX.Element {
    return (
        <div className={styles.gridContainer}>
            <div className={styles.flexContainer}>
                <div className={styles.logoAndName}>
                    <LogoAndName />
                </div>
                <JoinTeam />
            </div>
        </div>
    );
}

const JoinTeam = () => {
    const loadFunc = useCallback(() => axios.post("/users/me/find_team"), []);
    return (
        <AxiosLoader
            onload={(team: Team) => <JoinTeamLoaded team={team} />}
            loadFunc={loadFunc}
        />
    );
};

function JoinTeamLoaded({ team }: { team: Team }): JSX.Element {
    const [submitting, setSubmitting] = useState(false);

    const onContinue: FormEventHandler = (e) => {
        e.preventDefault();
        setSubmitting(true);
        axios
            .post("/users/me/join_team", {
                team_id: team.id,
            })
            .then(() => {
                window.location.href = "/";
                setSubmitting(false);
            })
            .catch((e) => {
                setSubmitting(false);
                toast_json_error(e, "Failed to join associated team");
            });
    };

    return (
        <>
            <div className={styles.title}>Your team is already on Fennel!</div>
            <div className={styles.teamCard}>
                <div className={styles.nameAndCount}>
                    <span className={styles.name}>{team.name}</span>
                    <span className={styles.count}>
                        {members(team.users.length)}
                    </span>
                </div>
                <Avatars maxShown={2} users={team.users} />
            </div>
            <form className={styles.form} onSubmit={onContinue}>
                <Button
                    type="submit"
                    color="primary"
                    className={styles.continueButton}
                    disabled={submitting}
                >
                    Continue
                </Button>
            </form>
        </>
    );
}

function members(num: number): string {
    if (num < 2) {
        return `${num} member`;
    }
    return `${num} members`;
}

interface AvatarsProps {
    users: { first_name: string }[];
    maxShown: 1 | 2 | 3 | 4;
}

function Avatars({ users, maxShown }: AvatarsProps): JSX.Element {
    if (users.length === 0) {
        return <div />;
    }
    const lst = [];
    const shown = users.length > maxShown ? maxShown : users.length;
    users.forEach((user, idx) => {
        if (idx < shown) {
            lst.push(user.first_name[0].toUpperCase());
        }
    });
    if (users.length > maxShown) {
        lst.push(`+${users.length - maxShown}`);
    }

    return (
        <div className={styles.avatars}>
            {lst.map((av, idx) => (
                <div key={idx} className={styles.avatar}>
                    {av}
                </div>
            ))}
        </div>
    );
}

export default JoinTeamContainer;
