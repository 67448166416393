import styles from "./styles/OwnerFilter.module.scss";
import PipelineIcon from "icons/pipeline.svg";
import SourceIcon from "icons/source.svg";
import CubeIcon from "icons/cube.svg";
import RadarIcon from "icons/radar.svg";

function ErrorOriginType({ type }: { type: string }): JSX.Element {
    switch (type.toLocaleLowerCase()) {
        case "pipeline":
            return (
                <PipelineIcon
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    style={{ flex: "none" }}
                />
            );
        case "source":
            return (
                <SourceIcon
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    style={{ flex: "none" }}
                />
            );
        case "extracthistorical":
            return (
                <CubeIcon
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    style={{ flex: "none" }}
                />
            );
        default:
            return (
                <RadarIcon
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    style={{ flex: "none" }}
                />
            );
    }
}

export function ErrorOriginTypeFilter({ name }: { name: string }): JSX.Element {
    return (
        <div className={styles.filter}>
            <ErrorOriginType type={name} />
            <span>{name}</span>
        </div>
    );
}

export function ErrorOriginNameFilter({
    name,
    metadata,
}: {
    name: string;
    metadata?: { [key: string]: string };
}): JSX.Element {
    return (
        <div className={styles.filter}>
            <ErrorOriginType type={metadata?.origin_type || ""} />
            <span>{name}</span>
        </div>
    );
}
