import {
    Prism as SyntaxHighlighter,
    createElement,
} from "react-syntax-highlighter";

const styleReset = {
    ['pre[class*="language-"]']: {},
};

interface Props {
    language: string;
    code: string;
}

function CodeBlock({ language, code }: Props): JSX.Element {
    return (
        <SyntaxHighlighter
            language={language}
            renderer={({ rows, stylesheet, useInlineStyles }) => {
                return rows.map((row, index) => {
                    const children = row.children;
                    const lineNumberElement = children?.shift();

                    /**
                     * We will take current structure of the rows and rebuild it
                     * according to the suggestion here https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/376#issuecomment-1246115899
                     */
                    if (lineNumberElement) {
                        row.children = [
                            {
                                ...lineNumberElement,
                                properties: {
                                    ...lineNumberElement.properties,
                                    style: {
                                        ...lineNumberElement.properties?.style,
                                    },
                                } as typeof lineNumberElement.properties,
                            },
                            {
                                children,
                                properties: {
                                    className: [],
                                    style: {
                                        wordBreak: "break-all",
                                        whiteSpace: "pre-wrap",
                                    },
                                },
                                tagName: "span",
                                type: "element",
                            },
                        ];
                    }

                    return createElement({
                        node: row,
                        stylesheet,
                        useInlineStyles,
                        key: index,
                    });
                });
            }}
            style={styleReset}
            showLineNumbers
            wrapLongLines
        >
            {code}
        </SyntaxHighlighter>
    );
}

export default CodeBlock;
