import classnames from "classnames";
import { ExpectationResult } from "../models";
import styles from "./styles/Expectation.module.scss";
import CheckCircleIcon from "icons/check-circle.svg";
import MinusCircleIcon from "icons/minus-circle.svg";
import { percentile } from "../utils/utils";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    expectationResult: ExpectationResult;
}

function Expectation({
    expectationResult,
    className,
    ...rest
}: Props): JSX.Element {
    const {
        threshold,
        expectation_type: expType,
        success_cnt,
        failure_cnt,
        columns,
    } = expectationResult;

    const successRatio = success_cnt / (success_cnt + failure_cnt);
    const meetExp = isMeetingExp(successRatio, threshold);

    return (
        <div className={classnames(styles.container, className)} {...rest}>
            <div className={classnames(styles.header, meetExp && styles.meet)}>
                <div className={styles.headerLeft}>
                    {meetExp ? (
                        <CheckCircleIcon
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                        />
                    ) : (
                        <MinusCircleIcon
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                        />
                    )}
                    {expName(expType, columns)}
                </div>
                <div className={styles.headerRight}>
                    {threshold ? (
                        <div className={styles.threshold}>
                            Threshold: {percentile(threshold)}
                        </div>
                    ) : null}
                    <div className={styles.successRatio}>
                        {percentile(successRatio)}
                    </div>
                </div>
            </div>
            <Bar successRatio={successRatio} threshold={threshold} />
        </div>
    );
}

function expName(expType: string, columns: string[]): string {
    return `${expType} (${columns.join(", ")})`;
}

function Bar({
    successRatio,
    threshold,
}: {
    successRatio: number;
    threshold?: number;
}): JSX.Element {
    const meetExp = isMeetingExp(successRatio, threshold);

    return (
        <div className={styles.barContainer}>
            <div
                className={classnames(styles.ratioBar, meetExp && styles.meet)}
                style={{ width: percentile(successRatio) }}
            />
            {threshold && !meetExp ? (
                <div
                    className={styles.thresholdLine}
                    style={{ left: percentile(threshold) }}
                />
            ) : null}
        </div>
    );
}

function isMeetingExp(
    successRatio: number,
    threshold: number | undefined
): boolean {
    return !(threshold && successRatio < threshold);
}

export default Expectation;
