import { PropsWithChildren } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import styles from "./styles/Tooltip.module.scss";

function Tooltip({
    children,
    content,
    disabled,
    onPointerDownOutside,
    side = "bottom",
}: PropsWithChildren<{
    content?: string;
    disabled?: boolean;
    onPointerDownOutside?: TooltipPrimitive.DismissableLayerProps["onPointerDownOutside"];
    side?: "top" | "right" | "bottom" | "left";
}>) {
    if (disabled || !content) {
        return <>{children}</>;
    }
    return (
        <TooltipPrimitive.Provider delayDuration={200}>
            <TooltipPrimitive.Root>
                <TooltipPrimitive.Trigger
                    asChild
                    onClick={(e) => e.preventDefault()}
                    onMouseDown={(e) => onPointerDownOutside?.(e as any)}
                >
                    {children}
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal>
                    <TooltipPrimitive.Content
                        className={styles.content}
                        side={side}
                        sideOffset={8}
                        align="center"
                        onPointerDownOutside={onPointerDownOutside}
                    >
                        {content}
                    </TooltipPrimitive.Content>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
}

export default Tooltip;
