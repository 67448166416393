import { useCallback, useEffect, useMemo, useState } from "react";
import axios, { AxiosError } from "axios";
import { mutate } from "swr";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { toast_json_error, toast_success } from "shared/utils/toast";
import type { Role, UserWithRole } from "shared/models";
import { useCurrentUser } from "../../../context/CurrentUser";

import {
    DialogActions,
    DialogClose,
    DialogContent,
    DialogTitle,
} from "shared/components/Dialog";
import Button from "shared/components/Button";
import UserDisplay from "shared/components/UserDisplay";
import RoleSelector from "shared/components/RoleSelector";
import { Field } from "shared/components/TextInput";

import styles from "./styles/UpdateRoleDialog.module.scss";

type FormValues = {
    role_id?: Role["id"];
};

const UpdateRoleDialog = ({
    onSuccess,
    data,
}: {
    onSuccess: () => void;
    data?: UserWithRole;
}) => {
    const { user: currentUser } = useCurrentUser();
    const [loading, setLoading] = useState<boolean>(false);

    const defaultValues = useMemo(
        () => ({
            role_id: data?.role.id,
        }),
        [data]
    );

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
    } = useForm<FormValues>({
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const onSubmit: SubmitHandler<FormValues> = useCallback(
        async ({ role_id }) => {
            if (!data || !role_id) {
                return;
            }

            setLoading(true);
            try {
                await axios.post(
                    `/api/v1/auth/role/assign`,
                    {
                        role_id,
                        user_email: data.user.email,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                setLoading(false);
                if (currentUser) {
                    mutate([
                        "get",
                        `/api/v1/auth/role?user_email=${encodeURIComponent(
                            currentUser.email
                        )}`,
                    ]);
                }
                toast_success(
                    `Role updated for ${data.user.first_name} ${data.user.last_name}`
                );
                onSuccess();
                reset();
            } catch (e) {
                setLoading(false);
                toast_json_error(
                    e as AxiosError<{ detail?: string }>,
                    "Something went wrong"
                );
            }
        },
        [currentUser, data]
    );

    if (!data) {
        return null;
    }

    return (
        <DialogContent>
            <DialogTitle>Update Role</DialogTitle>
            <div className={styles.content}>
                <UserDisplay
                    name={`${data.user.first_name} ${data.user.last_name}`}
                    meta={`Current role: ${data.role.name}`}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="role_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => (
                            <>
                                <Field
                                    helper="Caution: This will invalidate any existing tokens for this user."
                                    label="Select a role:"
                                    state={fieldState}
                                >
                                    <RoleSelector {...field} />
                                </Field>
                            </>
                        )}
                    />
                    <DialogActions>
                        <DialogClose>
                            <Button variant="outline">Cancel</Button>
                        </DialogClose>
                        <Button
                            disabled={!isDirty || !isValid}
                            loading={loading}
                            color="primary"
                            type="submit"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </DialogContent>
    );
};

export default UpdateRoleDialog;
