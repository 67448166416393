import { Children, PropsWithChildren, ReactElement, useMemo } from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

import styles from "./styles/DropdownMenu.module.scss";

function DropdownMenu({
    children,
    trigger,
    align = "end",
    sideOffset = 0,
    open,
    onOpenChange,
}: PropsWithChildren<{
    align?: DropdownMenuPrimitive.DropdownMenuContentProps["align"];
    trigger: JSX.Element;
    sideOffset?: number;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}>) {
    const items = useMemo(
        () =>
            Children.map(children, (child) => (
                <DropdownMenuPrimitive.Item
                    disabled={(child as ReactElement)?.props.disabled}
                    asChild
                    onClick={(e) => {
                        e.stopPropagation();
                        (child as ReactElement)?.props.onClick(e);
                    }}
                >
                    {child}
                </DropdownMenuPrimitive.Item>
            )),
        [children]
    );
    return (
        <DropdownMenuPrimitive.Root open={open} onOpenChange={onOpenChange}>
            <DropdownMenuPrimitive.Trigger asChild>
                {trigger}
            </DropdownMenuPrimitive.Trigger>
            <DropdownMenuPrimitive.Portal>
                <DropdownMenuPrimitive.Content
                    className={styles.content}
                    align={align}
                    sideOffset={sideOffset}
                >
                    {items}
                </DropdownMenuPrimitive.Content>
            </DropdownMenuPrimitive.Portal>
        </DropdownMenuPrimitive.Root>
    );
}

export default DropdownMenu;

export const DropdownSeparator = () => (
    <DropdownMenuPrimitive.Separator className={styles.separator} />
);
