import { PropsWithChildren, useEffect, useState } from "react";
import styles from "./styles/ExpandableSection.module.scss";
import MinusIcon from "icons/minus.svg";
import PlusIcon from "icons/plus.svg";
import Switch from "./Switch";

interface ExpandableSectionProps {
    title: JSX.Element | string;
    expanded?: boolean;
    isToggle?: boolean;
}

export const ExpandableSection = ({
    title,
    children,
    expanded = false,
    isToggle,
}: PropsWithChildren<ExpandableSectionProps>) => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    useEffect(() => {
        setIsExpanded(expanded);
    }, [expanded]);
    return (
        <>
            <div
                className={styles.row}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div
                    className={
                        isExpanded
                            ? styles.titleExpanded
                            : styles.titleCollapsed
                    }
                >
                    {title}
                </div>
                <div>
                    {!isToggle ? (
                        <>{isExpanded ? <MinusIcon /> : <PlusIcon />}</>
                    ) : (
                        <Switch ref={null} checked={isExpanded} />
                    )}
                </div>
            </div>
            {isExpanded && <div>{children}</div>}
        </>
    );
};
