import { SchemaField } from "../../../utils/types";
import { getChildIcon } from "../../dag/node.utils";
import styles from "../styles/EntityPage.module.scss";

function SchemaSection({ schema }: { schema: SchemaField[] }) {
    return (
        <div className={styles.schemaTable}>
            {schema.map((field, index) => (
                <div className={styles.schemaItem} key={index}>
                    <div className={styles.schemaField}>
                        {field.name}
                        <div className={styles.schemaIcons}>
                            {field.qualifiers.map((icon) => {
                                return getChildIcon(icon);
                            })}
                        </div>
                    </div>
                    <div className={styles.schemaType}>{field.type}</div>
                </div>
            ))}
        </div>
    );
}

export default SchemaSection;
