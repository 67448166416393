import { Node, Edge, ReactFlowProvider } from "reactflow";

import { DAG } from "./dag/DAG";
import styles from "./styles/LineageSection.module.scss";
import { useContext, useEffect, useState } from "react";
import { CurrentViewContext } from "../../context/CurrentView/CurrentViewProvider";
import { Loader } from "./graph/Loader";

export interface DAG {
    nodes: Node[];
    edges: Edge[];
}

function LineageSection({
    focusedNodes,
}: {
    focusedNodes?: { id: string }[];
}): JSX.Element {
    const { nodes, edges } = useContext(CurrentViewContext);
    const [loadTimeout, setLoadTimeout] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setLoadTimeout(true), 10000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <div className={styles.wrapper}>
            {(!nodes?.length || !edges?.length) && !loadTimeout ? (
                <Loader height={560} />
            ) : (
                <ReactFlowProvider>
                    <DAG
                        nodes={nodes as Node[]}
                        edges={edges as Edge[]}
                        focusedNodes={focusedNodes}
                    />
                </ReactFlowProvider>
            )}
        </div>
    );
}

export default LineageSection;
