import { useCallback, useMemo, useState } from "react";
import axios, { AxiosError } from "axios";
import { formatDistanceToNow, parseISO } from "date-fns/esm";
import { useParams } from "react-router-dom";

import ClipboardPre from "shared/components/ClipboardPre";
import Chip from "shared/components/Chip";
import ProgressChip from "shared/components/ProgressChip";
import SearchBar from "shared/components/search/SearchBar";
import Table, { Column } from "shared/components/Table";
import AxiosLoader from "shared/components/AxiosLoader";
import { Filter, hasOnePred } from "shared/components/search/filters";

import CheckCircleIcon from "icons/check-circle.svg";
import StopCircleIcon from "icons/stop-circle.svg";
import WarnIcon from "icons/warning.svg";
import MinusCircleIcon from "icons/minus-circle.svg";
import XCircleIcon from "icons/x-circle.svg";

import { toast_json_error, toast_success } from "shared/utils/toast";
import { stringCompare, branchedLink } from "shared/utils/utils";
import {
    ExtractHistoricalJob,
    ExtractHistoricalJobStatus,
} from "shared/models";

import styles from "./styles/ExtractHistoricalPage.module.scss";
import { QUERIES_NAV } from "shared/constants/navigation";
import { DOC_LINKS } from "shared/constants/docs";
import ConsolePage from "../console-page/ConsolePage";

const STATUS_MAP = {
    [ExtractHistoricalJobStatus.Created]: {
        icon: undefined,
        label: undefined,
    },
    [ExtractHistoricalJobStatus.InProgress]: {
        icon: undefined,
        label: undefined,
    },
    [ExtractHistoricalJobStatus.Cancelled]: {
        icon: <MinusCircleIcon color="#12101980" />,

        label: "Cancelled",
    },
    [ExtractHistoricalJobStatus.Failed]: {
        icon: <XCircleIcon color="#B32F29" />,

        label: "Failed",
    },
    [ExtractHistoricalJobStatus.Complete]: {
        icon: <CheckCircleIcon color="#2E8D76" />,

        label: "Succeeded",
    },
};

const ExtractHistorical = () => {
    const { branchName } = useParams();
    const loadFunc = useCallback(
        () => axios.get(branchedLink(branchName, "query_offline/list")),
        [branchName]
    );
    return (
        <AxiosLoader
            onload={(jobs: ExtractHistoricalJob[]) => (
                <JobsTableLoaded jobs={jobs} />
            )}
            loadFunc={loadFunc}
        />
    );
};

const JobsTableLoaded = ({
    jobs: allJobs,
}: {
    jobs: ExtractHistoricalJob[];
}) => {
    const { branchName } = useParams();
    const [filtered, setFiltered] = useState<ExtractHistoricalJob[]>(
        allJobs.sort((a, b) => stringCompare(b.started_at, a.started_at))
    );

    const handleCancel = useCallback(
        async (request_id: string) => {
            try {
                await axios.post(
                    branchedLink(
                        branchName,
                        `query_offline/${request_id}/cancel`
                    )
                );
                toast_success("Job cancelled");
            } catch (e) {
                toast_json_error(
                    e as AxiosError<{ detail?: string }>,
                    "Something went wrong"
                );
            }
        },
        [branchName]
    );

    const columns: Column<ExtractHistoricalJob>[] = useMemo(
        () => [
            {
                header: "Request ID",
                renderFunc: (job) => {
                    return (
                        <div>
                            <RequestID id={job.request_id} />
                        </div>
                    );
                },
                sortFunc: (x, y) => stringCompare(x.request_id, y.request_id),
            },
            {
                header: "Progress",
                renderFunc: (job) => (
                    <div>
                        <div style={{ width: "9rem" }}>
                            <JobStatusChip data={job} />
                        </div>
                        <div className={styles.actionButtonContainer}>
                            {(job.failure_rate as number) > 0 && (
                                <div
                                    title="View Errors"
                                    className={styles.actionButton}
                                    onClick={() =>
                                        window.open(
                                            branchedLink(branchName, "errors") +
                                                "?type=ExtractHistorical&origin=" +
                                                job.request_id,
                                            "_blank"
                                        )
                                    }
                                >
                                    <WarnIcon />
                                </div>
                            )}
                            {(job.status.toLowerCase() ===
                                ExtractHistoricalJobStatus.InProgress ||
                                job.status.toLowerCase() ===
                                    ExtractHistoricalJobStatus.Created) && (
                                <div
                                    title="Cancel Request"
                                    className={styles.actionButton}
                                    onClick={() => handleCancel(job.request_id)}
                                >
                                    <StopCircleIcon />
                                </div>
                            )}
                        </div>
                    </div>
                ),
                sortFunc: (x, y) => stringCompare(x.status, y.status),
            },
            /*{
                header: "Created By",
                renderFunc: (job) => (
                    <Chip icon={<Avatar name={job.created_by.first_name} />}>
                        <span>{job.created_by.email}</span>
                    </Chip>
                ),
                sortFunc: (x, y) =>
                    stringCompare(x.created_by.email, y.created_by.email),
            },*/
            {
                header: "Output Path",
                renderFunc: (job) => {
                    return (
                        <div>
                            <OutputPath
                                output_bucket={job.output_bucket}
                                output_prefix={job.output_prefix}
                            />
                        </div>
                    );
                },
                sortFunc: (x, y) =>
                    stringCompare(x.output_bucket, y.output_bucket),
            },
            {
                header: "Started",
                renderFunc: (job) => (
                    <div>
                        <span>
                            {formatDistanceToNow(
                                parseISO(`${job.started_at || ""}`),
                                {
                                    addSuffix: true,
                                }
                            )}
                        </span>
                    </div>
                ),
                sortFunc: (x, y) => stringCompare(x.started_at, y.started_at),
            },
            {
                header: false,
                renderFunc: () => <></>,
            },
        ],
        [handleCancel]
    );

    return (
        <ConsolePage
            header={{
                title: QUERIES_NAV.title,
                icon: QUERIES_NAV.icon,
            }}
            subheader={
                <SearchBar
                    onSearch={(text, filters) => {
                        setFiltered(filterJobs(allJobs, text, filters));
                    }}
                />
            }
            content={
                <Table
                    className={styles.table}
                    data={filtered}
                    columns={columns}
                    rowKeyFunc={(fs) => fs.request_id}
                    dataUnit="Job"
                    emptyText="No offline queries found"
                    learnMore={DOC_LINKS.queryOffline}
                />
            }
        />
    );
};

const JobStatusChip = ({ data }: { data: ExtractHistoricalJob }) => {
    const status = data?.status.toLowerCase() as ExtractHistoricalJobStatus;

    return status !== ExtractHistoricalJobStatus.Cancelled &&
        status !== ExtractHistoricalJobStatus.Complete ? (
        <ProgressChip
            completionRate={(data?.completion_rate || 0) * 100}
            failureRate={(data?.failure_rate || 0) * 100}
        />
    ) : (
        <Chip icon={status ? STATUS_MAP[status].icon : undefined}>
            {status ? STATUS_MAP[status].label : null}
        </Chip>
    );
};

const RequestID = ({ id }: { id: string }) => {
    const [tooltipContent, setTooltipContent] = useState("Copy Request ID");

    const handleCopyClick = useCallback(() => {
        if (id) {
            navigator.clipboard.writeText(id);
        }
        // Handle tooltip interaction
        setTooltipContent("Copied!");
        new Promise((res) => setTimeout(res, 2000)).then(() =>
            setTooltipContent("Click to copy")
        );
    }, [id]);

    const displayId = useMemo(() => {
        const start = id.split("-").shift();
        return `${start}...${id.slice(id.length - 5, id.length)}`;
    }, [id]);

    return (
        <ClipboardPre tooltip={tooltipContent} onCopyClick={handleCopyClick}>
            {displayId}
        </ClipboardPre>
    );
};

const OutputPath = ({
    output_bucket,
    output_prefix,
}: {
    output_bucket: string;
    output_prefix: string;
}) => {
    const fullPath = `s3://${output_bucket}/${output_prefix}`;
    const displayPath = useMemo(() => {
        if (fullPath.length > 32) {
            return `${fullPath.slice(0, 32)}...`;
        }

        return fullPath;
    }, [fullPath]);

    const [tooltipContent, setTooltipContent] = useState("Copy Output Bucket");

    const handleCopyClick = useCallback(() => {
        if (fullPath) {
            navigator.clipboard.writeText(fullPath);
        }
        // Handle tooltip interaction
        setTooltipContent("Copied!");
        new Promise((res) => setTimeout(res, 2000)).then(() =>
            setTooltipContent("Click to copy")
        );
    }, [fullPath]);

    return (
        <ClipboardPre tooltip={tooltipContent} onCopyClick={handleCopyClick}>
            {displayPath}
        </ClipboardPre>
    );
};

function filterJobs(
    jobs: ExtractHistoricalJob[],
    searchText: string,
    filters: Filter[]
): ExtractHistoricalJob[] {
    const text = searchText.toLowerCase();
    let filtered = [...jobs].filter(
        (ds) => text == "" || ds.request_id.toLowerCase().includes(text)
    );

    filters.forEach((f) => {
        if (f.key == "createdBy") {
            filtered = filtered.filter((d) => hasOnePred(f, d.created_by.id));
        }
    });

    return filtered;
}

export default ExtractHistorical;
