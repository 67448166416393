// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p3Rw0t97Bc8Onu4HY_dn{width:100%;padding:1rem 0;display:flex;position:relative;cursor:pointer;align-items:center;justify-content:space-between}.AX4Q4ohI679ZIWGiXqw5{font-size:1.25rem;transition:font-size .24s}.nT3rkhwMp3t6_1YaDcqH{font-size:1rem;transition:font-size .24s}", "",{"version":3,"sources":["webpack://./src/console/shared/components/styles/ExpandableSection.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,cAAA,CACA,YAAA,CACA,iBAAA,CACA,cAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,sBACI,iBAAA,CACA,yBAAA,CAGJ,sBACI,cAAA,CACA,yBAAA","sourcesContent":[".row {\n    width: 100%;\n    padding: 1rem 0;\n    display: flex;\n    position: relative;\n    cursor: pointer;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.titleExpanded {\n    font-size: 1.25rem;\n    transition: font-size 0.24s;\n}\n\n.titleCollapsed {\n    font-size: 1rem;\n    transition: font-size 0.24s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "p3Rw0t97Bc8Onu4HY_dn",
	"titleExpanded": "AX4Q4ohI679ZIWGiXqw5",
	"titleCollapsed": "nT3rkhwMp3t6_1YaDcqH"
};
export default ___CSS_LOADER_EXPORT___;
