import styles from "./styles/UsageSettings.module.scss";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import classNames from "classnames";
import { toast_json_error } from "shared/utils/toast";

type OverviewChild = {
    number: number;
    percent: number;
    background: string;
    usedPercent: number;
};

interface Overview {
    overage: OverviewChild;
    read: OverviewChild;
    remaining: OverviewChild;
}

const getOverview = ({ total, used }: { total: number; used: number }) => {
    return {
        overage: {
            percent: used > total ? ((used - total) * 100) / used : 0,
            number: used > total ? used - total : 0,
            background: "rgba(210, 48, 61, 1)",
            usedPercent: used > total ? ((used - total) * 100) / total : 0,
        },
        read: {
            percent: used > total ? (total * 100) / used : (used * 100) / total,
            number: used > total ? total : used,
            background: "rgba(118, 108, 249, 1)",
            usedPercent: used > total ? 100 : (used * 100) / total,
        },
        remaining: {
            percent: used > total ? 0 : ((total - used) * 100) / total,
            number: used > total ? 0 : total - used,
            background: "rgba(235, 236, 237, 1)",
            usedPercent: used > total ? 0 : ((total - used) * 100) / total,
        },
    };
};

const formatNumber = (value: number) => {
    return Math.abs(Number(value)) >= 1.0e9
        ? (Math.abs(Number(value)) / 1.0e9).toFixed(2) + " Billion"
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? (Math.abs(Number(value)) / 1.0e6).toFixed(2) + " Million"
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? (Math.abs(Number(value)) / 1.0e3).toFixed(2) + " Thousand"
        : Math.abs(Number(value));
};

const getBarStyles = (overview: Overview, type: string) => {
    const styles = {
        overage: {
            width: overview.overage.percent + "%",
            background: overview.overage.background,
        },
        read: {
            width: overview.read.percent + "%",
            background: overview.read.background,
        },
        remaining: {
            width: overview.remaining.percent + "%",
            background: overview.remaining.background,
        },
    };

    return styles[type as "overage" | "read" | "remaining"];
};

const OverviewSummaryCard = ({
    stats,
    cardTitle,
    cardSubtitle,
}: {
    stats: OverviewChild;
    cardTitle: string;
    cardSubtitle: string;
}) => {
    return (
        <div className={styles.summaryCard}>
            <div className={styles.summaryCardContent}>
                <div className={styles.summaryCardTitle}>
                    <h4
                        style={{
                            borderColor: stats.background,
                        }}
                    >
                        {cardTitle}
                    </h4>
                    <p>{stats.usedPercent.toFixed(2)}%</p>
                </div>
                <h1>{formatNumber(stats.number)}</h1>
                <p>{cardSubtitle}</p>
            </div>
        </div>
    );
};

export const CoreOverview = () => {
    const [overViewState, setOverViewState] = useState({
        total: 0,
        used: 0,
        date: ""
    });

    useEffect(() => {
        axios
            .post(`/billing/total`)
            .then(({ data }: AxiosResponse) => {
                setOverViewState({
                    total: data.total_reserved,
                    used: data.total_cores,
                    date: data.contract_date
                });
            })
            .catch((err) => {
                setOverViewState({ total: 0, used: 0, date: "" });
                toast_json_error(err, "Error fetching data");
            });
    }, []);

    const overview = getOverview(overViewState);
    const cardTypes = ["read", "remaining", "overage"];

    return (
        <div className={styles.card}>
            <div className={styles.content}>
                <div className={styles.cardHeader}>
                    <div className={styles.cardHeaderTitle}>
                        <h2>Overview</h2>
                        <p>
                            Total core consumption over your current
                            contract
                        </p>
                        {overViewState.date && <p className={styles.activeSince}>
                        {`(Active since ${new Date(overViewState.date).toLocaleDateString()})`}
                        </p>}
                    </div>
                    <div
                        className={classNames(
                            styles.cardHeaderTitle,
                            styles.rightAlign
                        )}
                    >
                        <h1>{formatNumber(overViewState.used)}</h1>
                        <p>Total Cores Consumed</p>
                    </div>
                </div>
                {overViewState.total > 0 && <>
                <div className={styles.progress}>
                    {cardTypes.map((type) => (
                        <div
                            className={styles.progressbar}
                            style={getBarStyles(overview, type)}
                            key={type}
                        />
                    ))}
                </div>
                <div className={styles.summary}>
                    {cardTypes.map((type) => (
                        <OverviewSummaryCard
                            key={type}
                            stats={(overview as any)[type]}
                            cardTitle={type == "read" ? "consumed" : type}
                            cardSubtitle={
                                type == "remaining"
                                    ? "Reserved Read Units"
                                    : "Read Units"
                            }
                        />
                    ))}
                </div>
                </>
                }
            </div>
        </div>
    );
};
