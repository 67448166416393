// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RE4ALhHVPjIK0LjIUigQ{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/console/shared/components/dag/DAG.module.scss"],"names":[],"mappings":"AAAA,sBACC,UAAA,CACA,WAAA","sourcesContent":[".root {\n\twidth: 100%;\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "RE4ALhHVPjIK0LjIUigQ"
};
export default ___CSS_LOADER_EXPORT___;
