import {
    toISOString,
    toUtcString,
} from "shared/components/date-picker/date-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./DashboardPage.module.scss";
import axios, { AxiosResponse } from "axios";
import {
    branchedLink,
    branchedMetricsLink,
    maybePlural,
    yTickLabelAbbreviator,
} from "shared/utils/utils";
import {
    DataPoint,
    parsePromResponse,
    PromResponse,
} from "shared/utils/prometheus";
import {
    formatDateShort,
    formatDateTime,
    FULL_GRAPH_HEIGHT,
    FULL_GRAPH_WIDTH,
} from "shared/components/graph/Graph";
import NumberSeries from "shared/components/graph/NumberSeries";
import MultiNumberSeries from "shared/components/graph/MultiNumberSeries";
import { ExpectationResult } from "shared/models";
import Table, { Column } from "shared/components/Table";
import { DOC_LINKS } from "shared/constants/docs";
import Expectation from "shared/components/Expectation";
import InteractiveGraph from "shared/components/graph/InteractiveGraph";
import { Dataset } from "shared/utils/types";
import { VERSION_SEPARATOR } from "shared/constants/constants";

function ExpCount({ cnt }: { cnt: number }): JSX.Element {
    return (
        <div className={styles.expCount}>
            <span>{cnt}</span>
            <span>{maybePlural(cnt, "row")}</span>
        </div>
    );
}

export function ExpectationSection({
    startDate,
    endDate,
    dataset,
}: DatasetGraphProps): JSX.Element {
    const { branchName } = useParams();
    const [exps, setExps] = useState<ExpectationResult[]>([]);

    useEffect(() => {
        axios
            .post(branchedLink(branchName, "aggregate_expectation_results"), {
                name: dataset?.name,
                kind: "Dataset",
                start: toUtcString(startDate),
                end: toUtcString(endDate),
            })
            .then((resp: AxiosResponse<ExpectationResult[]>) => {
                setExps(resp.data);
            });
    }, [startDate, endDate]);

    const columns: Column<ExpectationResult>[] = [
        {
            header: "Expectation",
            renderFunc: (exp) => <Expectation expectationResult={exp} />,
        },
        {
            header: "Successful",
            renderFunc: (exp) => <ExpCount cnt={exp.success_cnt} />,
        },
        {
            header: "Failed",
            renderFunc: (exp) => <ExpCount cnt={exp.failure_cnt} />,
        },
    ];

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Expectations</div>
                <div className={styles.graphSubtitle}>
                    {" "}
                    % of the rows that fail the user expectations in the given
                    time{" "}
                </div>
            </div>
            <div className={styles.expectation}>
                <Table
                    data={exps}
                    emptyText="This dataset does not have defined expectations in this timeframe"
                    learnMore={DOC_LINKS.expectation}
                    columns={columns}
                    rowKeyFunc={(exp) =>
                        `${exp.expectation_type} ${exp.columns.join(",")}`
                    }
                    dataUnit="Expectation"
                />
            </div>
        </div>
    );
}

export interface DatasetGraphProps {
    startDate: Date;
    endDate: Date;
    dataset?: Dataset;
}

function findMinMaxY(data: { [key: string]: DataPoint[] }) {
    let minY = Infinity;
    let maxY = -Infinity;

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            data[key].forEach((point) => {
                if (point.y < minY) {
                    minY = point.y;
                }
                if (point.y > maxY) {
                    maxY = point.y;
                }
            });
        }
    }

    return { minY, maxY };
}

export function BacklogGraph({
    startDate,
    endDate,
    dataset,
}: DatasetGraphProps) {
    const { branchName } = useParams();
    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Backlog</div>
                <div className={styles.graphSubtitle}>
                    Backlog of data that needs to be processed
                </div>
            </div>
            <div className={styles.graph}>
                <NumberSeries
                    yTickFormat={yTickLabelAbbreviator}
                    width={FULL_GRAPH_HEIGHT}
                    height={FULL_GRAPH_HEIGHT}
                    startDate={startDate}
                    endDate={endDate}
                    stroke="#2C2D3A"
                    strokeStyle="dotted"
                    lineLabel="Backlog"
                    annotationLabelFormat={(v) =>
                        `${Math.round(v * 100) / 100}`
                    }
                    loadFunc={() => {
                        return axios
                            .post(branchedMetricsLink(branchName, "backlog"), {
                                start: toISOString(startDate),
                                end: toISOString(endDate),
                                dataset_name: dataset?.name,
                            })
                            .then((resp: AxiosResponse<PromResponse>) => {
                                return parsePromResponse(resp.data);
                            });
                    }}
                />
            </div>
        </div>
    );
}

export function LastProcessedGraph({
    startDate,
    endDate,
    dataset,
}: DatasetGraphProps): JSX.Element {
    const { branchName } = useParams();
    const [graphData, setGraphData] = useState<Record<string, DataPoint[]>>({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [yDomain, setYDomain] = useState({ min: 0, max: 0 });
    useEffect(() => {
        setIsLoaded(false);
        axios
            .post(branchedMetricsLink(branchName, "processed_time"), {
                start: toISOString(startDate),
                end: toISOString(endDate),
                dataset_name: dataset?.name,
            })
            .then((resp: AxiosResponse<PromResponse>) => {
                const graphRespData = resp.data.data.result.reduce(
                    (acc: Record<string, DataPoint[]>, res) => {
                        const key =
                            res.metric.dataset_name.split(VERSION_SEPARATOR)[0];
                        const values = parsePromResponse({
                            data: {
                                result: [
                                    {
                                        metric: res.metric,
                                        values: res.values,
                                    },
                                ],
                            },
                        }).map((d) => ({
                            x: d.x,
                            y: d.y,
                        }));
                        if (acc[key]) {
                            acc[key].concat(values);
                        }
                        acc[key] = values;
                        return acc;
                    },
                    {}
                );

                const { minY, maxY } = findMinMaxY(graphRespData);

                setYDomain({ min: minY, max: maxY });
                setGraphData(graphRespData);
            })
            .catch((err) => {
                setGraphData({});
                console.error(err);
            })
            .finally(() => setIsLoaded(true));
    }, [startDate, endDate, dataset?.name]);

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Last Processed Time</div>
                <div className={styles.graphSubtitle}>
                    Timestamp of the last processed data
                </div>
            </div>

            <InteractiveGraph
                startDate={startDate}
                endDate={endDate}
                data={graphData}
                yDomain={yDomain}
                type="scatter"
                isLoaded={isLoaded}
                alignment="vertical"
                yTickAutoscale={true}
                yTickFormat={(tick) =>
                    formatDateShort(new Date(parseFloat(tick) * 1000))
                }
                annotationLabelFormat={(label) =>
                    formatDateTime(new Date(label * 1000))
                }
                showLegend={!dataset}
            />
        </div>
    );
}

export function ErrorsGraph({
    startDate,
    endDate,
    dataset,
}: DatasetGraphProps): JSX.Element {
    const { branchName } = useParams();
    const [pipelineData, setPipelineData] = useState<DataPoint[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(false);
        axios
            .post(branchedMetricsLink(branchName, "pipelines"), {
                start: toISOString(startDate),
                end: toISOString(endDate),
                dataset_name: dataset?.name,
            })
            .then((resp: AxiosResponse<PromResponse>) => {
                setPipelineData(parsePromResponse(resp.data));
            })
            .catch((err) => {
                setPipelineData([]);
                console.error(err);
            })
            .finally(() => setIsLoaded(true));
    }, [startDate, endDate, dataset?.name]);

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Errors</div>
                <div className={styles.graphSubtitle}>
                    Recent error activity in dataset related pipelines
                </div>
            </div>
            <MultiNumberSeries
                width={FULL_GRAPH_WIDTH}
                height={FULL_GRAPH_HEIGHT}
                startDate={startDate}
                endDate={endDate}
                configs={{
                    pipeline: {
                        stroke: "#CA2816",
                        lineLabel: "Errors",
                    },
                    extractor: {
                        stroke: "#F79388",
                        lineLabel: "Errors",
                    },
                }}
                data={{
                    pipeline: pipelineData,
                }}
                isLoaded={isLoaded}
            />
        </div>
    );
}

function addValuesForSameTimestamp(
    array1: DataPoint[],
    array2: DataPoint[]
): DataPoint[] {
    // Create a dictionary to store sums by timestamp
    const sumDict: { [key: number]: number } = {};

    // Helper function to add values to the dictionary
    function addToDict(array: DataPoint[]): void {
        array.forEach(({ x, y }) => {
            const timestamp = x.getTime();
            if (!sumDict[timestamp]) {
                sumDict[timestamp] = 0;
            }
            sumDict[timestamp] += y;
        });
    }

    // Add values from both arrays to the dictionary
    addToDict(array1);
    addToDict(array2);

    // Convert the dictionary back to an array format
    const result: DataPoint[] = Object.entries(sumDict).map(
        ([timestamp, value]) => ({
            x: new Date(parseInt(timestamp)),
            y: value,
        })
    );

    return result;
}

export function DatasetActivityGraph({
    startDate,
    endDate,
    dataset,
}: DatasetGraphProps): JSX.Element {
    const { branchName } = useParams();
    const [graphData, setGraphData] = useState<Record<string, DataPoint[]>>({});
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        setIsLoaded(false);
        axios
            .post(branchedMetricsLink(branchName, "dataset_activity"), {
                start: toISOString(startDate),
                end: toISOString(endDate),
                dataset_name: dataset?.name,
            })
            .then((resp: AxiosResponse<PromResponse>) => {
                const graphRespData = resp.data.data.result.reduce(
                    (acc: Record<string, DataPoint[]>, res) => {
                        const key =
                            res.metric.dataset_name.split(VERSION_SEPARATOR)[0];
                        const values = parsePromResponse({
                            data: {
                                result: [
                                    {
                                        metric: res.metric,
                                        values: res.values,
                                    },
                                ],
                            },
                        });
                        if (acc[key]) {
                            acc[key] = addValuesForSameTimestamp(
                                acc[key],
                                values
                            );
                        } else {
                            acc[key] = values;
                        }
                        return acc;
                    },
                    {}
                );

                setGraphData(graphRespData);
            })
            .catch((err) => {
                setGraphData({});
                console.error(err);
            })
            .finally(() => setIsLoaded(true));
    }, [startDate, endDate, dataset?.name]);

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Row Updates</div>
                <div className={styles.graphSubtitle}>
                    Changes to row count in datasets over time
                </div>
            </div>
            <div className={styles.graph}>
                <InteractiveGraph
                    startDate={startDate}
                    endDate={endDate}
                    data={graphData}
                    isLoaded={isLoaded}
                    alignment="vertical"
                    yTickAutoscale={false}
                    yTickFormat={yTickLabelAbbreviator}
                    showLegend={!dataset}
                />
            </div>
        </div>
    );
}
