import { useState } from "react";
import styles from "./TagRenderer.module.scss";
import Tag from "../Tag";
import Tooltip from "../Tooltip";

export const DirectTags = ({ tags }: { tags: string[] }) => {
    return (
        <>
            {tags.map((tag) => (
                <Tag name={tag} key={tag} size="small" />
            ))}
        </>
    );
};

export const InheritedTags = ({ tags }: { tags: string[] }) => {
    const [expanded, setExpanded] = useState(false);

    const onMouseEnter = () => {
        setExpanded(true);
    };

    const onMouseLeave = () => {
        setExpanded(false);
    };

    return (
        <Tooltip content="Inherited Tags">
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={styles.container}
            >
                {expanded ? (
                    <>
                        {tags.map((tag) => (
                            <Tag name={tag} key={tag} size="small" inherited />
                        ))}
                    </>
                ) : (
                    <Tag
                        name={"+" + tags.length.toString()}
                        key={"collapsed-tags"}
                        size="small"
                        collapsed
                        inherited
                    />
                )}
            </div>
        </Tooltip>
    );
};
