import { Outlet } from "react-router-dom";
import styles from "./styles/SettingsPage.module.scss";

function SettingsPage(): JSX.Element {
    return (
        <div className={styles.gridContainer}>
            <div className={styles.flexContainer}>
                <div className={styles.root}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default SettingsPage;
