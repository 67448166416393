import { useNavigate } from "react-router-dom";

import { useHasPermission } from "../../../context/CurrentUser";

import DropdownMenu from "shared/components/DropdownMenu";
import MenuItem from "shared/components/MenuItem";
import Tooltip from "shared/components/Tooltip";

import ShieldIcon from "icons/shield.svg";
import EditIcon from "icons/edit.svg";
import XSquareIcon from "icons/x-square.svg";

const RoleActionsDropdown = ({
    trigger,
    isDefaultRole,
    isSystemRole,
    roleId,
    onSetDefaultRole,
    onDeleteRole,
}: {
    trigger: JSX.Element;
    isSystemRole?: boolean;
    isDefaultRole?: boolean;
    roleId?: number;
    onSetDefaultRole?: (role_id: number) => void;
    onDeleteRole?: (role_id: number) => void;
}) => {
    const navigate = useNavigate();
    const hasPermission = useHasPermission();

    return (
        <DropdownMenu trigger={trigger}>
            <Tooltip
                side="top"
                content={isSystemRole ? "Cannot edit system roles." : undefined}
            >
                <MenuItem
                    disabled={isSystemRole}
                    icon={<EditIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/settings/organization/roles/${roleId}`);
                    }}
                >
                    Edit Role
                </MenuItem>
            </Tooltip>
            {hasPermission.default_role ? (
                <Tooltip
                    side="top"
                    content={
                        isDefaultRole
                            ? "This role is already the default"
                            : undefined
                    }
                >
                    <MenuItem
                        disabled={isDefaultRole}
                        icon={<ShieldIcon />}
                        onClick={(e) => {
                            e.stopPropagation();
                            roleId !== undefined
                                ? onSetDefaultRole?.(roleId)
                                : null;
                        }}
                    >
                        Set as Default Role
                    </MenuItem>
                </Tooltip>
            ) : null}
            <Tooltip
                side="top"
                content={
                    isSystemRole
                        ? "Cannot delete system roles."
                        : isDefaultRole
                        ? "Cannot delete the default role."
                        : undefined
                }
            >
                <MenuItem
                    disabled={isDefaultRole || isSystemRole}
                    icon={<XSquareIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        roleId !== undefined ? onDeleteRole?.(roleId) : null;
                    }}
                >
                    Delete Role
                </MenuItem>
            </Tooltip>
        </DropdownMenu>
    );
};

export default RoleActionsDropdown;
