import classnames from "classnames";
import FeaturesetIcon from "icons/featureset.svg";
import OwnerIcon from "icons/owner.svg";
import styles from "./styles/FeaturesetsTable.module.scss";
import { stringCompare } from "../utils/utils";
import Table, { Column } from "./Table";
import { DOC_LINKS } from "../constants/docs";
import MetaField from "./MetaField";
import { useUpdateEntityParams } from "../../context/CurrentView";

interface Featureset {
    name: string;
    owner: string;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    featuresets: Featureset[];
    windowed?: boolean;
    emptyText?: string;
    isLearnMore?: boolean;
}

function FeaturesetsTable({
    featuresets,
    className,
    windowed,
    emptyText,
    isLearnMore,
    ...rest
}: Props): JSX.Element {
    const { updateEntityParams } = useUpdateEntityParams();
    const columns: Column<Featureset>[] = [
        {
            header: "Name",
            renderFunc: (fs) => (
                <div>
                    <FeaturesetIcon />
                    <span>{fs.name}</span>
                </div>
            ),
            sortFunc: (x, y) => stringCompare(x.name, y.name),
        },
        {
            header: "Owner",
            renderFunc: (fs) => (
                <MetaField icon={<OwnerIcon />} name={fs.owner || ""} />
            ),
            sortFunc: (x, y) => stringCompare(x.owner, y.owner),
        },
    ];

    return (
        <Table
            windowed={windowed}
            className={classnames(className, styles.table)}
            data={featuresets}
            columns={columns}
            rowKeyFunc={(fs) => fs.name}
            onRowClick={(row) => {
                updateEntityParams("featureset", row.name);
            }}
            dataUnit="Featureset"
            learnMore={isLearnMore ? DOC_LINKS.featureset : undefined}
            emptyText={emptyText}
            {...rest}
        />
    );
}

export default FeaturesetsTable;
