import styles from "./styles/UsageSettings.module.scss";
import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import {
    DateValue,
    convertToDate,
    toISOString,
} from "shared/components/date-picker/date-types";
import { INIT_END_DATE, INIT_START_DATE } from "shared/constants/constants";
import DateRangeControl from "shared/components/date-picker/DateRangeControl";
import classNames from "classnames";
import {
    PromResponse,
    parsePromResponse,
} from "shared/utils/prometheus";
import { DEFAULT_STROKE, FULL_GRAPH_HEIGHT, FULL_GRAPH_WIDTH, StrokeStyle } from "shared/components/graph/Graph";
import NumberSeries from "shared/components/graph/NumberSeries";


export const CoreMetrics = () => {
    const [startDateValue, setStartDateValue] =
        useState<DateValue>(INIT_START_DATE);
    const [endDateValue, setEndDateValue] = useState<DateValue>(INIT_END_DATE);
    const startDate = convertToDate(startDateValue);
    const endDate = convertToDate(endDateValue);

    return (
        <div className={classNames(styles.card, styles.metrics)}>
            <div className={styles.content}>
                <div className={styles.cardHeader}>
                    <div className={styles.cardHeaderTitle}>
                        <h2>Core Units Consumed</h2>
                    </div>
                    <div className={styles.controller}>
                        <DateRangeControl
                            onDateUpdate={(fromDate, toDate) => {
                                setStartDateValue(fromDate);
                                setEndDateValue(toDate);
                            }}
                            initialFromDate={startDateValue}
                            initialToDate={endDateValue}
                            alignment="right"
                        />
                    </div>
                </div>
                <UsageGraph
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
        </div>
    );
};

interface GraphProps {
    startDate: Date;
    endDate: Date;
}


function UsageGraph({ startDate, endDate }: GraphProps): JSX.Element {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className={styles.graphContainer}>
            <div className={styles.fullGraph}>
            <NumberSeries
                        startDate={startDate}
                        endDate={endDate}
                        height={FULL_GRAPH_HEIGHT*1.6}
                        width={FULL_GRAPH_WIDTH}
                        lineLabel="Cores per minute"
                        stroke={DEFAULT_STROKE}
                        loadFunc={() => {
                            return axios.post(`/billing/timeseries`, {
                                start: toISOString(startDate),
                                end: toISOString(endDate),
                                })
                                .then((resp: AxiosResponse<PromResponse>) =>
                                    parsePromResponse(resp.data)
                                )
                                .finally(() => setIsLoaded(true));
                        }}
                        isLoaded={isLoaded}
                    />
            </div>
        </div>
    );
}
