import { useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import useSWR from "swr";
import axios from "axios";
import {
    ExpandedState,
    SortingState,
    Updater,
    createColumnHelper,
} from "@tanstack/react-table";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { RoleWithCount } from "shared/models";
import { axiosFetcher } from "shared/utils/utils";
import { toast_json_error, toast_success } from "shared/utils/toast";

import ReactTable from "shared/components/ReactTable";
import { AlertDialog } from "shared/components/AlertDialog";
import Chip from "shared/components/Chip";
import IconButton from "shared/components/IconButton";
import Tooltip from "shared/components/Tooltip";
import Button from "shared/components/Button";
import EmptyState from "shared/components/EmptyState/EmptyState";
import PageTitle from "../shared/PageTitle";
import RoleDeleteDialog from "./RoleDeleteDialog";

import ChevronRightIcon from "icons/chevron-right.svg";
import DotsHorizontalIcon from "icons/dots-horizontal.svg";
import LockIcon from "icons/lock.svg";
import PlusIcon from "icons/plus.svg";

import styles from "./styles/OrganizationRoleSettings.module.scss";
import RoleOverviewDialog from "./RoleOverviewDialog";
import RoleActionsDropdown from "./RoleActionsDropdown";
import { ROLES_NAV } from "shared/constants/navigation";

const roleColumnHelper = createColumnHelper<RoleWithCount>();

const DEFAULT_SORT = [
    {
        id: "created_at",
        desc: false,
    },
];

function OrganizationRoleSettings() {
    const [expanded, setExpanded] = useState<Record<string, boolean>>({});
    const [sorting, _setSorting] = useState<SortingState>(DEFAULT_SORT);

    const tableState = useMemo(
        () => ({ expanded: expanded as ExpandedState, sorting }),
        [expanded, sorting]
    );

    const [deletingRole, setDeletingRole] = useState<number | undefined>();

    const { data, error, mutate, isLoading } = useSWR<RoleWithCount[]>(
        ["get", "/api/v1/auth/role?detail=true"],
        axiosFetcher
    );

    const handleRowClick = ({ index }: { index: number }) =>
        setExpanded((prev) => {
            if (prev[index]) {
                return {
                    [index]: !prev[index],
                };
            }
            return {
                [index]: true,
            };
        });

    const handleSetDefaultRole = useCallback(async (role_id: number) => {
        axios
            .put(
                "/api/v1/auth/role/default",
                { role_id },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then(() => {
                mutate();
                toast_success("Default role updated.");
                setExpanded({});
            })
            .catch((e) => toast_json_error(e, "Something went wrong."));
    }, []);

    const columns = useMemo(
        () => [
            roleColumnHelper.accessor(({ role }) => role.name, {
                header: "Name",
                cell: ({ renderValue, row }) => (
                    <div className={styles.role_name}>
                        <p>{renderValue()}</p>
                        {row.original.role.system_role ? (
                            <Tooltip content="Built-in roles are immutable">
                                <div>
                                    <LockIcon width={12} height={12} />
                                </div>
                            </Tooltip>
                        ) : null}
                        {row.original.role.is_default ? (
                            <Chip color="primary">Default</Chip>
                        ) : null}
                    </div>
                ),
            }),
            roleColumnHelper.accessor(
                ({ num_users }) => `${num_users} accounts`,
                {
                    header: "Accounts",
                }
            ),
            roleColumnHelper.accessor(({ role }) => role.created_at, {
                id: "created_at",
                header: "Created at",
                cell: ({ renderValue }) => {
                    return formatDistanceToNow(
                        parseISO(`${renderValue() || ""}Z`),
                        {
                            addSuffix: true,
                        }
                    );
                },
            }),
            roleColumnHelper.display({
                id: "actions",
                header: () => null,
                cell: ({ row }: { row: { original: RoleWithCount } }) => (
                    <div
                        className={classnames(
                            styles.actions,
                            styles.row_actions
                        )}
                    >
                        <RoleActionsDropdown
                            trigger={
                                <IconButton
                                    icon={<DotsHorizontalIcon />}
                                    size="small"
                                />
                            }
                            roleId={row.original.role.id}
                            isSystemRole={row.original.role.system_role}
                            isDefaultRole={row.original.role.is_default}
                            onSetDefaultRole={handleSetDefaultRole}
                            onDeleteRole={(id) => setDeletingRole(id)}
                        />
                        <ChevronRightIcon />
                    </div>
                ),
                size: 32,
            }),
        ],
        []
    );

    if (isLoading) return null;

    return (
        <div className={styles.root}>
            <PageTitle title={ROLES_NAV.title} titleIcon={ROLES_NAV.icon}>
                <div className={styles.actions}>
                    <Link to="/settings/organization/roles/create">
                        <Button
                            color="primary"
                            icon={<PlusIcon />}
                            size="small"
                        >
                            Create Role
                        </Button>
                    </Link>
                </div>
            </PageTitle>
            <ReactTable
                columns={columns}
                data={data || []}
                state={tableState}
                onRowClick={handleRowClick}
                onExpandedChange={
                    setExpanded as (state: Updater<ExpandedState>) => void
                }
                renderSubComponent={({ row }) => (
                    <RoleOverviewDialog
                        open
                        role_id={row.original.role.id}
                        onOpenChange={(open) =>
                            setExpanded((prev) => ({
                                ...prev,
                                [row.index]: open,
                            }))
                        }
                        onSetDefaultRole={handleSetDefaultRole}
                        onDeleteRole={(id) => setDeletingRole(id)}
                    />
                )}
                renderEmptyState={() => (
                    <EmptyState
                        loading={isLoading}
                        text={
                            error
                                ? "Something went wrong. Please refresh and try again."
                                : "No Roles in this Organization."
                        }
                    />
                )}
            />
            <AlertDialog
                open={deletingRole !== undefined}
                onOpenChange={(open) =>
                    setDeletingRole((prev) => (!open ? undefined : prev))
                }
            >
                <RoleDeleteDialog
                    roleId={deletingRole}
                    onSuccess={() => {
                        setDeletingRole(undefined);
                        setExpanded({});
                        mutate();
                    }}
                />
            </AlertDialog>
        </div>
    );
}

export default OrganizationRoleSettings;
