import { createContext, PropsWithChildren, useState } from "react";

export const InspectorContext = createContext({
    isSidebarOpen: false,
    toggleSidebar: () => {
        return;
    },
    setSidebarOpen: (open: boolean) => {
        return;
    },
});

export const InspectorProvider = ({ children }: PropsWithChildren) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const setSidebarOpen = (open: boolean) => {
        setIsSidebarOpen(open);
    };

    return (
        <InspectorContext.Provider
            value={{ isSidebarOpen, toggleSidebar, setSidebarOpen }}
        >
            {children}
        </InspectorContext.Provider>
    );
};
