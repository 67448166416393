import styles from "./styles/Input.module.scss";
import XCircleIcon from "icons/x-circle.svg";
import { useState } from "react";
import classnames from "classnames";

interface ValidationRule {
    pattern?: RegExp;
    nonEmpty?: boolean;
    checkFn?: (v: string) => boolean;
    message: string;
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    validationRules?: ValidationRule[];
}

function Input({
    validationRules,
    onBlur,
    onChange,
    className,
    ...rest
}: Props) {
    const [validationError, setValidationError] = useState<string>();

    const validate = (
        e:
            | React.FocusEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!validationRules) {
            return true;
        }
        return validationRules.every((rule) => {
            let matched = true;
            if (rule.pattern) {
                matched = rule.pattern.test(e.target.value);
            } else if (rule.nonEmpty) {
                matched = !!e.target.value;
            } else if (rule.checkFn) {
                matched = rule.checkFn(e.target.value);
            }
            if (!matched) {
                setValidationError(rule.message);
            }
            return matched;
        });
    };

    const innerOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
            onBlur(e);
        }
        if (validate(e) && validationError) {
            setValidationError(undefined);
        }
    };

    const innerOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (onChange) {
            onChange(e);
        }
        // only trigger onChange validation if there's an error msg shown to the user
        if (validationError && validate(e)) {
            setValidationError(undefined);
        }
    };

    return (
        <div className={classnames(styles.container, className)}>
            <input
                {...rest}
                onBlur={innerOnBlur}
                onChange={innerOnChange}
                className={styles.input}
            />
            {validationError ? (
                <div className={styles.validation}>
                    <XCircleIcon width={16} height={16} viewBox="0 0 24 24" />
                    {validationError}
                </div>
            ) : null}
        </div>
    );
}

export default Input;
