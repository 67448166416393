import { ForwardedRef, forwardRef } from "react";
import classnames from "classnames";

import * as SwitchPrimitive from "@radix-ui/react-switch";

import styles from "./styles/Switch.module.scss";

interface SwitchProps extends SwitchPrimitive.SwitchProps {
    size?: "small" | "large";
}

function Switch(
    { size = "small", ...props }: SwitchProps,
    ref: ForwardedRef<HTMLButtonElement>
) {
    return (
        <SwitchPrimitive.Root
            {...props}
            ref={ref}
            className={classnames(styles.root, styles[size])}
        >
            <SwitchPrimitive.Thumb className={styles.thumb} />
        </SwitchPrimitive.Root>
    );
}

export default forwardRef(Switch);
