import { useState } from "react";
import {
    Filter,
    FilterConfig,
    FilterValue,
    FilterVerb,
    MultiSelectConfig,
} from "./filters";
import CloseIcon from "icons/close.svg";
import FilterValueMultiSelect from "./FilterValueMultiSelect";
import FilterVerbSelect from "./FilterVerbSelect";
import styles from "./styles/FilterBox.module.scss";
import { maybePlural } from "../../utils/utils";
import classnames from "classnames";
import { FILTER_META_SEPARATOR } from "../../constants/constants";

interface FilterBoxProps {
    filter: Filter;
    filterConfig: FilterConfig;
    onClose: () => void;
    onFilterChange: (newFilter: Filter) => void;
    onSubmit?: () => void;
    fixedFilters?: boolean;
}

function FilterBox({
    filter,
    filterConfig,
    onClose,
    onFilterChange,
    onSubmit,
    fixedFilters,
}: FilterBoxProps): JSX.Element {
    return (
        <MultiSelectFilter
            filter={filter}
            filterConfig={filterConfig}
            multiSelectConfig={filterConfig.kind}
            onClose={onClose}
            onFilterChange={onFilterChange}
            onSubmit={onSubmit}
            fixedFilters={fixedFilters}
        />
    );
}

enum FilterBoxStatus {
    None,
    EditFilterVerb,
    EditFilterValue,
}

interface MultiSelectFilterProps {
    filter: Filter;
    filterConfig: FilterConfig;
    multiSelectConfig: MultiSelectConfig;
    onClose: () => void;
    onFilterChange: (newFilter: Filter) => void;
    onSubmit?: () => void;
    fixedFilters?: boolean;
}

function MultiSelectFilter({
    filter,
    filterConfig,
    multiSelectConfig,
    onClose,
    onFilterChange,
    onSubmit,
    fixedFilters,
}: MultiSelectFilterProps): JSX.Element {
    const [status, setStatus] = useState(FilterBoxStatus.None);

    const onToggleSelect = (value: FilterValue) => {
        onFilterChange({
            key: filter.key,
            verb: maybeUpdateVerb(filter.verb, value, multiSelectConfig),
            value,
        });
    };

    return (
        <div className={styles.filterContainer}>
            <div
                className={classnames(styles.filter, {
                    [styles.filterDisabled]: fixedFilters,
                })}
            >
                <div className={styles.property}>
                    {filterConfig.icon && (
                        <filterConfig.icon
                            width={12}
                            height={12}
                            viewBox="0 0 24 24"
                        />
                    )}
                    <span>{filterConfig.propertyName}</span>
                </div>
                <div className={styles.verbContainer}>
                    <div
                        className={styles.verb}
                        onClick={() => {
                            if (
                                status === FilterBoxStatus.None &&
                                !fixedFilters
                            ) {
                                setStatus(FilterBoxStatus.EditFilterVerb);
                            }
                        }}
                    >
                        {filter.verb}
                    </div>
                    {status === FilterBoxStatus.EditFilterVerb ? (
                        <div>
                            <FilterVerbSelect
                                verb={filter.verb}
                                filter={filter}
                                filterConfig={filterConfig}
                                onChangeVerb={(verb: FilterVerb) => {
                                    onFilterChange({
                                        ...filter,
                                        verb,
                                    });
                                    setStatus(FilterBoxStatus.None);
                                }}
                                onCancel={() => setStatus(FilterBoxStatus.None)}
                            />
                        </div>
                    ) : null}
                </div>
                <div
                    className={styles.summary}
                    onClick={() => {
                        if (status === FilterBoxStatus.None && !fixedFilters) {
                            setStatus(FilterBoxStatus.EditFilterValue);
                        }
                    }}
                >
                    {filter.value.length > 1 ? (
                        <>
                            {filter.value.length}{" "}
                            {maybePlural(
                                filter.value.length,
                                multiSelectConfig.valueCategory,
                                undefined
                            )}
                        </>
                    ) : (
                        filter.value.toString().split(FILTER_META_SEPARATOR)[0]
                    )}
                </div>
                <div className={styles.close} onClick={onClose}>
                    <CloseIcon width={12} height={12} viewBox="0 0 24 24" />
                </div>
            </div>
            {status === FilterBoxStatus.EditFilterValue ? (
                <FilterValueMultiSelect
                    filter={filter}
                    filterConfig={filterConfig}
                    multiSelectConfig={multiSelectConfig}
                    onToggleSelect={onToggleSelect}
                    onSubmit={() => {
                        setStatus(FilterBoxStatus.None);
                        if (filterConfig.kind.kind === "single-select") {
                            onSubmit?.();
                        }
                    }}
                />
            ) : null}
        </div>
    );
}

function maybeUpdateVerb(
    verb: FilterVerb,
    value: FilterValue,
    multiSelectConfig: MultiSelectConfig
): FilterVerb {
    switch (multiSelectConfig.relationship) {
        case "has-one":
            if (Array.isArray(value) && value.length > 1 && verb === "is") {
                return "is one of";
            }
            if (
                Array.isArray(value) &&
                value.length <= 1 &&
                verb === "is one of"
            ) {
                return "is";
            }
            return verb;
        case "has-many":
            return verb;
    }
}

export default FilterBox;
