import styles from "./styles/FeaturesetsPage.module.scss";
import FeaturesTable from "shared/components/FeaturesTable";
import { Feature } from "shared/utils/types";

function FeaturesList({
    features: allFeatures,
    filteredFeatures,
}: {
    features: Feature[];
    filteredFeatures: Feature[];
}): JSX.Element {
    return (
        <>
            <FeaturesTable
                features={filteredFeatures}
                className={styles.featuresetsTable}
                isLearnMore={allFeatures.length === 0}
                emptyText={
                    allFeatures.length === 0
                        ? "No features created yet."
                        : "No features found."
                }
            />
        </>
    );
}

export default FeaturesList;
