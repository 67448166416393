import CloseIcon from "icons/close.svg";
import CopyIcon from "icons/copy.svg";

import styles from "./styles/CodeModal.module.scss";
import { useEffect, useRef } from "react";
import CodeBlock from "./CodeBlock";
import { toast_info } from "../utils/toast";

interface Props {
    code: string;
    title: string;
    onClose: () => void;
}

function CodeModal({ code, onClose, title }: Props): JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onPressEsc = (e: KeyboardEvent) => {
            if (e.key == "Escape") {
                onClose();
            }
        };

        document.addEventListener("keydown", onPressEsc);
        return () => {
            document.removeEventListener("keydown", onPressEsc);
        };
    }, [onClose]);

    useEffect(() => {
        const onClickOutside = (e: MouseEvent) => {
            if (!modalRef.current?.contains(e.target as Node)) {
                onClose();
            }
        };

        containerRef.current?.addEventListener("click", onClickOutside);
        return () => {
            containerRef.current?.removeEventListener("click", onClickOutside);
        };
    }, [onClose, containerRef]);

    const onCopyCode = async () => {
        await navigator.clipboard.writeText(code);
        toast_info("Code has been successfully copied to your clipboard");
    };

    return (
        <div className={styles.container} ref={containerRef}>
            <div className={styles.modal} ref={modalRef}>
                <div className={styles.titleRow}>
                    <div className={styles.titleLeft}>
                        <span>{title}</span>
                    </div>
                    <div className={styles.titleRight}>
                        <div className={styles.copyCode} onClick={onCopyCode}>
                            Copy Code
                            <CopyIcon
                                width={16}
                                height={16}
                                viewBox="0 0 24 24"
                            />
                        </div>
                        <div
                            className={styles.closeContainer}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <CodeBlock language="python" code={code} />
                </div>
            </div>
        </div>
    );
}

export default CodeModal;
