import TargetIcon from "icons/target.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { branchedLink } from "../../../utils/utils";
import Tooltip from "../../Tooltip";
import IconButton from "../../IconButton";

function ToLineage({ node }: { node: string }) {
    const { branchName } = useParams();
    const [searchParams] = useSearchParams();
    const ENTITY_LIST = ["dataset", "feature", "featureset"];
    const newSearchParams = new URLSearchParams();

    searchParams.forEach((val, key) => {
        if (ENTITY_LIST.includes(key)) {
            newSearchParams.set(key, val);
        }
    });
    const path = branchedLink(
        branchName,
        `lineage?node=${node}&${newSearchParams.toString()}`
    );
    const navigate = useNavigate();
    return (
        <Tooltip
            content="Show In Lineage"
            onPointerDownOutside={() => navigate(path)}
        >
            <IconButton
                icon={<TargetIcon />}
                size="small"
                data-test={`tolineage-test`}
            />
        </Tooltip>
    );
}

export default ToLineage;
