import styles from "./styles/Tag.module.scss";
import ChevronRight from "icons/chevron-right.svg";

function Tag({
    name,
    size,
    inherited,
    collapsed,
}: {
    name: string;
    size?: string;
    inherited?: boolean;
    collapsed?: boolean;
}): JSX.Element {
    return (
        <div className={size === "small" ? styles.tagSmall : styles.tag}>
            {inherited ? (
                <div className={styles.tagCircleOverlap}></div>
            ) : (
                <div className={styles.tagCircle}></div>
            )}
            <span>{name}</span>
            {collapsed ? <ChevronRight /> : null}
        </div>
    );
}

export default Tag;
