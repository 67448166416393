import { useEffect, useState } from "react";
import { DataPoint } from "../../utils/prometheus";
import { COLOR_PALETTE, COLOR_PALETTE_LENGTH } from "./colorUtils";
import { FULL_GRAPH_HEIGHT, FULL_GRAPH_WIDTH, StrokeStyle } from "./Graph";
import GraphLegends from "./GraphLegends";
import MultiNumberSeriesResponsive, { Props } from "./MultiNumberSeries";
import styles from "./styles/InteractiveGraph.module.scss";

interface GraphProps extends Partial<Props> {
    startDate: Date;
    endDate: Date;
    data: Record<string, DataPoint[]> | undefined;
    type?: "line" | "scatter";
    isLoaded?: boolean;
    alignment?: "horizontal" | "vertical";
    showLegend?: boolean;
    legendValueAccessor?: (
        data: Record<string, DataPoint[]>,
        legendId: string
    ) => number | string;
}

function InteractiveGraph({
    startDate,
    endDate,
    data,
    yDomain,
    type = "line",
    isLoaded,
    alignment = "horizontal",
    configs,
    showLegend = true,
    ...restProps
}: GraphProps): JSX.Element {
    const [graphData, setGraphData] = useState<
        Record<string, DataPoint[]> | undefined
    >(data);
    const [graphDataCopy, setGraphDataCopy] = useState<
        Record<string, DataPoint[]> | undefined
    >(data);
    const [cmdClickId, setCmdClickId] = useState<string | undefined>(undefined);
    const config =
        configs ||
        Object.keys(graphDataCopy as object).reduce(
            (
                acc: {
                    [key: string]: {
                        stroke: string;
                        lineLabel: string;
                        strokeStyle: StrokeStyle;
                    };
                },
                key,
                index
            ) => {
                acc[key] = {
                    stroke: COLOR_PALETTE[index % COLOR_PALETTE_LENGTH],
                    lineLabel: key,
                    strokeStyle: "solid" as const,
                };
                return acc;
            },
            {}
        );

    useEffect(() => {
        setGraphData(data);
        setGraphDataCopy(data);
    }, [data]);

    const onLegendClick = (id: string) => {
        if (cmdClickId === id) {
            setGraphData({ ...graphDataCopy });
            setCmdClickId(undefined);
        } else {
            const cmdClick = Object.keys(graphDataCopy as object).reduce(
                (acc: { [key: string]: DataPoint[] }, key) => {
                    acc[key] =
                        key === id && !!graphDataCopy ? graphDataCopy[key] : [];
                    return acc;
                },
                {}
            );
            setGraphData(cmdClick);
            setCmdClickId(id);
        }
    };

    const onLegendCmdClick = (id: string) => {
        setCmdClickId(undefined);
        graphData?.[id].length
            ? setGraphData({ ...graphData, [id]: [] })
            : setGraphData({
                  ...graphData,
                  [id]: [...(graphDataCopy?.[id] as DataPoint[])],
              });
    };

    const legends = Object.keys(config).map((key) => ({
        color: config[key]?.stroke,
        id: key,
        label: key,
        onClick: onLegendClick,
        onCmdClick: onLegendCmdClick,
        disabled: !(graphData && graphData[key]?.length > 0),
        style: config[key].strokeStyle || "solid",
    }));

    return (
        <div
            className={
                alignment === "horizontal"
                    ? styles.alignHorizontal
                    : styles.alignVertical
            }
        >
            <MultiNumberSeriesResponsive
                {...restProps}
                width={FULL_GRAPH_WIDTH}
                height={FULL_GRAPH_HEIGHT}
                startDate={startDate}
                endDate={endDate}
                yDomain={yDomain}
                configs={config}
                type={type}
                data={{ ...graphData }}
                isLoaded={isLoaded}
            />
            {showLegend && (
                <div
                    className={styles.legends}
                    style={{
                        maxHeight: FULL_GRAPH_HEIGHT,
                    }}
                >
                    <GraphLegends
                        alignment={alignment}
                        lines={legends}
                        isLoading={!isLoaded}
                    />
                </div>
            )}
        </div>
    );
}

export default InteractiveGraph;
