import axios from "axios";
import { PropsWithChildren, useEffect } from "react";
import { toast_info, toast_json_error } from "shared/utils/toast";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const LOGOUT_INACTIVE_TIME = 600000;

export const AppLogout = ({ children }: PropsWithChildren) => {
    let timer: any;

    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleTimer();
            });
        });
    }, []);

    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    const handleTimer = () => {
        timer = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            logoutAction();
        }, LOGOUT_INACTIVE_TIME);
    };

    const logoutAction = () => {
        localStorage.clear();
        axios
            .post("/logout")
            .then(() => {
                localStorage.setItem(
                    "autoLogoutLastPage",
                    window.location.href
                );
                window.location.href = "/login";
                toast_info("User inactive for more than 10 mins");
            })
            .catch((e) => toast_json_error(e, "Failed to log out"));
    };

    return <>{children}</>;
};
