import { PropsWithChildren } from "react";
import styles from "./styles/Chip.module.scss";
import classnames from "classnames";

// TODO: Support for icons/avatar alongside the text.
function Chip({
    children,
    color = "default",
    icon,
}: PropsWithChildren<{
    color?: "default" | "primary";
    icon?: JSX.Element;
}>) {
    return (
        <div
            className={classnames(styles.root, styles[color], {
                [styles.withIcon]: !!icon,
            })}
        >
            {icon || null}
            <p>{children}</p>
        </div>
    );
}

export default Chip;
