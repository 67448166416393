import "./shared/styles/app.less";
import Navbar from "./features/navbar/Navbar";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    Outlet,
} from "react-router-dom";
import IngestionsPage from "./features/ingestion/IngestionsPage";
import BatchSinkRunsPage from "./features/ingestion/BatchSinkRunsPage";
import SourcesPage from "./features/sources/SourcesPage";
import SignUpPage from "./features/signup/SignUpPage";
import LogInPage from "./features/signup/LogInPage";
import ForgotPasswordPage from "./features/signup/ForgotPasswordPage";
import ResetPasswordPage from "./features/signup/ResetPasswordPage";
import { ExtractHistoricalPage } from "./features/extract-historical";
import JoinTeam from "./features/onboard/JoinTeam";
import DashboardPage from "./features/dashboard/DashboardPage";
import ErrorsPage from "./features/errors/ErrorsPage";
import SettingsPage from "./features/settings/SettingsPage";
import { UsageSettings } from "./features/settings/UsageSettings/UsageSettings";
import { BranchesPage } from "./features/branches/BranchesPage";
import OrganizationMemberSettings from "./features/settings/OrganizationMemberSettings";
import OrganizationRoleSettings from "./features/settings/OrganizationRoleSettings";
import { ObservabilitySettings } from "./features/settings/ObservabilitySettings/ObservabilitySettings";
import UserSettings from "./features/settings/UserSettings";
import TokenSettings from "./features/settings/TokenSettings";
import OrganizationServiceAccountSettings from "./features/settings/OrganizationServiceAccountSettings";
import ServiceAccountDetail from "./features/settings/ServiceAccountDetail";
import CreateRole from "./features/settings/CreateRole";
import { useCurrentUser, useHasPermission } from "./context/CurrentUser";
import { ScrollRouteToTop } from "./shared/utils/ScrollRouteToTop";
import { CurrentViewProvider } from "./context/CurrentView";
import { InspectorProvider } from "./context/InspectorProvider";
import { CommitsPage } from "./features/commits/CommitsPage";
import Inspector from "./features/inspector/Inspector";
import LineagePage from "./features/lineage/LineagePage";
import { SSOSettings } from "./features/settings/SSOSettings/SSOSettings";
import { AppLogout } from "./context/AppLogout";
import EntititesPage from "./features/entitities/Entitites";
import DataPage from "./features/data/DataPage";
import BranchRedirectRoute from "./context/RedirectTo";

function SettingsRedirect() {
    const hasPermission = useHasPermission();

    return (
        <Navigate
            replace
            to={`/settings/${
                hasPermission.organization ? `organization/members` : "general"
            }`}
        />
    );
}

function DemoModeRedirect() {
    const currentUser = useCurrentUser();
    if (process.env.DEMO_MODE === "true") {
        return (
            <Navigate
                replace
                to={currentUser.user ? `/branch/main` : `/login`}
            />
        );
    }

    // When not in demo-mode and running in prod, this redirect is handled by
    // python (sends user to /tier/:tierName) so we can just return null here.
    return null;
}

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <ScrollRouteToTop />
            <Routes>
                {/** TODO: Can remove this once we separate py + js  */}
                <Route index element={<DemoModeRedirect />} />
                <Route path="/branch/:branchName" element={<MainConsole />}>
                    <Route
                        index
                        element={<BranchRedirectRoute defaultRoute="commits" />}
                    />
                    <Route path="metrics" element={<DashboardPage />} />
                    <Route path="ingestion" element={<IngestionsPage />} />
                    <Route path="sink_runs" element={<BatchSinkRunsPage />} />
                    <Route path="entities" element={<EntititesPage />} />
                    <Route path="data" element={<DataPage />} />
                    <Route path="errors" element={<ErrorsPage />} />
                    <Route path="queries" element={<ExtractHistoricalPage />} />
                    <Route path="commits" element={<CommitsPage />} />
                    <Route path="lineage" element={<LineagePage />} />
                </Route>

                <Route path="/branches" element={<MainSettings />}>
                    <Route index element={<BranchesPage />} />
                </Route>

                <Route path="/sources" element={<MainSettings />}>
                    <Route index element={<SourcesPage />} />
                </Route>

                <Route path="/usage" element={<MainSettings />}>
                    <Route index element={<UsageSettings />} />
                </Route>

                <Route path="/settings" element={<MainSettings />}>
                    <Route element={<SettingsPage />}>
                        <Route index element={<SettingsRedirect />} />
                        <Route
                            path="organization"
                            element={
                                <Navigate
                                    replace
                                    to="/settings/organization/members"
                                />
                            }
                        />
                        <Route
                            path="organization/members"
                            element={<OrganizationMemberSettings />}
                        />
                        <Route
                            path="organization/service-accounts"
                            element={<OrganizationServiceAccountSettings />}
                        />
                        <Route
                            path="organization/service-accounts/:id"
                            element={<ServiceAccountDetail />}
                        />
                        <Route
                            path="organization/observability"
                            element={<ObservabilitySettings />}
                        />
                        <Route
                            path="organization/roles"
                            element={<OrganizationRoleSettings />}
                        />
                        <Route
                            path="organization/roles/:id"
                            element={<CreateRole />}
                        />
                        <Route
                            path="organization/sso"
                            element={<SSOSettings />}
                        />
                        <Route path="general" element={<UserSettings />} />
                        <Route path="tokens" element={<TokenSettings />} />
                    </Route>
                </Route>

                <Route path="signup" element={<SignUpPage />} />
                <Route path="login" element={<LogInPage />} />
                <Route
                    path="forgot_password"
                    element={<ForgotPasswordPage />}
                />
                <Route path="reset_password" element={<ResetPasswordPage />} />
                <Route path="onboard" element={<JoinTeam />} />
            </Routes>
        </BrowserRouter>
    );
}

function MainConsole(): JSX.Element {
    return (
        <div className="shell">
            <AppLogout>
                <CurrentViewProvider>
                    <InspectorProvider>
                        <Navbar />
                        <Inspector />
                    </InspectorProvider>
                </CurrentViewProvider>
            </AppLogout>
        </div>
    );
}

function MainSettings(): JSX.Element {
    return (
        <div className="shell">
            <AppLogout>
                <Navbar />
                <Outlet />
            </AppLogout>
        </div>
    );
}

export default App;
