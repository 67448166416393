// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vV1gjDkCuWpdIr41n6lX{display:flex;gap:1rem}.NLxgyVqejKXmCmQi7rtV{flex:1;display:flex;align-items:center;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/console/features/settings/TokenSettings/styles/TokenCreateForm.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,QAAA,CAGJ,sBACI,MAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":[".input_row {\n    display: flex;\n    gap: 1rem;\n}\n\n.form_footer {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_row": "vV1gjDkCuWpdIr41n6lX",
	"form_footer": "NLxgyVqejKXmCmQi7rtV"
};
export default ___CSS_LOADER_EXPORT___;
