export type Config = {
    filterConfigs: { [key: string]: FilterConfig };
    filterOrder: string[];
};

export type FilterConfigKey = keyof Config["filterConfigs"];

export type FilterConfig = {
    icon?: React.FC<React.SVGAttributes<SVGElement>>;
    propertyName: string;
    kind: ConfigKind;
};

export type ConfigKind = MultiSelectConfig;

export type MultiSelectConfig = {
    kind: "multi-select" | "single-select";
    options: FilterOption[];
    relationship: "has-one" | "has-many";
    valueCategory: string; // The string shown in "Owner is 5 {valueCategory}". E.g, user, tag.
    component: React.FC<{ name: string; metadata?: { [key: string]: string } }>;
};

export type FilterOption = {
    key: string;
    name: string;
    value: number | string;
    metadata?: { [key: string]: string };
    metadataSearch?: boolean;
};

export type FilterOptionValue = FilterOption["value"];
export type FilterVerb = FilterHasOneVerb | FilterHasManyVerb;

type FilterHasOneVerb = "is" | "is not" | "is one of";
type FilterHasManyVerb =
    | "includes all of"
    | "includes any of"
    | "does not include all of"
    | "does not include any of";

export type Filter = {
    key: FilterConfigKey;
    verb: FilterVerb;
    value: FilterOptionValue[];
};

export type FilterValue = Filter["value"];

// returns a predicate whether v should be filtered given f
export function hasOnePred(f: Filter, v: FilterOptionValue): boolean {
    const included = f.value.includes(v);
    if (f.verb == "is" || f.verb == "is one of") {
        return included;
    }
    return !included;
}

export function hasManyPred(f: Filter, vs: FilterOptionValue[]): boolean {
    switch (f.verb) {
        case "includes all of":
            return f.value.every((v) => vs.includes(v));
        case "includes any of":
            return f.value.some((v) => vs.includes(v));
        case "does not include all of":
            return !f.value.every((v) => vs.includes(v));
        case "does not include any of":
            return !f.value.some((v) => vs.includes(v));
    }
    return true; // shouldn't happen, just for linter
}

export function defaultVerb(
    filterConfig: FilterConfig,
    value: FilterValue
): FilterVerb {
    switch (filterConfig.kind.relationship) {
        case "has-one":
            if (Array.isArray(value) && value.length > 1) {
                return "is one of";
            }
            return "is";
        case "has-many":
            return "includes all of";
    }
}
