import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import ArrowLeftIcon from "icons/arrow-left.svg";

import styles from "./PageTitle.module.scss";

function PageTitle({
    backTo,
    backText = "Go Back",
    children,
    title,
    description,
    titleIcon,
}: PropsWithChildren<{
    backText?: string;
    backTo?: string;
    title: string;
    description?: string;
    titleIcon?: React.ReactNode;
}>) {
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                {backTo ? (
                    <Link className={styles.back_link} to={backTo}>
                        <ArrowLeftIcon />
                        {backText}
                    </Link>
                ) : null}
                <div className={styles.title_wrapper}>
                    <h3>
                        {titleIcon}
                        {title}
                    </h3>
                </div>
                {description ? <p>{description}</p> : null}
            </div>
            {children}
        </div>
    );
}

export default PageTitle;
