import styles from "./styles/FeaturesetsPage.module.scss";
import FeaturesetsTable from "shared/components/FeaturesetsTable";
import { Featureset } from "shared/utils/types";

function FeaturesetsList({
    featuresets: allFeaturesets,
    filteredFeaturesets,
}: {
    featuresets: Featureset[];
    filteredFeaturesets: Featureset[];
}): JSX.Element {
    return (
        <>
            <FeaturesetsTable
                featuresets={filteredFeaturesets}
                className={styles.featuresetsTable}
                isLearnMore={allFeaturesets.length === 0}
                emptyText={
                    allFeaturesets.length === 0
                        ? "No featuresets created yet."
                        : "No featuresets found."
                }
            />
        </>
    );
}

export default FeaturesetsList;
