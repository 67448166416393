import { DatePresets, DateValue } from "./date-types";
import DatePicker from "react-datepicker";
import { useEffect, useRef, useState } from "react";

import "react-datepicker/dist/react-datepicker.min.css";
import "./styles/DateValuePicker.css";
import styles from "./styles/DateValuePicker.module.scss";
import classnames from "classnames";

interface Props {
    onValueChange: (d: DateValue) => void;
    onDismiss: () => void;
    initialValue: DateValue;
    alignment: "left" | "right";
}

function DateValuePicker({
    initialValue,
    onValueChange,
    onDismiss,
    alignment,
}: Props): JSX.Element {
    const [date, setDate] = useState<DateValue>(initialValue);
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onMouseDown = (e: Event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(e.target as Node)
            ) {
                onDismiss();
            }
        };

        document.addEventListener("mousedown", onMouseDown);
        return () => {
            document.removeEventListener("mousedown", onMouseDown);
        };
    }, [containerRef, onDismiss]);

    const onChange = (date: Date | null) => {
        if (date) {
            setDate(date);
            onValueChange(date);
            // no dismiss here, as both date and time edit would trigger
        }
    };
    const onSelect = (date: DatePresets) => {
        setDate(date);
        onValueChange(date);
        onDismiss();
    };

    return (
        <div
            className={classnames(
                styles.container,
                alignment == "left" && styles.left,
                alignment == "right" && styles.right
            )}
            ref={containerRef}
        >
            <QuickMenu onSelect={onSelect} />
            <div className={styles.pickerContainer}>
                <DatePicker
                    selected={date instanceof Date ? date : undefined}
                    onChange={onChange}
                    showTimeSelect
                    inline
                />
            </div>
        </div>
    );
}

const PRESETS: DatePresets[] = [
    "Now",
    "15 mins ago",
    "30 mins ago",
    "1 hour ago",
    "1 day ago",
    "1 week ago",
];

function QuickMenu({
    onSelect,
}: {
    onSelect: (dv: DatePresets) => void;
}): JSX.Element {
    return (
        <div className={styles.quickMenu}>
            <div key="quick" className={styles.quick}>
                Quick Select
            </div>
            {PRESETS.map((v, idx) => (
                <div
                    key={idx}
                    className={styles.item}
                    onClick={() => onSelect(v)}
                >
                    {v}
                </div>
            ))}
        </div>
    );
}

export default DateValuePicker;
