import {
    PropsWithChildren,
    forwardRef,
    ForwardedRef,
    ForwardRefExoticComponent,
    RefAttributes,
} from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import classnames from "classnames";
import input_styles from "./styles/TextInput.module.scss";
import styles from "./styles/SelectInput.module.scss";

import ChevronUpIcon from "icons/chevron-up.svg";
import ChevronDownIcon from "icons/chevron-down.svg";

export interface SelectProps extends SelectPrimitive.SelectProps {
    className?: string;
    disabled?: boolean;
    id?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    TriggerComponent?: ForwardRefExoticComponent<
        SelectPrimitive.SelectTriggerProps &
            RefAttributes<HTMLButtonElement> & { placeholder?: string }
    >;
}

const DefaultTrigger = forwardRef(
    (
        { className, id, disabled, placeholder }: SelectProps,
        ref: ForwardedRef<HTMLButtonElement>
    ) => (
        <SelectPrimitive.Trigger
            ref={ref}
            id={id}
            disabled={disabled}
            className={classnames(input_styles.input, styles.root, className)}
        >
            <SelectPrimitive.Value placeholder={placeholder} />
            <SelectPrimitive.Icon className="SelectIcon">
                <ChevronDownIcon />
            </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
    )
);
DefaultTrigger.displayName = "DefaultTrigger";

const SelectInput = forwardRef(
    (
        {
            children,
            className,
            disabled,
            id,
            placeholder,
            TriggerComponent = DefaultTrigger as any,
            ...props
        }: PropsWithChildren<SelectProps>,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        return (
            <SelectPrimitive.Root {...props} onValueChange={props.onChange}>
                <TriggerComponent
                    className={className}
                    disabled={disabled}
                    id={id}
                    placeholder={placeholder}
                    ref={ref}
                />
                <SelectPrimitive.Portal>
                    <SelectPrimitive.Content className={styles.content}>
                        <SelectPrimitive.ScrollUpButton>
                            <ChevronUpIcon />
                        </SelectPrimitive.ScrollUpButton>
                        <SelectPrimitive.Viewport>
                            {children}
                        </SelectPrimitive.Viewport>
                        <SelectPrimitive.ScrollDownButton>
                            <ChevronDownIcon />
                        </SelectPrimitive.ScrollDownButton>
                    </SelectPrimitive.Content>
                </SelectPrimitive.Portal>
            </SelectPrimitive.Root>
        );
    }
);

SelectInput.displayName = "SelectInput";

export default SelectInput;

export const SelectItem = forwardRef(
    (
        props: PropsWithChildren<SelectPrimitive.SelectItemProps>,
        ref: ForwardedRef<HTMLDivElement>
    ) => (
        <SelectPrimitive.Item
            ref={ref}
            {...props}
            className={classnames(styles.item, props.className)}
        >
            <SelectPrimitive.ItemText>
                {props.children}
            </SelectPrimitive.ItemText>
            <SelectPrimitive.ItemIndicator
                className={styles.active_indicator}
            />
        </SelectPrimitive.Item>
    )
);
SelectItem.displayName = "SelectItem";

export const SelectGroup = forwardRef(
    (
        props: SelectPrimitive.SelectGroupProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => <SelectPrimitive.Group {...props} ref={ref} className={styles.group} />
);
SelectGroup.displayName = "SelectGroup";
export const SelectLabel = forwardRef(
    (
        props: SelectPrimitive.SelectLabelProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => <SelectPrimitive.Label {...props} ref={ref} className={styles.label} />
);
SelectLabel.displayName = "SelectLabel";
export const SelectSeparator = forwardRef(
    (
        props: SelectPrimitive.SelectSeparatorProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => (
        <SelectPrimitive.Separator
            {...props}
            ref={ref}
            className={styles.separator}
        />
    )
);
SelectSeparator.displayName = "SelectSeparator";
