import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
    Node,
    Edge,
    ReactFlow,
    Background as RFBackground,
    BackgroundVariant as RFBackgroundVariant,
    useEdgesState,
    useNodesState,
    useReactFlow,
    Controls,
    useStore,
} from "reactflow";
import "reactflow/dist/style.css";

import styles from "./DAG.module.scss";

import DAGProvider from "./DAGProvider";
import { getTreeLayout } from "./tree";
import EntityNode from "./EntityNode";
import DAGEdge from "./DAGEdge";
import { LayoutNodesProps } from "./types";
import { Loader } from "../graph/Loader";

const elkOptions = {
    "elk.alignment": "CENTER",
    "elk.algorithm": "layered",
    "elk.layered.spacing.nodeNodeBetweenLayers": "100",
    "elk.spacing.nodeNode": "48",
    "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
    "elk.hierarchyHandling": "INCLUDE_CHILDREN",
    "elk.layered.nodePlacement.strategy": "LINEAR_SEGMENTS",
    "elk.partitioning.activate": "true",
    "elk.edgeRouting": "ORTHOGONAL",
    "elk.spacing.edgeEdge": "20",
};

const proOptions = { hideAttribution: true };

const NODE_TYPES = {
    dataset: EntityNode,
    featureset: EntityNode,
    source: EntityNode,
};

const EDGE_TYPES = {
    custom: DAGEdge,
};

const selectNodesSelector = (state: any) => state.addSelectedNodes;

// Make sure the node/edges props passed here to DAG
// are memoized/static values to avoid rendering/perf issues
export const DAG = ({
    nodes: initialNodes,
    edges: initialEdges,
    focusedNodes,
}: {
    nodes: Node[];
    edges: Edge[];
    focusedNodes?: { id: string }[];
}) => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const addSelectedNodes = useStore(selectNodesSelector);
    const [edges, ,] = useEdgesState(initialEdges);
    const [isLoaded, setLoaded] = useState(false);
    const { fitView } = useReactFlow();

    const [stale, setStale] = useState(false);

    const selectedNode: Node | undefined = useMemo(
        () => nodes.find(({ selected }) => selected),
        [nodes]
    );

    const layoutNodes = ({
        direction,
        useInitialNodes = false,
        newNodes = nodes,
    }: LayoutNodesProps) => {
        const opts = {
            "elk.direction": direction,
            ...elkOptions,
        };
        const n = useInitialNodes ? initialNodes : newNodes;
        const e = useInitialNodes ? initialEdges : edges;

        getTreeLayout(n, e, opts)
            .then(({ nodes: layoutedNodes }) => {
                // We only need to setNodes here, the edges will automatically connect the nodes and don't need to be updated,
                // although we still pass them to getTreeLayout above so that Elk can do it's thing and position the nodes properly.
                setNodes(layoutedNodes as Node[]);
                setLoaded(true);
                if (useInitialNodes) {
                    setStale(true);
                }
            })
            .catch(console.error);
    };

    useLayoutEffect(() => {
        layoutNodes({ direction: "RIGHT", useInitialNodes: true });
        // We specifically only want this to run on fresh mount, so we leave layoutNodes out of the hook dep array
    }, []);

    useEffect(() => {
        // We need to pass the nodes directly related to the "focus" entity for the current page (i.e. the Dataset or Feature whose detail page we're looking at)
        // to fitView so that they are focused to begin with, but the user can still zoom/pan to see the entire DAG.
        if (stale || isLoaded) {
            setStale(false);
            if (focusedNodes) {
                fitView({ nodes: focusedNodes, maxZoom: 0.75 });
                addSelectedNodes([focusedNodes[0].id]);
            }
        }
    }, [stale, isLoaded]);

    const onInit = () => {
        if (focusedNodes) {
            fitView({ nodes: focusedNodes, maxZoom: 0.75 });
            addSelectedNodes([focusedNodes[0].id]);
        }
    };

    return (
        <div className={styles.root}>
            {isLoaded ? (
                <DAGProvider
                    selectedNode={selectedNode}
                    setLayoutNodes={layoutNodes}
                >
                    <ReactFlow
                        fitView
                        nodes={nodes}
                        edges={edges}
                        disableKeyboardA11y
                        onNodesChange={onNodesChange}
                        // onNodeClick={(_, node) => noop}
                        nodeTypes={NODE_TYPES}
                        edgeTypes={EDGE_TYPES}
                        edgesFocusable={true}
                        nodesDraggable={false}
                        nodesConnectable={false}
                        draggable={false}
                        proOptions={proOptions}
                        minZoom={0.2}
                        onInit={onInit}
                    >
                        <RFBackground variant={RFBackgroundVariant.Dots} />
                        <Controls showInteractive={false} />
                    </ReactFlow>
                </DAGProvider>
            ) : (
                <Loader height={500} />
            )}
        </div>
    );
};
