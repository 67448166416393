import styles from "./styles/TagFilter.module.scss";
import FeaturesetIcon from "icons/featureset.svg";

function FeaturesetFilter({ name }: { name: string }): JSX.Element {
    return (
        <div className={styles.filter}>
            <FeaturesetIcon />
            <span>{name}</span>
        </div>
    );
}

export default FeaturesetFilter;
