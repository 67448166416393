// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WJ2d515zvXdmgyMnU78A{margin-top:1.5rem}", "",{"version":3,"sources":["webpack://./src/console/features/settings/TokenSettings/styles/TokenDeleteDialog.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA","sourcesContent":[".delete_dialog_form {\n    margin-top: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete_dialog_form": "WJ2d515zvXdmgyMnU78A"
};
export default ___CSS_LOADER_EXPORT___;
