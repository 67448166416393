import InspectIcon from "icons/data-search.svg";
import { useNavigate, useParams } from "react-router-dom";
import { branchedLink } from "../../../utils/utils";
import Tooltip from "../../Tooltip";
import IconButton from "../../IconButton";

function ToInspect({ datasetName }: { datasetName: string }) {
    const { branchName } = useParams();
    const path = branchedLink(branchName, `data?dataset=${datasetName}`);
    const navigate = useNavigate();
    return (
        <Tooltip
            content="Inspect Data"
            onPointerDownOutside={() => navigate(path)}
        >
            <IconButton
                size="small"
                icon={<InspectIcon />}
                data-test={`toinpect-test`}
            />
        </Tooltip>
    );
}

export default ToInspect;
