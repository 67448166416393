import { useMemo, useState } from "react";
import useSWR from "swr";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { Token } from "shared/models";
import { axiosFetcher } from "shared/utils//utils";
import { useCurrentUser, useHasPermission } from "../../../context/CurrentUser";

import { AlertDialog } from "shared/components/AlertDialog";
import ReactTable from "shared/components/ReactTable";
import IconButton from "shared/components/IconButton";
import UserDisplay from "shared/components/UserDisplay";
import PageTitle from "../shared/PageTitle";
import TokenCreateForm from "./TokenCreateForm";
import TokenDeleteDialog from "./TokenDeleteDialog";

import KeyIcon from "icons/key.svg";
import DeleteIcon from "icons/delete.svg";

import styles from "./styles/TokenSettings.module.scss";
import EmptyState from "shared/components/EmptyState/EmptyState";
import { TOKEN_NAV } from "shared/constants/navigation";

const columnHelper = createColumnHelper<Token>();

const DEFAULT_SORT = [
    {
        id: "created_at",
        desc: true,
    },
];

function UserTokenSettings() {
    const { user } = useCurrentUser();
    const hasPermission = useHasPermission();

    const [sorting, _setSorting] = useState<SortingState>(DEFAULT_SORT);
    const [deletingToken, setDeletingToken] = useState<string | undefined>();

    const tableState = useMemo(() => ({ sorting }), [sorting]);

    const { data, error, mutate, isLoading } = useSWR<Token[]>(
        user
            ? [
                  "get",
                  hasPermission?.tokens
                      ? `/api/v1/auth/token`
                      : `/api/v1/auth/token/${encodeURIComponent(user.email)}`,
              ]
            : null,
        axiosFetcher
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor(({ token_name }) => token_name, {
                header: "Name",
                size: 240,
                cell: ({ row, renderValue }) => (
                    <div className={styles.token_name}>
                        {renderValue()}{" "}
                        <span>••••{row.original.token_suffix}</span>
                    </div>
                ),
            }),
            columnHelper.accessor(({ owner }) => owner, {
                header: "Created by",
                cell: ({ renderValue }) => (
                    <UserDisplay name={renderValue() || ""} size="small" />
                ),
            }),
            columnHelper.accessor(
                ({ created_timestamp }) => created_timestamp,
                {
                    id: "created_at",
                    header: "Created at",
                    size: 160,
                    cell: ({ renderValue }) => {
                        return formatDistanceToNow(
                            parseISO(`${renderValue() || ""}Z`),
                            {
                                addSuffix: true,
                            }
                        );
                    },
                }
            ),
            {
                id: "actions",
                header: () => null,
                cell: ({ row }: { row: { original: Token } }) => (
                    <IconButton
                        className={styles.delete_token_btn}
                        icon={<DeleteIcon />}
                        size="small"
                        onClick={() =>
                            setDeletingToken(row.original.token_name)
                        }
                    />
                ),
                size: 40,
            },
        ],
        []
    );

    return (
        <div className={styles.root}>
            <PageTitle
                title={TOKEN_NAV.title}
                description="Create and manage access tokens for your account."
                titleIcon={TOKEN_NAV.icon}
            />

            <TokenCreateForm onSubmit={mutate} />

            <ReactTable
                className={styles.table}
                data={data || []}
                columns={columns}
                state={tableState}
                renderEmptyState={() => (
                    <EmptyState
                        icon={<KeyIcon />}
                        loading={isLoading}
                        text={
                            error
                                ? "Something went wrong. Please refresh and try again."
                                : "You haven't created any tokens yet."
                        }
                    />
                )}
            />

            <AlertDialog
                open={deletingToken !== undefined}
                onOpenChange={(open) =>
                    setDeletingToken((prev) => (!open ? undefined : prev))
                }
            >
                <TokenDeleteDialog
                    tokenName={deletingToken}
                    onSuccess={() => {
                        setDeletingToken(undefined);
                        mutate();
                    }}
                />
            </AlertDialog>
        </div>
    );
}

export default UserTokenSettings;
