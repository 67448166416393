import styles from "./styles/ThirdPartyAuth.module.scss";

interface Props {
    icon: JSX.Element;
    text: string;
    href: string;
}

function ThirdPartyAuth({ icon, text, href }: Props): JSX.Element {
    return (
        <div className={styles.container}>
            <form action={href} method="post">
                <button type="submit" color="primary" className={styles.button}>
                    {icon}
                    <span>{text}</span>
                </button>
            </form>
        </div>
    );
}

export default ThirdPartyAuth;
