import { useCallback, useState } from "react";
import Button from "shared/components/Button";
import {
    DialogActions,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "shared/components/Dialog";

import styles from "./styles/TokenConfirmDialog.module.scss";
import Tooltip from "shared/components/Tooltip";

const TokenConfirmDialog = ({ token }: { token?: string }) => {
    const [tooltipContent, setTooltipContent] = useState("Click to copy");

    const handleCopyClick = useCallback(
        (e: CustomEvent<{ originalEvent: PointerEvent }>) => {
            e.preventDefault();

            if (token) {
                navigator.clipboard.writeText(token);
            }

            // Handle tooltip interaction
            setTooltipContent("Copied!");
            new Promise((res) => setTimeout(res, 2000)).then(() =>
                setTooltipContent("Click to copy")
            );
        },
        [token]
    );

    return (
        <DialogContent>
            <DialogTitle>Token Created</DialogTitle>
            <DialogDescription>
                Your token has been created. Please save the key in a secure
                place, you won&apos;t be able to see it again after this modal
                closes
            </DialogDescription>
            <Tooltip
                content={tooltipContent}
                onPointerDownOutside={handleCopyClick}
            >
                <code
                    onMouseDown={(e) => e.preventDefault()}
                    className={styles.token_preview}
                >
                    {token || null}
                </code>
            </Tooltip>
            <DialogActions>
                <DialogClose>
                    <Button color="primary">Done</Button>
                </DialogClose>
            </DialogActions>
        </DialogContent>
    );
};

export default TokenConfirmDialog;
