import { PropsWithChildren } from "react";
import styles from "./SettingsCard.module.scss";

type SettingsCardProps = {
    Footer?: JSX.Element;
};

function SettingsCard({
    children,
    Footer,
}: PropsWithChildren<SettingsCardProps>) {
    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>{children}</div>
            {Footer ? <div className={styles.footer}>{Footer}</div> : null}
        </div>
    );
}

export default SettingsCard;
