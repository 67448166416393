import { ForwardedRef, PropsWithChildren, forwardRef } from "react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";

import styles from "./styles/Dialog.module.scss";

export const AlertDialogContent = forwardRef(
    (
        { children, ...props }: PropsWithChildren,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <AlertDialogPrimitive.Portal>
                <AlertDialogPrimitive.Overlay className={styles.overlay} />
                <AlertDialogPrimitive.Content
                    {...props}
                    className={styles.content}
                    ref={ref}
                >
                    {children}
                </AlertDialogPrimitive.Content>
            </AlertDialogPrimitive.Portal>
        );
    }
);
AlertDialogContent.displayName = "AlertDialogContent";

export const AlertDialog = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = ({ children }: PropsWithChildren) => (
    <AlertDialogPrimitive.Trigger asChild>
        {children}
    </AlertDialogPrimitive.Trigger>
);

export const AlertDialogTitle = ({ children }: PropsWithChildren) => (
    <AlertDialogPrimitive.Title className={styles.title}>
        {children}
    </AlertDialogPrimitive.Title>
);

export const AlertDialogDescription = ({ children }: PropsWithChildren) => (
    <AlertDialogPrimitive.Description className={styles.description}>
        {children}
    </AlertDialogPrimitive.Description>
);

export const AlertDialogActions = ({ children }: PropsWithChildren) => (
    <div className={styles.actions}>{children}</div>
);

export const AlertDialogAction = ({ children }: PropsWithChildren) => (
    <AlertDialogPrimitive.Action asChild>
        {children}
    </AlertDialogPrimitive.Action>
);

export const AlertDialogCancel = ({ children }: PropsWithChildren) => (
    <AlertDialogPrimitive.Cancel asChild>
        {children}
    </AlertDialogPrimitive.Cancel>
);
