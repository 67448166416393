import { useEffect, useRef, useState } from "react";
import PlusIcon from "icons/plus.svg";
import {
    Config,
    Filter,
    FilterConfigKey,
    FilterValue,
    defaultVerb,
} from "./filters";
import FilterValueSelect from "./FilterValueSelect";
import styles from "./styles/AddFilter.module.scss";

interface AddFilterProps {
    config: Config;
    onAdd: (filter: Filter) => void;
}

enum AddFilterStatus {
    None,
    SelectProperty,
    SelectValue,
}

function AddFilter({ config, onAdd }: AddFilterProps): JSX.Element {
    const [status, setStatus] = useState(AddFilterStatus.None);
    const [mousedownStatus, setMousedownStatus] = useState<AddFilterStatus>(); // this is a hack to prevent clicking Add causing existing menu to close then open again

    const [selectedFilterKey, setSelectedFilterKey] =
        useState<keyof Config["filterConfigs"]>();
    const [selectedFilterValue, setSelectedFilterValue] =
        useState<FilterValue>();

    const onSelectKey = (key: FilterConfigKey) => {
        setSelectedFilterKey(key);
        setStatus(AddFilterStatus.SelectValue);
    };
    const onSelectValue = (value: FilterValue) => {
        setSelectedFilterValue(value);
    };
    const onSubmit = () => {
        if (selectedFilterKey && selectedFilterValue) {
            onAdd({
                key: selectedFilterKey,
                verb: defaultVerb(
                    config.filterConfigs[selectedFilterKey],
                    selectedFilterValue
                ),
                value: selectedFilterValue,
            });
        }
        setStatus(AddFilterStatus.None);
        setSelectedFilterKey(undefined);
        setSelectedFilterValue(undefined);
    };

    return (
        <div className={styles.addFilterContainer}>
            <div
                className={styles.addFilter}
                onClick={() => {
                    if (
                        status === AddFilterStatus.None &&
                        mousedownStatus == AddFilterStatus.None
                    ) {
                        setStatus(AddFilterStatus.SelectProperty);
                    }
                }}
                onMouseDown={() => {
                    setMousedownStatus(status);
                }}
            >
                <PlusIcon width={12} height={12} viewBox="0 0 24 24" />
                <span>Add Filter</span>
            </div>
            {status === AddFilterStatus.SelectProperty ? (
                <FilterPropertySelect
                    config={config}
                    onSelect={onSelectKey}
                    onCancel={() => {
                        setStatus(AddFilterStatus.None);
                    }}
                />
            ) : null}
            {status === AddFilterStatus.SelectValue && selectedFilterKey ? (
                <FilterValueSelect
                    filterConfig={config.filterConfigs[selectedFilterKey]}
                    onSelect={onSelectValue}
                    onSubmit={onSubmit}
                />
            ) : null}
        </div>
    );
}

interface FilterPropertySelectProps {
    config: Config;
    onSelect: (key: FilterConfigKey) => void;
    onCancel: () => void;
}

function FilterPropertySelect({
    config,
    onSelect,
    onCancel,
}: FilterPropertySelectProps): JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onClick = (e: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(e.target as Node)
            ) {
                onCancel();
            }
        };

        document.addEventListener("mousedown", onClick);
        return () => {
            document.removeEventListener("mousedown", onClick);
        };
    }, [containerRef]);

    return (
        <div className={styles.filterPropertySelect} ref={containerRef}>
            <div className={styles.title}>Filter Properties</div>
            {config.filterOrder.map((key) => {
                const filterConfig = config.filterConfigs[key];
                return (
                    <div
                        key={key}
                        className={styles.item}
                        onClick={() => {
                            onSelect(key);
                        }}
                    >
                        {filterConfig.icon && <filterConfig.icon />}
                        <span>{filterConfig.propertyName}</span>
                    </div>
                );
            })}
        </div>
    );
}

export default AddFilter;
