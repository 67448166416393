import FeaturesetIcon from "icons/featureset.svg";
import styles from "../styles/EntityPage.module.scss";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import { useUpdateEntityParams } from "../../../../context/CurrentView";

const Featureset = ({ name }: { name: string }) => {
    const { updateEntityParams: navigate } = useUpdateEntityParams();
    return (
        <div className={styles.metadataItem}>
            <div className={styles.metadataItemKey}>
                <FeaturesetIcon />
                <span>Featureset</span>
            </div>
            <div className={styles.metadataItemValue}>
                <div
                    onClick={() => navigate("featureset", name)}
                    className={styles.metadataLink}
                >
                    <div className={styles.metadataString}>{name}</div>
                    <ArrowNarrowUpRightIcon />
                </div>
            </div>
        </div>
    );
};

export default Featureset;
