import { ForwardedRef, forwardRef, useMemo } from "react";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import Select, {
    GroupBase,
    MultiValueProps,
    NoticeProps,
    OptionProps,
} from "react-select";
import SelectEl, {
    Props as SelectProps,
} from "react-select/dist/declarations/src/Select";
import classnames from "classnames";

import MenuItem from "./MenuItem";

import styles from "./styles/MultiSelectInput.module.scss";
import Tooltip from "./Tooltip";

const classNames = {
    container: () => styles.container,
    control: () => styles.control,
    menu: () => styles.menu,
    valueContainer: () => styles.multi_value_container,
    placeholder: () => styles.placeholder,
};

export const MultiSelectInput = forwardRef(function MultiSelectInput<T>(
    props: CreatableProps<T, true, GroupBase<T>>,
    ref: ForwardedRef<SelectEl<T, true, GroupBase<T>>> | undefined
) {
    const components: CreatableProps<T, true, GroupBase<T>>["components"] =
        useMemo(
            () => ({
                NoOptionsMessage: ({
                    children,
                    innerProps,
                }: NoticeProps<T, true, GroupBase<T>>) => (
                    <div {...innerProps} className={styles.empty_state}>
                        {children}
                    </div>
                ),
                Option: ({
                    children,
                    isFocused,
                    innerProps,
                    innerRef,
                }: OptionProps<T, true, GroupBase<T>>) => {
                    return (
                        <MenuItem
                            {...innerProps}
                            className={classnames({
                                [styles.focused_item]: isFocused,
                            })}
                            ref={innerRef}
                        >
                            {children}
                        </MenuItem>
                    );
                },
                IndicatorsContainer: () => null,
                MultiValue: (props: MultiValueProps<T, true>) => {
                    return (
                        <Tooltip content={`Remove "${props.children}"`}>
                            <div
                                onClick={props.removeProps.onClick}
                                className={classnames(styles.multi_value, {
                                    [styles.focused]: props.isFocused,
                                })}
                            >
                                {props.children}
                            </div>
                        </Tooltip>
                    );
                },
            }),
            []
        );

    return (
        <CreatableSelect<T, true>
            ref={ref}
            {...props}
            unstyled
            isMulti
            components={components}
            classNames={classNames}
        />
    );
}) as <T>(
    props: CreatableProps<T, true, GroupBase<T>> & {
        ref?: ForwardedRef<SelectEl<T, true, GroupBase<T>>>;
    }
) => JSX.Element;

export const MultiSelect = forwardRef(function MultiSelect<T>(
    props: Partial<SelectProps<T, true, GroupBase<T>>>,
    ref: ForwardedRef<SelectEl<T, true, GroupBase<T>>> | undefined
) {
    const components: SelectProps<T, true, GroupBase<T>>["components"] =
        useMemo(
            () => ({
                NoOptionsMessage: ({
                    children,
                    innerProps,
                }: NoticeProps<T, true, GroupBase<T>>) => (
                    <div {...innerProps} className={styles.empty_state}>
                        {children}
                    </div>
                ),
                Option: ({
                    children,
                    isFocused,
                    innerProps,
                    innerRef,
                }: OptionProps<T, true, GroupBase<T>>) => {
                    return (
                        <MenuItem
                            {...innerProps}
                            className={classnames({
                                [styles.focused_item]: isFocused,
                            })}
                            ref={innerRef}
                        >
                            {children}
                        </MenuItem>
                    );
                },
                IndicatorsContainer: () => null,
                MultiValue: (props: MultiValueProps<T, true>) => (
                    <Tooltip content={`Remove "${props.children}"`}>
                        <div
                            onClick={props.removeProps.onClick}
                            className={styles.multi_value}
                        >
                            {props.children}
                        </div>
                    </Tooltip>
                ),
            }),
            []
        );

    return (
        <Select<T, true>
            ref={ref}
            {...props}
            unstyled
            isMulti
            components={components}
            classNames={classNames}
        />
    );
}) as <T>(
    props: Partial<SelectProps<T, true, GroupBase<T>>> & {
        ref?: ForwardedRef<SelectEl<T, true, GroupBase<T>>>;
    }
) => JSX.Element;
