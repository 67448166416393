// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cSdl6zBvukS5YhBkRuKx{display:flex;align-items:center;gap:16px}.tJBHpWT2mtZHS1jJvLEw{font-size:14px;line-height:16px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/console/shared/components/date-picker/styles/DateRangeControl.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,QAAA,CAGJ,sBACI,cAAA,CACA,gBAAA,CACA,eAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n}\n\n.to {\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cSdl6zBvukS5YhBkRuKx",
	"to": "tJBHpWT2mtZHS1jJvLEw"
};
export default ___CSS_LOADER_EXPORT___;
