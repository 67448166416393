import { useContext } from "react";
import styles from "./ConsolePage.module.scss";
import { InspectorContext } from "../../context/InspectorProvider";
import SidebarIcon from "icons/sidebar.svg";
import Tooltip from "shared/components/Tooltip";
import IconButton from "shared/components/IconButton";
import classNames from "classnames";

type Props = {
    header: {
        icon?: React.ReactNode;
        title: string;
        actions?: React.ReactNode[];
    };
    content: React.ReactNode;
    subheader?: React.ReactNode;
    customClassNames?: {
        container?: string;
        content?: string;
    };
};

function ConsolePage({
    header,
    content,
    subheader,
    customClassNames,
}: Props): JSX.Element {
    const { toggleSidebar } = useContext(InspectorContext);

    return (
        <div className={styles.gridContainer}>
            <div
                className={classNames(
                    styles.flexContainer,
                    customClassNames?.container
                )}
                data-test={"console-header-test"}
            >
                <div className={styles.headerContainer}>
                    <div className={styles.titleContainer}>
                        <h2
                            className={styles.title}
                            data-test={"console-title-test"}
                        >
                            {header.icon}
                            {header.title}
                        </h2>
                        <div
                            className={styles.actions}
                            data-test={"console-actions-test"}
                        >
                            {header.actions?.map((action) => action)}
                            <div className={styles.sidebarToggler}>
                                <Tooltip content="Toggle Inspector Panel">
                                    <IconButton
                                        size="small"
                                        iconOnly
                                        onClick={toggleSidebar}
                                        icon={<SidebarIcon />}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    {subheader && (
                        <div className={styles.subtitleContainer}>
                            {subheader}
                        </div>
                    )}
                </div>
                <div
                    className={classNames(
                        styles.content,
                        customClassNames?.content
                    )}
                    data-test={"console-content-test"}
                >
                    {content}
                </div>
            </div>
        </div>
    );
}

export default ConsolePage;
