import styles from "./styles/DatasetsPage.module.scss";
import DatasetIcon from "icons/dataset.svg";
import { stringCompare, getSourceTypeIcon } from "shared/utils/utils";
import OwnerIcon from "icons/owner.svg";
import { useContext } from "react";
import Table, { Column } from "shared/components/Table";
import {
    CurrentViewContext,
    useUpdateEntityParams,
} from "../../context/CurrentView";
import Tag from "shared/components/Tag";
import { DOC_LINKS } from "shared/constants/docs";
import MetaField from "shared/components/MetaField";
import { Dataset } from "shared/utils/types";
import Tooltip from "shared/components/Tooltip";
import {
    DirectTags,
    InheritedTags,
} from "shared/components/expandableTags/TagRenderer";

function DatasetsList({
    allDatasets,
    filteredDatasets,
}: {
    allDatasets: Dataset[];
    filteredDatasets: Dataset[];
}): JSX.Element {
    const { sourceInfo } = useContext(CurrentViewContext);
    const { updateEntityParams: navigate } = useUpdateEntityParams();

    const getSourcedDerivedFromContent = (sources: string[], unit: string) => {
        if (sources.length > 1) {
            return `${sources.length} ${unit}`;
        }
        return sources[0];
    };

    const columns: Column<Dataset>[] = [
        {
            header: "Name",
            renderFunc: (ds) => {
                const datasetSourceKeys = Object.keys(sourceInfo || {}).filter(
                    (key) =>
                        sourceInfo?.[key].datasets.find(
                            (dataset) => dataset.name === ds.name
                        )
                );
                const datasetSources = datasetSourceKeys?.map(
                    (dkey) => sourceInfo?.[dkey].metadata
                );
                const parentDatasets = ds.pipelines.flatMap(
                    (p) => p.input_dataset_names
                );
                const sourceNames = datasetSources.map(
                    (s) => `${s?.source_type}`
                );
                return (
                    <div>
                        <DatasetIcon />
                        {ds.name}
                        {ds.is_source_dataset ? (
                            <Tooltip
                                content={`Sourced from ${sourceNames.join(
                                    ", "
                                )}`}
                            >
                                <span className={styles.sourcedFrom}>
                                    Sourced From
                                    {datasetSources.map((source, index) => {
                                        const Icon = getSourceTypeIcon(
                                            source?.source_type || ""
                                        );
                                        return <Icon key={index} />;
                                    })}
                                    {getSourcedDerivedFromContent(
                                        sourceNames,
                                        "Sources"
                                    )}
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                content={`Derived from ${parentDatasets.join(
                                    ", "
                                )}`}
                            >
                                <span className={styles.sourcedFrom}>
                                    Derived From
                                    <DatasetIcon />
                                    {getSourcedDerivedFromContent(
                                        parentDatasets,
                                        "Datasets"
                                    )}
                                </span>
                            </Tooltip>
                        )}
                        <span className={styles.taggedName}>
                            <DirectTags tags={ds.tags} />
                        </span>
                    </div>
                );
            },
            sortFunc: (x, y) => stringCompare(x.name, y.name),
        },
        {
            header: "Owner",
            renderFunc: (ds) => (
                <MetaField icon={<OwnerIcon />} name={ds.owner} />
            ),
            sortFunc: (x, y) => stringCompare(x.owner, y.owner),
        },
    ];

    return (
        <>
            <Table
                className={styles.table}
                data={filteredDatasets}
                columns={columns}
                rowKeyFunc={(ds) => ds.name}
                onRowClick={(row) => {
                    navigate("dataset", row.name);
                }}
                dataUnit="Dataset"
                emptyText={
                    allDatasets.length > 0
                        ? "No datasets found"
                        : "No datasets created yet."
                }
                learnMore={
                    allDatasets.length > 0 ? undefined : DOC_LINKS.dataset
                }
            />
        </>
    );
}

export default DatasetsList;
