import {
    Config,
    Filter,
    hasManyPred,
    hasOnePred,
} from "shared/components/search/filters";
import {
    Dataset,
    Feature,
    Featureset,
    SourceInfo,
} from "../../shared/utils/types";
import UserCircleIcon from "icons/user-circle.svg";
import SourceIcon from "icons/source.svg";
import TagIcon from "icons/tag.svg";
import DatasetIcon from "icons/dataset.svg";
import OwnerFilter from "shared/components/search/OwnerFilter";
import SourcesFilter from "shared/components/search/SourcesFilter";
import TagFilter from "shared/components/search/TagFilter";
import FeaturesetIcon from "icons/featureset.svg";
import FeaturesetFilter from "shared/components/search/FeaturesetFilter";

export enum EntityType {
    DATASET = "datasets",
    FEATURESET = "featuresets",
    FEATURE = "features",
}

enum DatasetType {
    IS_SOURCED = "Is Sourced",
    IS_DERIVED = "Is Derived",
}

export function buildFeatureSearchConfig(
    features: Feature[],
    sourceInfo: SourceInfo
): Config {
    const allSources = Object.keys(sourceInfo);
    const allSourcesSet = allSources.filter(
        (source) => sourceInfo[source].features.length > 0
    );
    const sources = allSourcesSet.map((source) => ({
        ...sourceInfo[source].metadata,
    }));
    const sourceConfig = {
        icon: SourceIcon,
        propertyName: "Upstream Sources",
        kind: {
            kind: "multi-select" as const,
            options: sources.map((o) => ({
                key: o.name as string,
                name: o.name as string,
                value: o.name as string,
                metadata: { ...o },
                metadataSearch: true,
            })),
            valueCategory: "source",
            component: SourcesFilter,
            relationship: "has-one" as const,
        },
    };

    const allOwnersSet = new Set(features.map((d) => d.owner));
    const owners = Array.from(allOwnersSet.keys()).sort();
    const ownerConfig = {
        icon: UserCircleIcon,
        propertyName: "Owner",
        kind: {
            kind: "multi-select" as const,
            options: owners.map((o = "") => ({ key: o, name: o, value: o })),
            valueCategory: "user",
            component: OwnerFilter,
            relationship: "has-one" as const,
        },
    };
    const allTagsSet = new Set(features.flatMap((f) => f.tags));
    const tags = Array.from(allTagsSet.keys()).sort();
    const tagConfig = {
        icon: TagIcon,
        propertyName: "Tag",
        kind: {
            kind: "multi-select" as const,
            options: tags.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "tag",
            component: TagFilter,
            relationship: "has-many" as const,
        },
    };

    const allFeaturesetSet = new Set(
        features.map((f) => f.featureset as string)
    );
    const featuresets = Array.from(allFeaturesetSet.keys()).sort();
    const featuresetConfig = {
        icon: FeaturesetIcon,
        propertyName: "Featureset",
        kind: {
            kind: "multi-select" as const,
            options: featuresets.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "featureset",
            component: FeaturesetFilter,
            relationship: "has-one" as const,
        },
    };

    const filterOrder = ["owner"];
    if (tags.length > 0) {
        filterOrder.push("tag");
    }

    if (featuresets.length > 0) {
        filterOrder.push("featureset");
    }

    if (sources.length > 0) {
        filterOrder.push("source");
    }

    return {
        filterOrder,
        filterConfigs: {
            owner: ownerConfig,
            tag: tagConfig,
            featureset: featuresetConfig,
            source: sourceConfig,
        },
    };
}

export function buildFeaturesetSearchConfig(featuresets: Featureset[]): Config {
    const allOwnersSet = new Set(featuresets.map((d) => d.owner));
    const owners = Array.from(allOwnersSet.keys()).sort();
    const ownerConfig = {
        icon: UserCircleIcon,
        propertyName: "Owner",
        kind: {
            kind: "multi-select" as const,
            options: owners.map((o) => ({ key: o, name: o, value: o })),
            valueCategory: "user",
            component: OwnerFilter,
            relationship: "has-one" as const,
        },
    };
    const allTagsSet = new Set(featuresets.flatMap((f) => f.tags));
    const tags = Array.from(allTagsSet.keys()).sort();
    const tagConfig = {
        icon: TagIcon,
        propertyName: "Tag",
        kind: {
            kind: "multi-select" as const,
            options: tags.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "tag",
            component: TagFilter,
            relationship: "has-many" as const,
        },
    };
    const filterOrder = ["owner"];
    if (tags.length > 0) {
        filterOrder.push("tag");
    }

    return {
        filterOrder,
        filterConfigs: {
            owner: ownerConfig,
            tag: tagConfig,
        },
    };
}

export function buildDatasetSearchConfig(
    datasets: Dataset[],
    sourceInfo: SourceInfo
): Config {
    const allSources = Object.keys(sourceInfo);
    const allSourcesSet = allSources.filter(
        (source) => sourceInfo[source].datasets.length > 0
    );
    const sources = allSourcesSet.map((source) => ({
        ...sourceInfo[source].metadata,
    }));
    const sourceConfig = {
        icon: SourceIcon,
        propertyName: "Upstream Sources",
        kind: {
            kind: "multi-select" as const,
            options: sources.map((o) => ({
                key: o.name as string,
                name: o.name as string,
                value: o.name as string,
                metadata: { ...o },
                metadataSearch: true,
            })),
            valueCategory: "source",
            component: SourcesFilter,
            relationship: "has-one" as const,
        },
    };

    const allOwnersSet = new Set(datasets.map((d) => d.owner));
    const owners = Array.from(allOwnersSet.keys()).sort();
    const ownerConfig = {
        icon: UserCircleIcon,
        propertyName: "Owner",
        kind: {
            kind: "multi-select" as const,
            options: owners.map((o) => ({ key: o, name: o, value: o })),
            valueCategory: "user",
            component: OwnerFilter,
            relationship: "has-one" as const,
        },
    };

    const allTagsSet = new Set(datasets.flatMap((d) => d.tags));
    const tags = Array.from(allTagsSet.keys()).sort();
    const tagConfig = {
        icon: TagIcon,
        propertyName: "Tag",
        kind: {
            kind: "multi-select" as const,
            options: tags.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "tag",
            component: TagFilter,
            relationship: "has-many" as const,
        },
    };

    const types = [DatasetType.IS_SOURCED, DatasetType.IS_DERIVED];
    const typeConfig = {
        icon: DatasetIcon,
        propertyName: "Type",
        kind: {
            kind: "multi-select" as const,
            options: types.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "type",
            component: SourcesFilter,
            relationship: "has-one" as const,
        },
    };

    const filterOrder = ["owner"];
    if (tags.length > 0) {
        filterOrder.push("tag");
    }
    if (sources.length > 0) {
        filterOrder.push("source");
    }
    filterOrder.push("type");

    return {
        filterOrder,
        filterConfigs: {
            owner: ownerConfig,
            tag: tagConfig,
            type: typeConfig,
            source: sourceConfig,
        },
    };
}

export function filterDatasets(
    datasets: Dataset[],
    searchText: string,
    filters: Filter[],
    sourceInfo: SourceInfo
): Dataset[] {
    const text = searchText.toLowerCase();
    let filtered = [...datasets].filter(
        (ds) => text == "" || ds.name.toLowerCase().includes(text)
    );
    filters.forEach((f) => {
        if (f.key == "owner") {
            filtered = filtered.filter((d) => hasOnePred(f, d.owner));
        } else if (f.key == "tag") {
            filtered = filtered.filter((d) => hasManyPred(f, d.tags));
        } else if (f.key == "type") {
            filtered = filtered.filter((d) => {
                let includeDataset = false;
                if (f.value.includes(DatasetType.IS_DERIVED)) {
                    includeDataset = includeDataset || d.pipelines.length > 0;
                }
                if (f.value.includes(DatasetType.IS_SOURCED)) {
                    includeDataset = includeDataset || d.pipelines.length === 0;
                }
                return includeDataset;
            });
        } else if (f.key == "source") {
            filtered = filtered.filter((d) => {
                const selectedSources = f.value;
                let sourceContainsDataset = false;
                for (const s of selectedSources) {
                    const sourceDetails = sourceInfo[s];
                    sourceContainsDataset =
                        sourceContainsDataset ||
                        sourceDetails.datasets.some(
                            (da: Dataset) => da.name === d.name
                        );
                }
                return sourceContainsDataset;
            });
        }
    });
    return filtered;
}

export function filterFeatures(
    features: Feature[],
    searchText: string,
    filters: Filter[],
    sourceInfo: SourceInfo
): Feature[] {
    const text = searchText.toLocaleLowerCase();
    let filtered = features.filter(
        (fs) =>
            searchText == "" ||
            fs.name.toLocaleLowerCase().includes(text) ||
            fs.description.toLocaleLowerCase().includes(text)
    );
    filters.forEach((f) => {
        if (f.key == "owner") {
            filtered = filtered.filter((d) => hasOnePred(f, d.owner || ""));
        } else if (f.key == "tag") {
            filtered = filtered.filter((d) => hasManyPred(f, d.tags));
        } else if (f.key == "featureset") {
            filtered = filtered.filter((d) =>
                hasOnePred(f, d.featureset as string)
            );
        } else if (f.key == "source") {
            filtered = filtered.filter((d) => {
                const selectedSources = f.value;
                let sourceContainsFeature = false;
                for (const s of selectedSources) {
                    const sourceDetails = sourceInfo[s];
                    sourceContainsFeature =
                        sourceContainsFeature ||
                        sourceDetails.features.some(
                            (fe: Feature) => fe.name === d.name
                        );
                }
                return sourceContainsFeature;
            });
        }
    });
    return filtered;
}

export function filterFeaturesets(
    featuresets: Featureset[],
    searchText: string,
    filters: Filter[]
): Featureset[] {
    const text = searchText.toLocaleLowerCase();
    let filtered = featuresets.filter(
        (fs) => searchText == "" || fs.name.toLocaleLowerCase().includes(text)
    );
    filters.forEach((f) => {
        if (f.key == "owner") {
            filtered = filtered.filter((d) => hasOnePred(f, d.owner));
        } else if (f.key == "tag") {
            filtered = filtered.filter((d) => hasManyPred(f, d.tags));
        }
    });
    return filtered;
}

export const getSearchFilterEntity = (entityType: EntityType) => {
    return function (
        entities: Dataset[] | Featureset[] | Feature[],
        text: string,
        filters: Filter[],
        sourceInfo: SourceInfo
    ) {
        switch (entityType) {
            case EntityType.DATASET:
                return filterDatasets(
                    entities as Dataset[],
                    text,
                    filters,
                    sourceInfo
                );
            case EntityType.FEATURE:
                return filterFeatures(
                    entities as Feature[],
                    text,
                    filters,
                    sourceInfo
                );
            case EntityType.FEATURESET:
                return filterFeaturesets(
                    entities as Featureset[],
                    text,
                    filters
                );
            default:
                return {};
        }
    };
};

export const getSearchConfig = (entityType: EntityType) => {
    return function (
        entities: Dataset[] | Featureset[] | Feature[],
        sourceInfo: SourceInfo
    ) {
        switch (entityType) {
            case EntityType.DATASET:
                return buildDatasetSearchConfig(
                    entities as Dataset[],
                    sourceInfo as SourceInfo
                );
            case EntityType.FEATURESET:
                return buildFeaturesetSearchConfig(entities as Featureset[]);
            case EntityType.FEATURE:
            default:
                return buildFeatureSearchConfig(
                    entities as Feature[],
                    sourceInfo as SourceInfo
                );
        }
    };
};
