import { useCallback } from "react";

import CopyIcon from "icons/copy.svg";

import styles from "./styles/ClipboardPre.module.scss";
import Tooltip from "./Tooltip";

const ClipboardPre = ({
    children,
    onCopyClick,
    tooltip,
    variant = "default",
}: {
    children: string;
    onCopyClick?: () => void;
    tooltip?: string;
    variant?: "default" | "flat";
}) => {
    const handleCopyClick = useCallback(
        (e: CustomEvent<{ originalEvent: PointerEvent }>) => {
            e.preventDefault();

            if (onCopyClick) {
                onCopyClick();
            }
        },
        [children, onCopyClick]
    );

    return variant === "default" ? (
        <div className={styles.root}>
            <code
                onMouseDown={(e) => e.preventDefault()}
                className={styles.pre}
            >
                {children}
            </code>
            <Tooltip content={tooltip} onPointerDownOutside={handleCopyClick}>
                <div className={styles.icon}>
                    <CopyIcon />
                </div>
            </Tooltip>
        </div>
    ) : (
        <div className={styles.rootFlat}>
            <code
                onMouseDown={(e) => e.preventDefault()}
                className={styles.preFlat}
            >
                {children}
            </code>
            <Tooltip content={tooltip} onPointerDownOutside={handleCopyClick}>
                <div className={styles.iconFlat}>
                    <CopyIcon />
                </div>
            </Tooltip>
        </div>
    );
};

export default ClipboardPre;
