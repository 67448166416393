import styles from "./styles/TextDivider.module.scss";

interface Props {
    text: string;
}

function TextDivider({ text }: Props): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.line} />
            <span className={styles.text}>{text}</span>
            <div className={styles.line} />
        </div>
    );
}

export default TextDivider;
