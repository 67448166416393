// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gu7qQ2UHHC2B5j5aoDH4{border-top:1px solid rgba(0,0,0,.08);height:100%;width:100%;display:flex}", "",{"version":3,"sources":["webpack://./src/console/shared/components/styles/LineageSection.module.scss"],"names":[],"mappings":"AAAA,sBACI,oCAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA","sourcesContent":[".wrapper {\n    border-top: 1px solid rgba(0, 0, 0, 0.08);\n    height: 100%;\n    width: 100%;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Gu7qQ2UHHC2B5j5aoDH4"
};
export default ___CSS_LOADER_EXPORT___;
