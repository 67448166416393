import { forwardRef, ForwardedRef } from "react";
import useSWR from "swr";

import { axiosFetcher } from "../utils/utils";
import { Role } from "../models";

import SelectInput, { SelectItem } from "./SelectInput";

/**
 * If loading and the field has a default value in the context of a form, the active item can't be selected and we get an empty change event
 * that clears the fields value.
 *
 * so we stop the onChange event from firing until we are done fetching the roles
 * */
const NOOP = () => null;

type RoleSelectorProps = {
    disabled?: boolean;
    onChange?: (value: number) => void;
    value?: number;
};

const RoleSelector = forwardRef(
    (props: RoleSelectorProps, ref: ForwardedRef<HTMLButtonElement>) => {
        const { data, error, isLoading } = useSWR<Role[]>(
            ["get", "/api/v1/auth/role"],
            axiosFetcher
        );

        const disabled =
            props.disabled || (!data?.length && isLoading) || error;

        const handleChange = (value: string) =>
            disabled ? NOOP : props.onChange?.(parseInt(value, 10));

        return (
            <SelectInput
                ref={ref}
                {...props}
                onChange={handleChange}
                value={`${props.value}`}
                disabled={disabled}
            >
                {data?.length
                    ? data.map((role) => (
                          <SelectItem key={role.id} value={`${role.id}`}>
                              {role.name}
                          </SelectItem>
                      ))
                    : null}
            </SelectInput>
        );
    }
);

RoleSelector.displayName = "RoleSelector";

export default RoleSelector;
