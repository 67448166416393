// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FsNa9OPnj5W9HRFgmoD7{padding-top:32px;width:100%;height:100%;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/console/shared/components/styles/Loader.module.scss","webpack://./src/console/shared/styles/_page.scss"],"names":[],"mappings":"AAGA,sBCqBI,gBAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["// TODO(xiao): remove page.loading\n@use \"../../styles/page\";\n\n.container {\n    @include page.loading;\n}\n","@use \"./common\" as *;\n\n@mixin container {\n    grid-column: span 12;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    height: 100%;\n}\n\n@mixin title {\n    font-style: normal;\n    font-weight: 600;\n    font-size: 1rem;\n    line-height: 1rem;\n    color: $on-color;\n    display: flex;\n    gap:0.5rem;\n    height: 3.5rem;\n    align-items: center;\n    padding: 0 1rem;\n}\n\n@mixin loading {\n    padding-top: 32px;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FsNa9OPnj5W9HRFgmoD7"
};
export default ___CSS_LOADER_EXPORT___;
