import styles from "./styles/TagFilter.module.scss";
import DatasetIcon from "icons/dataset.svg";

function DatasetFilter({ name }: { name: string }): JSX.Element {
    return (
        <div className={styles.filter}>
            <DatasetIcon />
            <span>{name}</span>
        </div>
    );
}

export default DatasetFilter;
