import OwnerIcon from "icons/owner.svg";
import styles from "../styles/EntityPage.module.scss";

const Owner = ({ owner }: { owner: string }) => {
    return (
        <div className={styles.metadataItem}>
            <div
                className={styles.metadataItemKey}
                data-test={"metadata-owner-test"}
            >
                <OwnerIcon />
                <span>Owner</span>
            </div>
            <div
                className={styles.metadataItemValue}
                data-test={"metadata-owner-value-test"}
            >
                <div className={styles.metadataString}>{owner}</div>
            </div>
        </div>
    );
};

export default Owner;
