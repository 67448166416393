import React, { HTMLAttributes, useMemo } from "react";
import { EdgeProps, getBezierPath } from "reactflow";
import { useIsFocusedNode } from "./DAGProvider";

function DAGEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerStart,
    markerEnd,
    source,
    target,
}: EdgeProps) {
    const sourceFocused = useIsFocusedNode(source);
    const targetFocused = useIsFocusedNode(target);

    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        curvature: 0,
    });

    const style: HTMLAttributes<SVGPathElement>["style"] = useMemo(() => {
        const isFocused = sourceFocused && targetFocused;
        let additional = {};

        if (isFocused) {
            additional = {
                strokeDasharray: 5,
                WebkitAnimation: "dashdraw 0.5s linear infinite",
                animation: "dashdraw 0.5s linear infinite",
            };
        }

        return {
            stroke: "#615f7e",
            opacity: isFocused ? 0.8 : 0.16,
            ...additional,
        };
    }, [sourceFocused, targetFocused]);

    return (
        <path
            id={id}
            style={style}
            d={edgePath}
            fill="none"
            className="react-flow__edge-path"
            markerEnd={markerEnd}
            markerStart={markerStart}
            data-test={`edge-${id}-test`}
        />
    );
}

export default React.memo(DAGEdge);
