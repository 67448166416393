import { useCallback, useMemo } from "react";
import useSWR from "swr";

import { OrgPermission, RoleWithRules, BranchRule } from "shared/models";
import {
    ORG_PERMISSION_GROUPS,
    ORG_PERMISSION_LABELS,
} from "shared/constants/constants";
import { axiosFetcher } from "shared/utils/utils";

import { Dialog, DialogContent } from "shared/components/Dialog";
import IconButton from "shared/components/IconButton";
import Tooltip from "shared/components/Tooltip";
import Chip from "shared/components/Chip";
import EmptyState from "shared/components/EmptyState";
import RoleActionsDropdown from "./RoleActionsDropdown";
import BranchRuleDisplay from "../shared/BranchRuleDisplay";

import CheckIcon from "icons/check-circle.svg";
import CloseIcon from "icons/close.svg";
import DotsVerticalIcon from "icons/dots-vertical.svg";
import LockIcon from "icons/lock.svg";
import HelpSquareIcon from "icons/help-square.svg";
import SlashCircleIcon from "icons/slash-circle.svg";
import ShieldIcon from "icons/shield.svg";

import styles from "./styles/RoleOverviewDialog.module.scss";

const RoleOverviewDialog = ({
    open,
    role_id,
    onOpenChange,
    onDeleteRole,
    onSetDefaultRole,
    viewOnly = false,
}: {
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    onSetDefaultRole?: (role_id: number) => void;
    onDeleteRole?: (role_id: number) => void;
    role_id?: number;
    viewOnly?: boolean;
}) => {
    const { data, isLoading } = useSWR<RoleWithRules>(
        role_id !== undefined ? ["get", `/api/v1/auth/role/${role_id}`] : null,
        axiosFetcher
    );

    const { role, rules } = data || {};

    const org_permissions: OrgPermission[] = useMemo(() => {
        if (!rules?.length) {
            return [] as OrgPermission[];
        }

        return rules
            .filter(({ rule }) => rule.kind === "org")
            .flatMap(({ rule }) => rule.permissions as OrgPermission[]);
    }, [rules]);

    const branch_rules: BranchRule[] = useMemo(() => {
        if (!rules?.length) {
            return [] as BranchRule[];
        }

        return rules
            .filter(({ rule }) => rule.kind === "branch")
            .flatMap(({ rule }) => rule as BranchRule);
    }, [rules]);

    const renderOrgPermissions = useCallback(() => {
        const isSuperAdmin = role?.name === "Super Admin";

        if (!org_permissions?.length && !isSuperAdmin) {
            return (
                <EmptyState
                    className={styles.empty_org_permissions}
                    icon={<SlashCircleIcon />}
                    text={`${role?.name} has no organization permissions.`}
                />
            );
        }

        return Object.entries(ORG_PERMISSION_GROUPS)
            .filter(
                ([_, permissions]) =>
                    isSuperAdmin ||
                    org_permissions.includes(OrgPermission.ALL) ||
                    permissions.every((p) => org_permissions.includes(p))
            )
            .map(([key]) => (
                <div key={key} className={styles.org_permission}>
                    <CheckIcon />
                    <p>{ORG_PERMISSION_LABELS[key]}</p>
                </div>
            ));
    }, [role?.name, org_permissions]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={styles.content}>
                {isLoading ? (
                    <EmptyState loading />
                ) : (
                    <>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                <div className={styles.name}>
                                    <h4>{role?.name}</h4>
                                    {!viewOnly && role?.system_role ? (
                                        <Tooltip content="Built-in roles are immutable">
                                            <div>
                                                <LockIcon
                                                    width={12}
                                                    height={12}
                                                />
                                            </div>
                                        </Tooltip>
                                    ) : null}
                                    {!viewOnly && role?.is_default ? (
                                        <Chip color="primary">Default</Chip>
                                    ) : null}
                                </div>
                                <p>{role?.description}</p>
                            </div>
                            <div className={styles.actions}>
                                {!viewOnly ? (
                                    <RoleActionsDropdown
                                        trigger={
                                            <IconButton
                                                icon={<DotsVerticalIcon />}
                                            />
                                        }
                                        isDefaultRole={role?.is_default}
                                        isSystemRole={role?.system_role}
                                        roleId={role?.id}
                                        onSetDefaultRole={onSetDefaultRole}
                                        onDeleteRole={onDeleteRole}
                                    />
                                ) : null}
                                <IconButton
                                    icon={<CloseIcon />}
                                    onClick={() => onOpenChange?.(false)}
                                />
                            </div>
                        </div>
                        <section>
                            <div className={styles.section_title}>
                                <h4>Organization Permissions</h4>
                                <Tooltip content="Permissions related to org management">
                                    <div>
                                        <HelpSquareIcon />
                                    </div>
                                </Tooltip>
                            </div>
                            <div className={styles.org_grid}>
                                {renderOrgPermissions()}
                            </div>
                        </section>
                        <section>
                            <div className={styles.section_title}>
                                <h4>Branch Resource Permissions</h4>
                                <Tooltip content="Tag-based access rules for datasets and featuresets">
                                    <div>
                                        <HelpSquareIcon />
                                    </div>
                                </Tooltip>
                            </div>
                            {branch_rules?.length ? (
                                <div className={styles.branch_grid}>
                                    {branch_rules.map((rule, i) => (
                                        <BranchRuleDisplay
                                            key={i}
                                            rule={rule}
                                        />
                                    ))}
                                </div>
                            ) : role?.name === "Super Admin" ? (
                                <EmptyState
                                    icon={<ShieldIcon />}
                                    text={`${role.name} has all permissions`}
                                />
                            ) : (
                                <EmptyState
                                    icon={<SlashCircleIcon />}
                                    text={`${role?.name} does not have any branch-level permissions`}
                                />
                            )}
                        </section>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default RoleOverviewDialog;
