import App from "./App";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { toast_error, toast_info, toast_success } from "./shared/utils/toast";
import { CurrentUserProvider } from "./context/CurrentUser";

const container = document.getElementById("root")!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

const demo_mode: boolean = process.env.DEMO_MODE === "true";

const data = container.dataset;
const { flashMsgType, flashMsgContent, tiers, user } = data;
const parsedUser = JSON.parse(user || "{}");
const parsedTiers = JSON.parse(
    demo_mode ? '[{"id": 0, "name": "outbrain"}]' : tiers || "{}"
);

// Initiate the SW for mocking API Responses in development
const prepare = async () => {
    if (demo_mode) {
        const { worker } = await import("../mocks/browser");
        worker.start();
    }

    return Promise.resolve();
};

const root = createRoot(container);
prepare().then(() => {
    root.render(
        <CurrentUserProvider user={parsedUser} tiers={parsedTiers}>
            <App />
            <ToastContainer
                position="bottom-right"
                hideProgressBar
                theme="dark"
            />
        </CurrentUserProvider>
    );
});

if (flashMsgContent) {
    if (flashMsgType == "error") {
        toast_error(flashMsgContent);
    } else if (flashMsgType == "info") {
        toast_info(flashMsgContent);
    } else if (flashMsgType == "success") {
        toast_success(flashMsgContent);
    }
}
