import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm,
    useFormContext,
    useWatch,
} from "react-hook-form";
import useSWR from "swr";
import axios from "axios";

import { axiosFetcher } from "shared/utils/utils";
import {
    OrgPermission,
    OrgRule,
    RolePermissionRule,
    RoleWithRules,
    BranchRule,
} from "shared/models";
import {
    toast_error,
    toast_json_error,
    toast_success,
} from "shared/utils/toast";
import { ORG_PERMISSION_GROUPS } from "shared/constants/constants";

import Button from "shared/components/Button";
import BranchRuleField from "./BranchRuleField";
import OrgPermissionToggle from "./OrgPermissionToggle";

import ChevronDownIcon from "icons/chevron-down.svg";
import AsteriskIcon from "icons/asterisk.svg";
import KeyIcon from "icons/key.svg";
import XSquareIcon from "icons/x-square.svg";
import CheckDoneIcon from "icons/check-done.svg";
import ShieldPlusIcon from "icons/shield-plus.svg";
import ShieldZapIcon from "icons/shield-zap.svg";
import ToolIcon from "icons/tool.svg";
import UsersEditIcon from "icons/users-edit.svg";

import styles from "./styles/CreateRole.module.scss";

type OrgToggleOptions = {
    all: boolean;
    roles: boolean;
    users: boolean;
    tokens: boolean;
    default_role: boolean;
    service_accounts: boolean;
};

export type CreateRoleFormValues = {
    /** The Role name */
    name: string;
    description: string;
    /** The organization rule */
    // organization: OrgRule;
    organization: OrgToggleOptions;
    /**
     * The list of branch rules
     * When the component mounts, if the user is editing a role, we fetch it as a RuleWithRoles
     * and split the rules into org and branch to populate the specific areas of the form. When creating
     * a new role, we fallback to default values.
     * */
    branch: BranchRule[];
};

function CreateRole() {
    const params = useParams();
    const navigate = useNavigate();
    const is_new = params.id === "create";
    const [submitting, setIsSubmitting] = useState<boolean>(false);

    const { data } = useSWR<RoleWithRules>(
        !is_new ? ["get", `/api/v1/auth/role/${params.id}`] : null,
        axiosFetcher
    );

    const defaultValues = useMemo<CreateRoleFormValues>(() => {
        const org_rule = data?.rules.find(
            ({ rule }) => rule.kind === "org"
        ) as RolePermissionRule<OrgRule>;

        const branch_rules = data?.rules.filter(
            ({ rule }) => rule.kind === "branch"
        ) as RolePermissionRule<BranchRule>[];
        return {
            name: data?.role.name || "",
            description: data?.role.description || "",
            organization: {
                all: org_rule?.rule.permissions?.includes(OrgPermission.ALL),
                default_role: ORG_PERMISSION_GROUPS.default_role.every((v) =>
                    org_rule?.rule.permissions?.includes(v)
                ),
                roles: ORG_PERMISSION_GROUPS.roles.every((v) =>
                    org_rule?.rule.permissions?.includes(v)
                ),
                service_accounts: ORG_PERMISSION_GROUPS.service_accounts.every(
                    (v) => org_rule?.rule.permissions?.includes(v)
                ),
                tokens: ORG_PERMISSION_GROUPS.tokens.every((v) =>
                    org_rule?.rule.permissions?.includes(v)
                ),
                users: ORG_PERMISSION_GROUPS.users.every((v) =>
                    org_rule?.rule.permissions?.includes(v)
                ),
            },
            branch: (branch_rules || []).map(({ rule }) => rule),
        };
    }, [data]);

    const form = useForm<CreateRoleFormValues>({
        defaultValues,
        mode: "all",
    });

    const {
        control,
        handleSubmit,
        reset,
        register,
        formState: { errors, isSubmitted, isValid, submitCount },
    } = form;

    const branchFieldArray = useFieldArray({
        control,
        name: "branch",
    });

    useEffect(() => {
        if (isSubmitted && !isValid && errors) {
            for (const [name, { message }] of Object.entries(errors)) {
                toast_error(message || `${name} is invalid.`);
            }
        }
    }, [submitCount]);

    const onSubmit: SubmitHandler<CreateRoleFormValues> = useCallback(
        async (v) => {
            setIsSubmitting(true);
            const { branch, organization } = v;
            let org_permissions = Object.entries(organization)
                .filter(([_, toggled]) => toggled)
                .flatMap(([key]: [string, boolean]) =>
                    key === "all"
                        ? [OrgPermission.ALL]
                        : (ORG_PERMISSION_GROUPS[
                              key as keyof OrgToggleOptions
                          ] as OrgPermission[])
                );

            if (org_permissions.includes(OrgPermission.ALL)) {
                org_permissions = [OrgPermission.ALL];
            }

            const value = {
                role: {
                    name: v.name,
                    description: v.description,
                    is_default: data?.role.is_default || false,
                },
                rules: [
                    {
                        kind: "org",
                        permissions: org_permissions,
                    },
                    ...branch,
                ].map((rule) => ({ rule })),
            };

            const method = is_new ? "post" : "put";
            const url = is_new
                ? `/api/v1/auth/role`
                : `/api/v1/auth/role/${params.id}`;

            await axios[method](url, value, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(() => {
                    setIsSubmitting(false);
                    navigate("/settings/organization/roles");
                    toast_success(
                        `${value.role.name} role ${
                            is_new ? "created" : "updated"
                        }.`
                    );
                    reset();
                })
                .catch((e) => {
                    setIsSubmitting(false);
                    toast_json_error(e, "Something went wrong!");
                });
        },
        [data?.role, params, is_new]
    );

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.root}>
                    <div className={styles.form_header}>
                        <div
                            className={classnames(styles.input, styles.name, {
                                [styles.input_error]: !!errors["name"],
                            })}
                        >
                            <input
                                placeholder="Role Name"
                                {...register(`name`, {
                                    required: "Name is required",
                                })}
                            />
                            <AsteriskIcon />
                        </div>
                        <div
                            className={classnames(styles.input, styles.desc, {
                                [styles.input_error]: !!errors["description"],
                            })}
                        >
                            <input
                                placeholder="Description"
                                {...register(`description`, {
                                    required: "Description is required",
                                })}
                            />
                            <AsteriskIcon />
                        </div>
                    </div>
                    <OrganizationPermissions />
                    <section className={styles.permission_group}>
                        <div className={styles.group_header}>
                            <div className={styles.title}>
                                <p>Branch Permissions</p>
                                <p>
                                    Grant access to branch-level resources, and
                                    entities based on tags
                                </p>
                            </div>
                        </div>
                        <BranchRuleField
                            onChange={branchFieldArray.update}
                            onDeleteRule={branchFieldArray.remove}
                            value={branchFieldArray.fields}
                        />
                    </section>
                    <div className={styles.form_footer}>
                        <Button
                            // disabled={!isDirty || !isValid}
                            loading={submitting}
                            color="primary"
                            type="submit"
                        >
                            {is_new ? "Create" : "Save Changes"}
                        </Button>
                        <Button variant="outline" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}

function OrganizationPermissions() {
    const [expand, setExpand] = useState<boolean>(false);
    const [selectedCount, setSelectedCount] = useState<number>(0);

    const { watch, setValue } = useFormContext();

    const allSelected = useWatch({
        name: "organization.all",
    });

    // Subscription to form field value to keep selectedCount up to date.
    useEffect(() => {
        const subscription = watch((value) => {
            const { organization } = value;
            let count = 0;
            if (organization.all) {
                count = Object.keys(organization).length - 1;
            } else {
                count = Object.values(organization).filter(
                    (value) => !!value
                ).length;
            }

            setSelectedCount(count);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <section className={styles.permission_group}>
            <div
                className={styles.group_header}
                onClick={() => setExpand((prev) => !prev)}
            >
                <div className={styles.title}>
                    <p>Organization Permissions</p>
                    <p>
                        Grant access to organization-level resources and
                        actions.
                    </p>
                </div>
                <div className={styles.indicator}>
                    <p>{selectedCount} / 5</p>
                    <ChevronDownIcon />
                </div>
            </div>
            {expand ? (
                <div className={styles.org_permissions}>
                    <div>
                        <Controller
                            name="organization.all"
                            render={({ field }) => (
                                <Button
                                    icon={
                                        field.value ? (
                                            <XSquareIcon />
                                        ) : (
                                            <CheckDoneIcon />
                                        )
                                    }
                                    color={field.value ? "primary" : "neutral"}
                                    variant={
                                        field.value ? "default" : "outline"
                                    }
                                    onClick={() => {
                                        field.onChange(!field.value);
                                        if (field.value) {
                                            setValue("organization", {
                                                all: false,
                                                roles: false,
                                                tokens: false,
                                                users: false,
                                                default_role: false,
                                                service_accounts: false,
                                            });
                                        }
                                    }}
                                    size={"small"}
                                >
                                    {field.value ? "Uns" : "S"}elect All
                                </Button>
                            )}
                        />
                    </div>
                    <div className={styles.org_permission_grid}>
                        <Controller
                            name="organization.roles"
                            render={({ field }) => {
                                return (
                                    <OrgPermissionToggle
                                        {...field}
                                        disabled={allSelected}
                                        icon={<ShieldPlusIcon />}
                                        checked={allSelected || field.value}
                                        name="roles"
                                        title="Role Management"
                                        description="Create, edit and delete roles."
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="organization.users"
                            render={({ field }) => {
                                return (
                                    <OrgPermissionToggle
                                        {...field}
                                        disabled={allSelected}
                                        icon={<UsersEditIcon />}
                                        checked={allSelected || field.value}
                                        title="User Management"
                                        description="View, and update the role of org members."
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="organization.default_role"
                            render={({ field }) => {
                                return (
                                    <OrgPermissionToggle
                                        {...field}
                                        disabled={allSelected}
                                        icon={<ShieldZapIcon />}
                                        checked={allSelected || field.value}
                                        title="Default Role"
                                        description="Update the default role for new members."
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="organization.tokens"
                            render={({ field }) => {
                                return (
                                    <OrgPermissionToggle
                                        {...field}
                                        disabled={allSelected}
                                        icon={<KeyIcon />}
                                        checked={allSelected || field.value}
                                        title="User Tokens"
                                        description="Can revoke tokens created by other users."
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="organization.service_accounts"
                            render={({ field }) => {
                                return (
                                    <OrgPermissionToggle
                                        {...field}
                                        disabled={allSelected}
                                        icon={<ToolIcon />}
                                        checked={allSelected || field.value}
                                        title="Service Accounts"
                                        description="Manage service accounts and their tokens."
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </section>
    );
}

export default CreateRole;
