import { useEffect, useRef, useState } from "react";
import {
    Filter,
    FilterConfig,
    FilterOptionValue,
    FilterValue,
    MultiSelectConfig,
} from "./filters";
import styles from "./styles/FilterValueMultiSelect.module.scss";
import debounce from "lodash/debounce";
import SearchBar from "./SearchBar";

interface FilterValueMultiSelectProps {
    filter?: Filter;
    filterConfig: FilterConfig;
    multiSelectConfig: MultiSelectConfig;
    onToggleSelect: (value: FilterValue) => void;
    onSubmit: () => void;
}

function FilterValueMultiSelect({
    filter,
    filterConfig,
    multiSelectConfig,
    onToggleSelect,
    onSubmit,
}: FilterValueMultiSelectProps): JSX.Element {
    const [selected, setSelected] = useState<Map<string, FilterOptionValue>>(
        new Map(
            multiSelectConfig.options
                .filter((opt) => filter?.value.includes(opt.value))
                .map((opt) => [opt.key, opt.value])
        )
    );
    const containerRef = useRef<HTMLDivElement>(null);
    const [filteredConfig, setFilteredConfig] = useState(
        multiSelectConfig.options
    );

    const isMultiSelect = filterConfig.kind.kind === "multi-select";

    useEffect(() => {
        const onClick = (e: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(e.target as Node)
            ) {
                onSubmit();
            }
        };

        document.addEventListener("mousedown", onClick);
        return () => {
            document.removeEventListener("mousedown", onClick);
        };
    }, [containerRef, onSubmit]);

    const onChange = (key: string, val: FilterOptionValue) => {
        const newSelected = new Map(selected);
        if (newSelected.has(key)) {
            newSelected.delete(key);
        } else {
            if (isMultiSelect) {
                newSelected.set(key, val);
            } else {
                newSelected.clear();
                newSelected.set(key, val);
            }
        }
        setSelected(newSelected);
        onToggleSelect(Array.from(newSelected.values()));
    };

    const _onSearchChange = (searchText: string) => {
        const newFilteredConfig = multiSelectConfig.options.filter((opt) => {
            let metadataSearch = false;
            if (opt.metadataSearch && opt.metadata) {
                Object.keys(opt.metadata).forEach((key) => {
                    metadataSearch =
                        opt.metadata?.[key]
                            .toLowerCase()
                            .includes(searchText) || metadataSearch;
                });
            }
            return (
                metadataSearch || opt.name.toLowerCase().includes(searchText.toLowerCase())
            );
        });
        setFilteredConfig(newFilteredConfig);
    };

    const onSearchChange = debounce(_onSearchChange, 250);

    return (
        <div className={styles.filterValueMultiSelect} ref={containerRef}>
            <div className={styles.title}>{filterConfig.propertyName}</div>
            <div className={styles.searchInputContainer}>
                <SearchBar onSearch={(text) => onSearchChange(text)} fill />
            </div>
            <div className={styles.filterValueMultiSelectList}>
                {filteredConfig.map((opt) => {
                    return (
                        <div
                            key={opt.key}
                            className={styles.item}
                            onClick={() => onChange(opt.key, opt.value)}
                            data-testid={"filter_opt_" + opt.key}
                        >
                            <input
                                type="checkbox"
                                name={opt.key}
                                checked={selected.has(opt.key)}
                            />
                            <multiSelectConfig.component
                                name={opt.name}
                                metadata={opt.metadata}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default FilterValueMultiSelect;
