// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h3ka2T62tST10pnTOnIK{display:flex;align-items:stretch;flex-direction:column;gap:1.5rem}", "",{"version":3,"sources":["webpack://./src/console/features/settings/ServiceAccountDetail/styles/ServiceAccountTokenCreateDialog.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,mBAAA,CACA,qBAAA,CACA,UAAA","sourcesContent":[".content {\n    display: flex;\n    align-items: stretch;\n    flex-direction: column;\n    gap: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "h3ka2T62tST10pnTOnIK"
};
export default ___CSS_LOADER_EXPORT___;
