import FeatureIcon from "icons/feature.svg";
import styles from "./styles/SourcesFilter.module.scss";

function FeatureMetricsFilter({
    name,
    metadata,
}: {
    name: string;
    metadata?: { [key: string]: string };
}): JSX.Element {
    return (
        <div className={styles.filter}>
            <div className={styles.sourceIcon}>
                <FeatureIcon />
            </div>
            <div className={styles.metadataContainer}>
                <div>{name}</div>
                <div className={styles.metadata}>{metadata?.featureset}</div>
            </div>
        </div>
    );
}

export default FeatureMetricsFilter;
