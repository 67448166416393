import classnames from "classnames";

import { stringCompare } from "../utils/utils";
import styles from "./styles/FeaturesetsTable.module.scss";

import OwnerIcon from "icons/owner.svg";

import Table, { Column } from "./Table";
import { DOC_LINKS } from "../constants/docs";
import MetaField from "./MetaField";
import { Feature } from "../utils/types";
import { useUpdateEntityParams } from "../../context/CurrentView";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    features: Feature[];
    windowed?: boolean;
    emptyText?: string;
    isLearnMore?: boolean;
}

function FeaturesTable({
    features,
    className,
    emptyText,
    isLearnMore,
    windowed,
    ...rest
}: Props): JSX.Element {
    const { updateEntityParams: navigate } = useUpdateEntityParams();

    const columns: Column<Feature>[] = [
        {
            header: "Name",
            renderFunc: (f) => (
                <div className={styles.featureName}>
                    <div>{f.name}</div>
                    <div className={styles.featureDescription}>
                        {f.description}
                    </div>
                </div>
            ),
            sortFunc: (x, y) => stringCompare(x.name, y.name),
        },
        {
            header: "Featureset",
            renderFunc: (f) => (
                <div>
                    <span>{f.featureset}</span>
                </div>
            ),
            sortFunc: (x, y) =>
                stringCompare(x.featureset as string, y.featureset as string),
        },
        {
            header: "Owner",
            renderFunc: (fs) => (
                <MetaField icon={<OwnerIcon />} name={fs.owner || ""} />
            ),
            sortFunc: (x, y) => stringCompare(x.owner || "", y.owner || ""),
        },
    ];

    return (
        <Table
            windowed={windowed}
            className={classnames(className, styles.table)}
            data={features}
            columns={columns}
            rowKeyFunc={(fs) => fs.name + "_" + fs.featureset}
            onRowClick={(row) => navigate("feature", row.name, row.featureset)}
            dataUnit="Feature"
            emptyText={emptyText}
            learnMore={isLearnMore ? DOC_LINKS.featureset : undefined}
            {...rest}
        />
    );
}

export default FeaturesTable;
