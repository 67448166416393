import styles from "./styles/ToggleGroup.module.scss";
import React from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";

type Props = {
    items: {
        key: string;
        value: string;
        label: string | React.ReactNode;
    }[];
    onValueChange: (value: string) => void;
    activeTab: string;
};

const ToggleGroupComponent = (props: Props) => {
    return (
        <ToggleGroup.Root
            className={styles.container}
            type="single"
            onValueChange={props.onValueChange}
            value={props.activeTab}
        >
            {props.items.map((item) => (
                <ToggleGroup.Item
                    key={item.key}
                    value={item.value}
                    className={styles.child}
                >
                    {item.label}
                </ToggleGroup.Item>
            ))}
        </ToggleGroup.Root>
    );
};

export default ToggleGroupComponent;
