export const DOC_LINKS = {
    branches: "https://fennel.ai/docs/concepts/branch",
    featureset: "https://fennel.ai/docs/concepts/featureset",
    source: "https://fennel.ai/docs/concepts/source",
    dataset: "https://fennel.ai/docs/concepts/dataset",
    expectation: "https://fennel.ai/docs/api-reference/expectations",
    queryOffline: "https://fennel.ai/docs/api-reference/client/query-offline",
    extractor: "https://fennel.ai/docs/concepts/featureset#extractors",
    pipelines: "https://fennel.ai/docs/concepts/pipeline",
};
