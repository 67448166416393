import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { DATA_NAV } from "shared/constants/navigation";
import ToggleGroupComponent from "shared/components/ToggleGroup";
import styles from "./DataPage.module.scss";
import ConsolePage from "../console-page/ConsolePage";
import { Dataset, SchemaField } from "shared/utils/types";
import { CurrentViewContext } from "../../context/CurrentView/CurrentViewProvider";
import { branchedDatasetLink, displayFormattedValue } from "shared/utils/utils";
import axios, { AxiosResponse } from "axios";
import Table, { Column } from "shared/components/Table";
import { LookupForm } from "shared/components/Lookup";
import ComboxBox from "shared/components/combobox/Combobox";
import DatasetIcon from "icons/dataset.svg";
import KeyIcon from "icons/key-field.svg";
import ChevronDown from "icons/chevron-down.svg";
import { useUpdateEntityParams } from "../../context/CurrentView";

enum DataType {
    INSPECT = "inspect",
    LOOKUP = "lookup",
}

function DataPage(): JSX.Element {
    const { viewInfo } = useContext(CurrentViewContext);
    const [featureTab, setFeatureTab] = useState(DataType.INSPECT);
    const location = useLocation();
    const { updateEntityParams } = useUpdateEntityParams();

    const comboRef = useRef(null);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (location.hash.length > 0) {
            setFeatureTab(location.hash.substring(1) as DataType);
        }
    }, [location]);

    const datasets = viewInfo?.datasets;
    const initDatasetName = searchParams.get("dataset");
    const comboboxItems = datasets?.map((d) => {
        const isKeyPresent = d.dsschema.some((s) =>
            s.qualifiers.includes("Key")
        );
        return {
            icon: <DatasetIcon />,
            display: (
                <div className={styles.keyedDataset}>
                    {d.name}
                    {isKeyPresent && <KeyIcon />}
                </div>
            ),
            value: d.name,
        };
    });

    const [selectedDataset, setSelectedDataset] = useState<
        Dataset | undefined
    >();

    useEffect(() => {
        const initDataset = initDatasetName
            ? datasets?.find((d) => d.name === initDatasetName)
            : undefined;
        setSelectedDataset(initDataset);
    }, [initDatasetName]);

    return (
        <ConsolePage
            customClassNames={{
                container: styles.overflowHidden,
                content: styles.overflowAuto,
            }}
            header={{
                icon: DATA_NAV.icon,
                title: DATA_NAV.title,
            }}
            content={
                featureTab === DataType.LOOKUP ? (
                    <LookupSection dataset={selectedDataset} />
                ) : (
                    <InspectSection dataset={selectedDataset} />
                )
            }
            subheader={
                <div className={styles.subheader}>
                    <div className={styles.toggleContainer}>
                        <ToggleGroupComponent
                            items={[
                                {
                                    key: DataType.INSPECT,
                                    label: "Inspect",
                                    value: DataType.INSPECT,
                                },
                                {
                                    key: DataType.LOOKUP,
                                    label: "Lookup Keys",
                                    value: DataType.LOOKUP,
                                },
                            ]}
                            onValueChange={(value) => {
                                if (value) {
                                    if (value != featureTab) {
                                        window.location.hash = value;
                                        setFeatureTab(value as DataType);
                                    }
                                }
                            }}
                            activeTab={featureTab}
                        />
                    </div>
                    <div ref={comboRef}>
                        <ComboxBox
                            items={comboboxItems}
                            trigger={
                                <div className={styles.combobox}>
                                    <div className={styles.comboName}>
                                        <DatasetIcon />
                                        {selectedDataset
                                            ? selectedDataset.name
                                            : "None"}
                                    </div>
                                    <ChevronDown />
                                </div>
                            }
                            onItemClick={(datasetName: string) => {
                                setSelectedDataset(
                                    viewInfo?.datasets?.find(
                                        (d) => d.name === datasetName
                                    )
                                );
                                updateEntityParams("dataset", datasetName);
                            }}
                        />
                    </div>
                </div>
            }
        />
    );
}

const LookupSection = ({ dataset }: { dataset?: Dataset }) => {
    const isKeyPresent = dataset?.dsschema.some((s) =>
        s.qualifiers.includes("Key")
    );
    if (!dataset || !isKeyPresent) {
        return (
            <EmptyDataPageState
                text={"Select a keyed dataset to lookup values"}
            />
        );
    }

    return <LookupForm schema={dataset.dsschema} datasetName={dataset.name} />;
};

const EmptyDataPageState = ({ text }: { text: string }) => {
    return (
        <div className={styles.emptyState}>
            <div className={styles.emptyStateCenter}>
                <DatasetIcon width={"5rem"} height={"5rem"} />
                <div>{text}</div>
            </div>
        </div>
    );
};

function InspectSection({ dataset }: { dataset?: Dataset }) {
    if (!dataset) {
        return <EmptyDataPageState text={"Select a dataset to view rows"} />;
    }
    return (
        <InspectTable schema={dataset.dsschema} datasetName={dataset.name} />
    );
}

function InspectTable({
    schema,
    datasetName,
}: {
    schema?: SchemaField[];
    datasetName?: string;
}) {
    const { branchName } = useParams();
    const [inspectDataset, setInspectDataset] = useState<
        { [key: string]: string | number | boolean }[]
    >([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(true);
    useEffect(() => {
        setInspectDataset([]);
        setIsLoading(true);
        const url = branchedDatasetLink(branchName, datasetName) + "/inspect";
        axios
            .post(url)
            .then((resp: AxiosResponse<{ [key: string]: string }[]>) => {
                setInspectDataset(resp.data);
            })
            .catch((e) => {
                if (e.response && e.response.status === 401) {
                    setIsAuthorized(false);
                } else {
                    console.error("Error in inspecting dataset");
                }
                setInspectDataset([]);
            })
            .finally(() => setIsLoading(false));
    }, [branchName, datasetName]);
    const keys =
        inspectDataset.length > 0 ? Object.keys(inspectDataset[0]) : [];
    const columns: Column<any>[] = useMemo(
        () =>
            keys.map((key) => ({
                header: key,
                renderFunc: (data) => (
                    <div>{displayFormattedValue(data[key])}</div>
                ),
                headerRenderFunc: () => (
                    <>
                        <span>{key}</span>
                        <span className={styles.inspect_table_schema}>
                            {schema?.find((d) => d.name == key)?.type}
                        </span>
                    </>
                ),
            })),
        [keys]
    );

    return (
        <Table
            className={styles.inspect_table}
            data={inspectDataset}
            columns={columns}
            emptyText={
                isLoading
                    ? "Searching for latest data.."
                    : isAuthorized
                    ? "No rows founds for this dataset"
                    : "You don't have the required permission to view this data"
            }
            rowKeyFunc={(p) => JSON.stringify(p)}
            dataUnit="row"
            fixedHeader
            divided
            isLoading={isLoading}
        />
    );
}

export default DataPage;
