import { useState } from "react";
import useSWR from "swr";
import { Link, useNavigate, useParams } from "react-router-dom";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { ServiceAccount } from "shared/models";
import { axiosFetcher } from "shared/utils/utils";

import Button from "shared/components/Button";
import DropdownMenu from "shared/components/DropdownMenu";
import MenuItem from "shared/components/MenuItem";
import EmptyState from "shared/components/EmptyState";
import PageTitle from "../shared/PageTitle";
import ServiceAccountTokens from "./ServiceAccountTokens";
import CreateServiceAccountDialog from "../OrganizationServiceAccountSettings/CreateServiceAccountDialog";
import ServiceAccountDeleteDialog from "../OrganizationServiceAccountSettings/ServiceAccountDeleteDialog";

import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import DropdownIcon from "icons/dropdown.svg";
import XSquareIcon from "icons/x-square.svg";
import EditIcon from "icons/edit.svg";

import styles from "./styles/ServiceAccountDetail.module.scss";
import { AlertDialog } from "shared/components/AlertDialog";
import { Dialog } from "shared/components/Dialog";
import UserDisplay from "shared/components/UserDisplay";
import { SERVICE_NAV } from "shared/constants/navigation";

const ServiceAccountDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [updating, setUpdating] = useState<ServiceAccount | undefined>();
    const [deleting, setDeleting] = useState<number | undefined>();

    const { data, mutate, isLoading, error } = useSWR<ServiceAccount>(
        ["get", `/api/v1/auth/service_account/${id}`],
        axiosFetcher
    );

    if (!data || isLoading || error) {
        return (
            <EmptyState
                loading={!error && isLoading}
                text={error ? "Something went wrong." : undefined}
            />
        );
    }

    return (
        <div className={styles.root}>
            <PageTitle
                title={data.name}
                backTo={"/settings/organization/service-accounts"}
                backText="Back to Service Accounts"
                titleIcon={SERVICE_NAV.icon}
            >
                <DropdownMenu
                    trigger={
                        <Button
                            icon={<DropdownIcon />}
                            direction="row-reverse"
                            variant="outline"
                        >
                            Actions
                        </Button>
                    }
                >
                    <MenuItem
                        icon={<EditIcon />}
                        onClick={() => setUpdating(data)}
                    >
                        Edit Service Account
                    </MenuItem>
                    <MenuItem
                        icon={<XSquareIcon />}
                        onClick={() => setDeleting(data.id)}
                    >
                        Delete Service Account
                    </MenuItem>
                </DropdownMenu>
            </PageTitle>
            <div className={styles.metadata}>
                <div>
                    <h6>Active</h6>
                    <p>{data.revoked_at ? "false" : "true"}</p>
                </div>
                <div>
                    <h6>Created at </h6>
                    <p>
                        {formatDistanceToNow(
                            parseISO(`${data.created_at || ""}Z`),
                            {
                                addSuffix: true,
                            }
                        )}
                    </p>
                </div>
                <div>
                    <h6>Owner</h6>
                    <UserDisplay size="small" name={data.owner} />
                </div>
                <div>
                    <h6>Role</h6>
                    <Link
                        className={styles.role_link}
                        to="/settings/organization/roles"
                    >
                        <p>{data.role.name}</p>
                        <ArrowNarrowUpRightIcon />
                    </Link>
                </div>
            </div>
            <ServiceAccountTokens />
            <AlertDialog
                open={deleting !== undefined}
                onOpenChange={(open) =>
                    setDeleting((prev) => (!open ? undefined : prev))
                }
            >
                <ServiceAccountDeleteDialog
                    serviceAccountId={deleting}
                    onSuccess={() => {
                        navigate("/settings/organization/service-accounts");
                        setDeleting(undefined);
                        mutate();
                    }}
                />
            </AlertDialog>
            <Dialog
                open={!!updating}
                onOpenChange={(open) => {
                    setUpdating((prev) => (!open ? undefined : prev));
                }}
            >
                <CreateServiceAccountDialog
                    updating={updating}
                    onSuccess={() => {
                        setUpdating(undefined);
                        mutate();
                    }}
                />
            </Dialog>
        </div>
    );
};

export default ServiceAccountDetail;
