import { FilterConfig, FilterValue } from "./filters";
import FilterValueMultiSelect from "./FilterValueMultiSelect";

interface FilterValueSelectProps {
    filterConfig: FilterConfig;
    onSelect: (value: FilterValue) => void;
    onSubmit: () => void;
}

function FilterValueSelect({
    filterConfig,
    onSelect,
    onSubmit,
}: FilterValueSelectProps): JSX.Element {
    return (
        <FilterValueMultiSelect
            filterConfig={filterConfig}
            multiSelectConfig={filterConfig.kind}
            onToggleSelect={onSelect}
            onSubmit={onSubmit}
        />
    );
}

export default FilterValueSelect;
