import SourceFromIcon from "icons/cover.svg";
import styles from "../styles/EntityPage.module.scss";
import { Link } from "react-router-dom";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import DatasetIcon from "icons/dataset.svg";
import { getSourceTypeIcon } from "../../../utils/utils";
import { Source } from "../../../utils/types";
import { useUpdateEntityParams } from "../../../../context/CurrentView";

const SourcedFrom = ({
    sources,
    datasets,
    isSourced,
}: {
    sources: Source[];
    datasets: string[];
    isSourced?: boolean;
}) => {
    const { updateEntityParams: navigate } = useUpdateEntityParams();
    return (
        <div className={styles.metadataItem}>
            <div
                className={styles.metadataItemKey}
                data-test={"metadata-sourced-test"}
            >
                <SourceFromIcon />
                <span>{isSourced ? "Sourced" : "Derived"} From</span>
            </div>
            <div>
                {isSourced ? (
                    <>
                        {sources.map((source) => {
                            const Icon = getSourceTypeIcon(source.source_type);
                            return (
                                <div
                                    className={styles.metadataItemValue}
                                    key={source.name}
                                    data-test={"metadata-sourced-value-test"}
                                >
                                    <Link
                                        to={"/sources"}
                                        className={styles.metadataLink}
                                    >
                                        <Icon />
                                        <div className={styles.metadataString}>
                                            {source.source_type} -{" "}
                                            {source.table}
                                        </div>
                                        <ArrowNarrowUpRightIcon />
                                    </Link>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <>
                        {datasets.map((dataset) => (
                            <div
                                className={styles.metadataItemValue}
                                key={dataset}
                                data-test={"metadata-derived-value-test"}
                            >
                                <div
                                    onClick={() => navigate("dataset", dataset)}
                                    className={styles.metadataLink}
                                >
                                    <DatasetIcon />
                                    <div className={styles.metadataString}>
                                        {dataset}
                                    </div>
                                    <ArrowNarrowUpRightIcon />
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default SourcedFrom;
