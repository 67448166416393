import styles from "./styles/LogInPage.module.scss";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import LogoAndName from "./LogoAndName";
import { FormEventHandler, useState } from "react";
import axios from "axios";
import TextDivider from "shared/components/TextDivider";
import ThirdPartyAuth from "shared/components/ThirdPartyAuth";
import GoogleIcon from "icons/google.svg";
import LockIcon from "icons/lock.svg";
import Button from "shared/components/Button";
import { toast_json_error } from "shared/utils/toast";
import { Field, TextInput } from "shared/components/TextInput";

const container = document.getElementById("root")!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
const data = container.dataset;
const sso_url = data.sso;

function LogInPage(): JSX.Element {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOTP] = useState("");
    const [is2faEnabled, set2FAEnabled] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const onSubmit: FormEventHandler = (e) => {
        setSubmitting(true);
        axios
            .post("/login", { email, password, otp })
            .then((resp) => {
                if (resp.data && resp.data.mfa_required) {
                    set2FAEnabled(true);
                } else {
                    window.location.href = "/";
                }
                setSubmitting(false);
            })
            .catch((e) => {
                toast_json_error(e, "Failed to log in");
                setSubmitting(false);
            });
        e.preventDefault();
    };

    return (
        <div className={styles.gridContainer}>
            <div className={styles.container}>
                <div className={styles.logoAndName}>
                    <LogoAndName />
                </div>
                <div className={styles.titleRow}>
                    <span className={styles.title}>Log In</span>
                    <Link to="/signup" className={styles.link}>
                        Sign up instead?
                    </Link>
                </div>
                <form onSubmit={onSubmit}>
                    <div className={styles.inputGroup}>
                        <Field>
                            <TextInput
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Field>
                    </div>
                    <div className={styles.inputGroup}>
                        <Field>
                            <TextInput
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Field>
                    </div>
                    {is2faEnabled && (
                        <>
                            <div className={styles.otp}>
                                Enter OTP from your authenticator app
                            </div>
                            <div className={styles.inputGroup}>
                                <Field>
                                    <TextInput
                                        type="text"
                                        placeholder="OTP"
                                        value={otp}
                                        onChange={(e) => setOTP(e.target.value)}
                                    />
                                </Field>
                            </div>
                        </>
                    )}

                    <Button
                        type="submit"
                        color="primary"
                        className={styles.button}
                        disabled={submitting}
                    >
                        Login
                    </Button>
                </form>
                <Link
                    to="/forgot_password"
                    className={classnames(styles.link, styles.forgot)}
                >
                    Forgot your password?
                </Link>
                <div className={styles.orDivider}>
                    <TextDivider text="or" />
                </div>
                <div className={styles.thirdPartyAuth}>
                    {sso_url ? (
                        <ThirdPartyAuth
                            href={sso_url}
                            icon={
                                <LockIcon
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                />
                            }
                            text="Sign in with your Identity Provider"
                        />
                    ) : (
                        <ThirdPartyAuth
                            href="/login/google"
                            icon={
                                <GoogleIcon
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                />
                            }
                            text="Sign in with Google"
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default LogInPage;
