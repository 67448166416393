import { useCallback, useState } from "react";
import axios, { AxiosError } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast_json_error, toast_success } from "shared/utils/toast";

import {
    AlertDialogActions,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogTitle,
} from "shared/components/AlertDialog";
import { Field, TextInput } from "shared/components/TextInput";
import Button from "shared/components/Button";

import styles from "./styles/TokenDeleteDialog.module.scss";

type DeleteTokenFormValues = {
    confirm: string;
};

const schema = yup
    .object({
        confirm: yup.string().is(["DELETE"]).required(),
    })
    .required();

const TokenDeleteDialog = ({
    onSuccess,
    tokenName,
}: {
    onSuccess: () => void;
    tokenName?: string;
}) => {
    const {
        register,
        reset,
        handleSubmit,
        formState: { isValid },
    } = useForm<DeleteTokenFormValues>({
        resolver: yupResolver(schema),
    });

    const [submitting, setIsSubmitting] = useState<boolean>(false);

    const onSubmit: SubmitHandler<DeleteTokenFormValues> =
        useCallback(async () => {
            try {
                setIsSubmitting(true);
                await axios.delete(`/api/v1/auth/token/${tokenName}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                setIsSubmitting(false);
                toast_success("Token revoked");
                onSuccess();
                reset();
            } catch (e) {
                setIsSubmitting(false);
                toast_json_error(
                    e as AxiosError<{ detail?: string }>,
                    "Something went wrong"
                );
                reset();
            }
        }, [onSuccess, tokenName]);

    return (
        <AlertDialogContent>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
                This action is irreversible and once revoked, this token can no
                longer be used.
            </AlertDialogDescription>
            <form
                className={styles.delete_dialog_form}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Field label={`Type "DELETE" to confirm`}>
                    <TextInput {...register("confirm")} />
                </Field>
                <AlertDialogActions>
                    <AlertDialogCancel>
                        <Button variant="outline">Cancel</Button>
                    </AlertDialogCancel>
                    <Button
                        disabled={!isValid}
                        loading={submitting}
                        type="submit"
                        color="danger"
                    >
                        Confirm
                    </Button>
                </AlertDialogActions>
            </form>
        </AlertDialogContent>
    );
};

export default TokenDeleteDialog;
