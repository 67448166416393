import TagIcon from "icons/tag.svg";
import styles from "../styles/EntityPage.module.scss";
import {
    DirectTags,
    InheritedTags,
} from "shared/components/expandableTags/TagRenderer";

const Tags = ({
    tags,
    lineage_tags,
}: {
    tags: string[];
    lineage_tags?: string[];
}) => {
    const inherited_tags = lineage_tags?.filter((x) => !tags.includes(x));
    return (
        <div className={styles.metadataItem}>
            <div
                className={styles.metadataItemKey}
                data-test={"metadata-tags-test"}
            >
                <TagIcon />
                <span>Tags</span>
            </div>
            <div
                className={styles.metadataItemValue}
                data-test={"metadata-tags-value-test"}
            >
                {tags && tags.length > 0 && <DirectTags tags={tags} />}
                {inherited_tags && inherited_tags?.length > 0 && (
                    <InheritedTags tags={inherited_tags} />
                )}
                {!tags.length && !inherited_tags?.length && (
                    <span className={styles.none}>None</span>
                )}
            </div>
        </div>
    );
};

export default Tags;
