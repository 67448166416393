import { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import LoadingError from "./LoadingError";
import LogoLoader from "./LogoLoader";
import styles from "./styles/Loader.module.scss";

const retry = async <T,>(
    fn: () => Promise<AxiosResponse<T> | AxiosResponse<T>[]>,
    retries = 5,
    delay = 1000
) => {
    for (let i = 0; i < retries; i++) {
        try {
            return await fn();
        } catch (err) {
            if (i < retries - 1) {
                await new Promise((resolve) => setTimeout(resolve, delay));
            } else {
                throw err;
            }
        }
    }
};

const AxiosLoader = <T,>({
    onload,
    loadFunc,
    loadTransformFunc,
}: {
    onload: (data: T) => JSX.Element;
    loadFunc: () => Promise<AxiosResponse<T> | AxiosResponse<T>[]>;
    loadTransformFunc?: (response?: T | T[]) => T;
}): JSX.Element => {
    const [firstLoad, setFirstLoad] = useState(true);
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(false);
    const [loadingErrMsg, setLoadingErrMsg] = useState("");
    const [reload, setReload] = useState(0);
    useEffect(() => {
        setLoading(true);
        setFirstLoad(false);
        retry(loadFunc)
            .then((response?: AxiosResponse<T> | AxiosResponse<T>[]) => {
                setLoading(false);
                if (Array.isArray(response)) {
                    const concData = response.reduce((acc: any, curr: any) => {
                        return acc.concat(curr.data);
                    }, [] as unknown as T);
                    setData(
                        loadTransformFunc
                            ? loadTransformFunc(concData)
                            : concData
                    );
                } else {
                    setData(
                        loadTransformFunc
                            ? loadTransformFunc(response?.data)
                            : response?.data
                    );
                }
            })
            .catch((err) => {
                setData(undefined);
                setLoading(false);
                if (err instanceof AxiosError && err.response?.data?.detail) {
                    setLoadingErrMsg(err.response.data.detail);
                } else {
                    setLoadingErrMsg(err.message);
                }
            });
    }, [reload, loadFunc]);

    if (firstLoad || loading || data == undefined) {
        return (
            <div className={styles.container}>
                {loading || firstLoad ? (
                    <LogoLoader />
                ) : (
                    <LoadingError
                        message={loadingErrMsg}
                        onReload={() => setReload(reload + 1)}
                    />
                )}
            </div>
        );
    }

    return onload(data);
};

export default AxiosLoader;
