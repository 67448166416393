// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kxKpvIOdS7N7cN0u7CgY{padding:2rem 0;display:flex;flex-direction:column;align-items:stretch;gap:3rem}.kxKpvIOdS7N7cN0u7CgY>section{display:flex;flex-direction:column;align-items:stretch;gap:2rem}.iQhYv1pWyWjKhbwX87li{display:flex;align-items:center;gap:2rem}.b6G3woYGk06PEcOeJT_R{gap:1.5rem}.tip62gqe1V3wocuSxPDe{display:flex;flex-direction:column;gap:.5rem;max-width:25.5rem}.nfqoJv3i6mpnXj5BXTjn{padding:.5rem 0 1.5rem 0}", "",{"version":3,"sources":["webpack://./src/console/features/settings/UserSettings/styles/UserSettings.module.scss"],"names":[],"mappings":"AAEA,sBACI,cAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,QAAA,CAEA,8BACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,QAAA,CAIR,sBACI,YAAA,CACA,kBAAA,CACA,QAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,SAAA,CACA,iBAAA,CAGJ,sBACI,wBAAA","sourcesContent":["@use \"shared/styles/common\" as *;\n\n.root {\n    padding: 2rem 0;\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    gap: 3rem;\n\n    & > section {\n        display: flex;\n        flex-direction: column;\n        align-items: stretch;\n        gap: 2rem;\n    }\n}\n\n.user_header {\n    display: flex;\n    align-items: center;\n    gap: 2rem;\n}\n\n.general_settings {\n    gap: 1.5rem;\n}\n\n.profile_form {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    max-width: 25.5rem;\n}\n\n.form_footer {\n    padding: 0.5rem 0 1.5rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "kxKpvIOdS7N7cN0u7CgY",
	"user_header": "iQhYv1pWyWjKhbwX87li",
	"general_settings": "b6G3woYGk06PEcOeJT_R",
	"profile_form": "tip62gqe1V3wocuSxPDe",
	"form_footer": "nfqoJv3i6mpnXj5BXTjn"
};
export default ___CSS_LOADER_EXPORT___;
