// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rYcXSuUNy0q9mRHT90Kw{display:inline-flex;align-items:center;flex-wrap:wrap;gap:.25rem;flex-grow:1;min-width:0}", "",{"version":3,"sources":["webpack://./src/console/shared/components/expandableTags/TagRenderer.module.scss"],"names":[],"mappings":"AACA,sBACE,mBAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA","sourcesContent":["\n.container {\n  display: inline-flex;   \n  align-items: center;\n  flex-wrap: wrap;\n  gap: 0.25rem;\n  flex-grow:1;\n  min-width: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "rYcXSuUNy0q9mRHT90Kw"
};
export default ___CSS_LOADER_EXPORT___;
