import { useMemo, useState } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { Token } from "shared/models";
import { axiosFetcher } from "shared/utils/utils";

import Button from "shared/components/Button";
import ReactTable from "shared/components/ReactTable";
import EmptyState from "shared/components/EmptyState";

import DeleteIcon from "icons/delete.svg";
import KeyIcon from "icons/key.svg";
import PlusIcon from "icons/plus.svg";

import styles from "./styles/ServiceAccountTokens.module.scss";
import IconButton from "shared/components/IconButton";
import UserDisplay from "shared/components/UserDisplay";
import { Dialog } from "shared/components/Dialog";
import { AlertDialog } from "shared/components/AlertDialog";
import TokenDeleteDialog from "../TokenSettings/TokenDeleteDialog";
import TokenConfirmDialog from "../TokenSettings/TokenConfirmDialog";
import ServiceAccountTokenCreateDialog from "./ServiceAccountTokenCreateDialog";

const columnHelper = createColumnHelper<Token>();

const DEFAULT_SORT = [
    {
        id: "created_at",
        desc: true,
    },
];

const ServiceAccountTokens = () => {
    const { id } = useParams();
    const [sorting, _setSorting] = useState<SortingState>(DEFAULT_SORT);
    const [creating, setCreating] = useState<boolean>();
    const [deletingToken, setDeletingToken] = useState<string | undefined>();
    const [createdToken, setCreatedToken] = useState<string | undefined>();

    const { data, mutate, isLoading, error } = useSWR<Token[]>(
        ["get", `/api/v1/auth/service_account/${id}/token`],
        axiosFetcher
    );

    const tableState = useMemo(() => ({ sorting }), [sorting]);

    const columns = useMemo(
        () => [
            columnHelper.accessor(({ token_name }) => token_name, {
                header: "Name",
                size: 240,
                cell: ({ row, renderValue }) => (
                    <div className={styles.token_name}>
                        {renderValue()}{" "}
                        <span>••••{row.original.token_suffix}</span>
                    </div>
                ),
            }),
            columnHelper.accessor(({ owner }) => owner, {
                header: "Created by",
                cell: ({ renderValue }) => (
                    <UserDisplay name={renderValue() || ""} size="small" />
                ),
            }),
            columnHelper.accessor(
                ({ created_timestamp }) => created_timestamp,
                {
                    id: "created_at",
                    header: "Created at",
                    size: 160,
                    cell: ({ renderValue }) => {
                        return formatDistanceToNow(
                            parseISO(`${renderValue() || ""}Z`),
                            {
                                addSuffix: true,
                            }
                        );
                    },
                }
            ),
            {
                id: "actions",
                header: () => null,
                cell: ({ row }: { row: { original: Token } }) => (
                    <IconButton
                        className={styles.delete_token_btn}
                        icon={<DeleteIcon />}
                        size="small"
                        onClick={() =>
                            setDeletingToken(row.original.token_name)
                        }
                    />
                ),
                size: 40,
            },
        ],
        []
    );

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <h3>Tokens</h3>
                <Button
                    icon={<PlusIcon />}
                    onClick={() => setCreating(true)}
                    color="primary"
                    size="small"
                >
                    New Token
                </Button>
            </div>
            {data?.length ? (
                <ReactTable
                    className={styles.table}
                    data={data}
                    columns={columns}
                    state={tableState}
                />
            ) : (
                <EmptyState
                    className={styles.table}
                    loading={isLoading}
                    icon={<KeyIcon />}
                    text={
                        error
                            ? "Something went wrong. Please refresh and try again."
                            : "You haven't created any tokens for this service account yet."
                    }
                />
            )}
            <AlertDialog
                open={deletingToken !== undefined}
                onOpenChange={(open) =>
                    setDeletingToken((prev) => (!open ? undefined : prev))
                }
            >
                <TokenDeleteDialog
                    tokenName={deletingToken}
                    onSuccess={() => {
                        setDeletingToken(undefined);
                        mutate();
                    }}
                />
            </AlertDialog>
            <Dialog open={creating} onOpenChange={(open) => setCreating(open)}>
                <ServiceAccountTokenCreateDialog
                    accountId={id}
                    onSuccess={(token) => {
                        setCreating(false);
                        mutate();
                        setCreatedToken(token);
                    }}
                />
            </Dialog>
            <Dialog
                open={createdToken !== undefined}
                onOpenChange={(open) =>
                    setCreatedToken((prev) => (!open ? undefined : prev))
                }
            >
                <TokenConfirmDialog token={createdToken} />
            </Dialog>
        </div>
    );
};

export default ServiceAccountTokens;
