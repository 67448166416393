import { useCallback, useEffect, useMemo } from "react";
import { ColumnFiltersState } from "@tanstack/react-table";
import {
    useForm,
    useFieldArray,
    Controller,
    SubmitHandler,
} from "react-hook-form";
import classnames from "classnames";

import { useControlledValue } from "../../hooks";

import styles from "./styles/TableFilterToolbar.module.scss";

import DropdownMenu from "../../components/DropdownMenu";
import MenuItem from "../../components/MenuItem";

import PlusCircleIcon from "icons/plus-circle.svg";
import DropdownIcon from "icons/dropdown.svg";
import XSquareIcon from "icons/x-square.svg";

interface Option {
    label: string;
    value?: string;
}

interface AttributeOption extends Option {
    column: string;
    pinned: boolean;
    icon: JSX.Element;
    options: Option[];
}

type TableFilterToolbarProps = {
    onChange?: (filters: ColumnFiltersState) => void;
    /** The Available attributes that the user can filter on
     * can also specify and attribute as "pinned" so that it always
     * shows in the UI without having to explicitly add it.
     */
    attributes: AttributeOption[];
    value?: ColumnFiltersState;
};

type FilterToolbarFormValue = {
    filters: AttributeOption[];
};

const TableFilterToolbar = ({
    attributes,
    onChange,
    value: controlledValue = [],
}: TableFilterToolbarProps) => {
    const [value, setValue] = useControlledValue<ColumnFiltersState>({
        controlled: controlledValue,
        name: "filters",
        default: [],
    });

    const defaultValues = useMemo(() => {
        const pinned = attributes
            .filter(({ pinned }) => pinned)
            .map((attr) => {
                const existing_filter = controlledValue?.find?.(
                    ({ id }) => id === attr.column
                );
                return {
                    ...attr,
                    value: existing_filter?.value,
                } as AttributeOption;
            });
        return {
            filters: pinned,
        };
    }, [attributes, value]);

    const { control, reset, handleSubmit } = useForm<FilterToolbarFormValue>({
        defaultValues,
    });

    const { fields } = useFieldArray({
        control,
        name: "filters",
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const onSubmit: SubmitHandler<FilterToolbarFormValue> = useCallback(
        (value) => {
            const { filters } = value;

            const new_value = (
                (filters as AttributeOption[])?.map(({ column, value }) => ({
                    id: column,
                    value,
                })) || []
            ).filter(({ value }) => !!value) as ColumnFiltersState;

            onChange?.(new_value);
            setValue(new_value);
        },
        []
    );

    const hasAnyValue = value?.some(({ value }) => !!value);

    return (
        <div className={styles.root}>
            {fields.map(({ icon, label, options = [], column }, i) => (
                <Controller
                    key={i}
                    control={control}
                    name={`filters.${i}.value`}
                    render={({ field }) => (
                        <FilterChip
                            key={column}
                            options={options}
                            label={label}
                            column={column}
                            icon={icon}
                            onChange={(v) => {
                                field.onChange(v);
                                handleSubmit(onSubmit)();
                            }}
                            value={field.value}
                        />
                    )}
                />
            ))}
            {hasAnyValue ? (
                <div
                    className={classnames(styles.filter_chip, styles.filled)}
                    onClick={() => onChange?.([])}
                >
                    <XSquareIcon />
                    Clear Filters
                </div>
            ) : null}
        </div>
    );
};

export default TableFilterToolbar;

type FilterChipProps = {
    /** Column ID */
    column?: string;
    /** Displayed label on the Chip */
    label: string;
    /** Displayed icon on the Chip */
    icon: JSX.Element;
    /** The value to filter on */
    value?: string;
    /** The options that the user can filter from */
    options?: Option[];
    onChange?: (value?: string) => void;
};

const FilterChip = ({
    column,
    icon,
    label,
    onChange,
    options,
    value,
}: FilterChipProps) => {
    const handleChange = (value: string | undefined) => {
        onChange?.(value);
    };

    const displayValue = useMemo(() => {
        return options?.find((opt) => opt.value === value)?.label || value;
    }, [value]);

    return (
        <DropdownMenu
            align="start"
            sideOffset={8}
            trigger={
                <div
                    className={classnames(styles.filter_chip, {
                        [styles.empty]: !value && !column,
                        [styles.filled]: !!value,
                    })}
                >
                    {!column ? <PlusCircleIcon /> : icon}
                    {value ? displayValue : column ? label : "Add Filter"}
                    {column ? <DropdownIcon /> : null}
                </div>
            }
        >
            {options?.map((opt) => (
                <MenuItem
                    active={value === opt.value}
                    key={opt.value}
                    onClick={() =>
                        handleChange(
                            value === opt.value ? undefined : opt.value
                        )
                    }
                >
                    {opt.label}
                </MenuItem>
            )) || null}
        </DropdownMenu>
    );
};
