import classNames from "classnames";
import Modal from "./Modal";
import styles from "./styles/SummaryModal.module.scss";

interface SummaryModalProps {
    header: string;
    onClose: () => void;
    metadataCols: {
        metadataTitle: string;
        metadataDetails: React.ReactNode;
    }[];
    content: React.ReactNode;
    containerClassName?: string;
    contentClassName?: string;
}

function SummaryModal({
    header,
    onClose,
    containerClassName,
    contentClassName,
    metadataCols,
    content,
}: SummaryModalProps) {
    return (
        <Modal
            header={header}
            onClose={onClose}
            className={classNames(containerClassName, styles.summaryModal)}
        >
            <div className={styles.summaryHeader}>
                {metadataCols.map(
                    ({ metadataTitle, metadataDetails }, index) => {
                        return (
                            <div
                                className={styles.summaryHeaderBlock}
                                key={index}
                            >
                                <div className={styles.summaryHeaderLabel}>
                                    {metadataTitle}
                                </div>
                                <div className={styles.summaryHeaderDetails}>
                                    {metadataDetails}
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
            <div
                className={classNames(contentClassName, styles.summaryContent)}
            >
                {content}
            </div>
        </Modal>
    );
}

export default SummaryModal;
