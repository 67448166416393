import { useCallback, useEffect, useMemo, useState } from "react";
import axios, { AxiosError } from "axios";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { toast_json_error, toast_success } from "shared/utils/toast";

import {
    DialogActions,
    DialogClose,
    DialogContent,
    DialogTitle,
} from "shared/components/Dialog";
import Button from "shared/components/Button";
import { Field, TextInput } from "shared/components/TextInput";

import styles from "./styles/ServiceAccountTokenCreateDialog.module.scss";

type FormValues = {
    service_account_id?: number;
    token_name?: string;
};

const ServiceAccountTokenCreateDialog = ({
    onSuccess,
    accountId,
}: {
    onSuccess: (token: string) => void;
    accountId?: string;
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const defaultValues = useMemo(
        () => ({
            service_account_id: accountId ? parseInt(accountId, 10) : undefined,
            token_name: "",
        }),
        [accountId]
    );

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
    } = useForm<FormValues>({
        defaultValues,
        mode: "all",
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const onSubmit: SubmitHandler<FormValues> = useCallback(async (values) => {
        setLoading(true);
        try {
            const res = await axios.post(
                "/api/v1/auth/service_account/token",
                values,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            setLoading(false);
            toast_success(`Token created.`);
            onSuccess(res.data);
            reset();
        } catch (e) {
            setLoading(false);
            toast_json_error(
                e as AxiosError<{ detail?: string }>,
                "Something went wrong"
            );
        }
    }, []);

    return (
        <DialogContent>
            <DialogTitle>Create Token</DialogTitle>
            <div className={styles.content}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="token_name"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                            <>
                                <Field label="Name" state={fieldState}>
                                    <TextInput {...field} />
                                </Field>
                            </>
                        )}
                    />
                    <DialogActions>
                        <DialogClose>
                            <Button variant="outline">Cancel</Button>
                        </DialogClose>
                        <Button
                            disabled={!isDirty || !isValid}
                            loading={loading}
                            type="submit"
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </DialogContent>
    );
};

export default ServiceAccountTokenCreateDialog;
