import LogoAndName from "./LogoAndName";
import styles from "./styles/CheckYourInbox.module.scss";
import InboxIcon from "icons/inbox.svg";
import ResendButton from "shared/components/ResendButton";

interface Props {
    email: string;
}

function CheckYourInbox({ email }: Props): JSX.Element {
    return (
        <div className={styles.gridContainer}>
            <div className={styles.container}>
                <LogoAndName />
                <div className={styles.messageTitle}>
                    <InboxIcon />
                    <span>Check Your inbox</span>
                </div>
                <div className={styles.message}>
                    {`We’ve sent the link to reset your password to ${email}.\
    Check your inbox and follow the instructions to reset your password.`}
                </div>
                <div className={styles.buttonTip}>
                    {
                        "Please check your spam folder and mark our emails as safe, so you don't miss future messages."
                    }
                </div>
                <ResendButton
                    href="/users/send_reset_pwd"
                    email={email}
                    text="Resend Password Reset Mail"
                />
            </div>
        </div>
    );
}

export default CheckYourInbox;
