import { Fragment } from "react";
import styles from "./InspectorPanel.module.scss";
import Tooltip from "shared/components/Tooltip";

type Section = {
    title: string;
    subtitle?: string;
    titleAction?: React.ReactNode;
    section: React.ReactNode;
};

type Props = {
    header: {
        icon?: React.ReactNode;
        title: string;
        actions?: React.ReactNode[];
    };
    sections: (Section | null)[];
};

function notNull<T>(val: T | null): val is T {
    return val !== null;
}

function InspectorPage({ header, sections }: Props): JSX.Element {
    const sectionsToRender: Section[] = sections.filter(notNull);
    return (
        <div
            className={styles.container}
            data-test={"inspector-container-test"}
        >
            <div className={styles.header}>
                <div
                    className={styles.titleRow}
                    data-test={"inspector-title-test"}
                >
                    {header.icon && (
                        <div className={styles.titleIcon}>{header.icon}</div>
                    )}
                    <Tooltip content={header.title}>
                        <div className={styles.title}>
                            {header.title}
                        </div>
                    </Tooltip>
                    {header.actions && (
                        <div
                            className={styles.titleActions}
                            data-test={"inspector-actions-test"}
                        >
                            {header.actions.map((action, index) => (
                                <Fragment key={index}>{action}</Fragment>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div
                className={styles.content}
                data-test={"inspector-content-test"}
            >
                {sectionsToRender.map((section, index) => (
                    <div
                        className={styles.section}
                        key={section.title}
                        data-test={`inspector-section-${index}-test`}
                    >
                        <div className={styles.sectionHeader}>
                            <div
                                className={styles.sectionTitle}
                                data-test={`inspector-section-title-test`}
                            >
                                {section.title}
                                {section.subtitle && (
                                    <div className={styles.subtitle}>
                                        {section.subtitle}
                                    </div>
                                )}
                            </div>
                            {section.titleAction && (
                                <div className={styles.sectionActions}>
                                    {section.titleAction}
                                </div>
                            )}
                        </div>
                        <div
                            className={styles.sectionBody}
                            data-test={`inspector-section-body-test`}
                        >
                            {section.section}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default InspectorPage;
