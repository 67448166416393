import FeatureMetricsFilter from "shared/components/search/FeatureMetricsFilters";
import DatasetFilter from "shared/components/search/SourceFilter";
import { Dataset, Featureset, CurrentViewState } from "shared/utils/types";
import DatasetIcon from "icons/dataset.svg";
import FeatureIcon from "icons/feature.svg";
import { FILTER_META_SEPARATOR } from "shared/constants/constants";

export enum MetricType {
    DATASET = "dataset",
    FEATURE = "feature",
}

const buildFeatureSearchConfig = (featuresets: Featureset[]) => {
    const allFeatures = featuresets.flatMap(
        ({ features, name: featureset, owner }) =>
            features.map((f) => ({ ...f, featureset, owner }))
    );
    const featureConfig = {
        icon: FeatureIcon,
        propertyName: "Feature",
        kind: {
            kind: "single-select" as const,
            options: allFeatures.map((f) => ({
                key: f.name + "_" + f.featureset,
                name: f.name,
                value: f.name + FILTER_META_SEPARATOR + f.featureset,
                metadata: { ...f, tags: "", lineage_tags: "" },
            })),
            valueCategory: "feature",
            component: FeatureMetricsFilter,
            relationship: "has-one" as const,
        },
    };
    const filterOrder = ["feature"];

    return {
        filterOrder,
        filterConfigs: {
            feature: featureConfig,
        },
    };
};

const buildDatasetSearchConfig = (datasets: Dataset[]) => {
    const allDatasets = new Set(datasets.flatMap((d) => d.name));
    const datasetNames = Array.from(allDatasets.keys()).sort();
    const datasetNamesConfig = {
        icon: DatasetIcon,
        propertyName: "Dataset",
        kind: {
            kind: "single-select" as const,
            options: datasetNames.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "dataset",
            component: DatasetFilter,
            relationship: "has-one" as const,
        },
    };
    const filterOrder = ["dataset"];

    return {
        filterOrder,
        filterConfigs: {
            dataset: datasetNamesConfig,
        },
    };
};

export const getSearchConfig = (
    entityType: MetricType,
    viewInfo: CurrentViewState
) => {
    return function () {
        switch (entityType) {
            case MetricType.DATASET:
                return buildDatasetSearchConfig(
                    viewInfo?.datasets as Dataset[]
                );
            case MetricType.FEATURE:
                return buildFeatureSearchConfig(
                    viewInfo?.featuresets as Featureset[]
                );
            default:
                return buildDatasetSearchConfig(
                    viewInfo?.datasets as Dataset[]
                );
        }
    };
};
