import { createContext } from "react";
import type { Node } from "reactflow";
import { LayoutNodesProps } from "./types";

export type DAGContextValue = {
    setSelectedSourceHandle: (sourceHandle: string) => void;
    selectedNode: Node | undefined;
    selectedSourceHandle?: string;
    ancestors: Set<string>;
    descendants: Set<string>;
    setLayoutNodes: (props: LayoutNodesProps) => void;
};

const DAGContext = createContext<DAGContextValue>({} as DAGContextValue);

export default DAGContext;
