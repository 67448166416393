import { HtmlLabel } from "@visx/annotation";
import styles from "./styles/AnnotationLabel.module.scss";
import { Dotted } from "./GraphLegends";

interface Props {
    x: number;
    y: number;
    graphWidth: number;
    label: string;
    stroke: string;
    strokeStyle?: string;
    labelValue?: string | number;
    xValue?: string | Date;
}

const CONTAINER_WIDTH = 300;
const DX = 15;

function AnnotationLabel({
    graphWidth,
    stroke,
    strokeStyle,
    xValue,
    ...restProps
}: Props): JSX.Element {
    const labelValue = !isNaN(restProps.labelValue as number)
        ? Math.round(Number(restProps.labelValue) * 100) / 100
        : restProps.labelValue;

    const x =
        restProps.x + DX + CONTAINER_WIDTH < graphWidth
            ? restProps.x + CONTAINER_WIDTH / 2 + DX
            : restProps.x - CONTAINER_WIDTH / 2 - DX;

    return (
        <HtmlLabel
            showAnchorLine={true}
            horizontalAnchor="middle"
            verticalAnchor="middle"
            containerStyle={{
                width: CONTAINER_WIDTH,
                padding: "8px 0px",
                background: "#1A1A28",
                borderRadius: 8,
            }}
            x={x}
            y={restProps.y}
        >
            <div className={styles.labelValueX}>
                {typeof xValue === "object" ? xValue.toLocaleString() : xValue}
            </div>
            <div className={styles.legendContainer}>
                <div className={styles.legend}>
                    <div className={styles.colorAndLabel}>
                        {strokeStyle && strokeStyle == "dotted" ? (
                            <Dotted color={stroke} />
                        ) : (
                            <div
                                className={styles.colorBar}
                                style={{ background: stroke }}
                            />
                        )}
                        <div className={styles.label}>{restProps.label}</div>
                    </div>
                    {labelValue != undefined ? (
                        <div className={styles.labelValue}>
                            {typeof labelValue === "string"
                                ? labelValue
                                : labelValue.toFixed(2)}
                        </div>
                    ) : null}
                </div>
            </div>
        </HtmlLabel>
    );
}

export default AnnotationLabel;
