// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fgFIEzgsy8kuolKxrdsP{animation:2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955) ZqfHuoxVBnMvSiitFQki}@keyframes ZqfHuoxVBnMvSiitFQki{0%{opacity:64%}50%{opacity:0%}80%{opacity:64%}100%{opacity:64%}}", "",{"version":3,"sources":["webpack://./src/console/shared/components/styles/LogoLoader.module.scss"],"names":[],"mappings":"AAAA,sBACI,kFAAA,CAGJ,gCACI,GACI,WAAA,CAGJ,IACI,UAAA,CAGJ,IACI,WAAA,CAGJ,KACI,WAAA,CAAA","sourcesContent":[".container {\n    animation: 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955) logo-loader;\n}\n\n@keyframes logo-loader {\n    0% {\n        opacity: 64%;\n    }\n\n    50% {\n        opacity: 0%;\n    }\n\n    80% {\n        opacity: 64%;\n    }\n\n    100% {\n        opacity: 64%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "fgFIEzgsy8kuolKxrdsP",
	"logo-loader": "ZqfHuoxVBnMvSiitFQki"
};
export default ___CSS_LOADER_EXPORT___;
