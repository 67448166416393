import VersionIcon from "icons/stack.svg";
import styles from "../styles/EntityPage.module.scss";

const Version = ({ version }: { version: string }) => {
    return (
        <div className={styles.metadataItem}>
            <div
                className={styles.metadataItemKey}
                data-test={"metadata-version-test"}
            >
                <VersionIcon />
                <span>Version</span>
            </div>
            <div
                className={styles.metadataItemValue}
                data-test={"metadata-version-value-test"}
            >
                {version}
            </div>
        </div>
    );
};

export default Version;
