import { useState } from "react";
import { SubmitHandler } from "react-hook-form";

import { RuleType, BranchPermission, BranchRule } from "shared/models";
import { useCurrentUser } from "../../../context/CurrentUser";
import { BRANCH_PERMISSION_LABELS } from "shared/constants/constants";

import styles from "./styles/BranchRuleDrawer.module.scss";

import {
    Dialog,
    DialogActions,
    DialogClose,
    DialogContent,
    DialogTitle,
} from "shared/components/Dialog";
import SelectInput, { SelectItem } from "shared/components/SelectInput";
import { MultiSelect } from "shared/components/MultiSelectInput";
import Button from "shared/components/Button";
import type { RuleFieldOption } from "./BranchRuleField";
import TagsInput from "./TagsInput";

import TagIcon from "icons/tag.svg";
import CheckVerifiedIcon from "icons/check-verified.svg";
import CubeIcon from "icons/cube.svg";
import Tooltip from "shared/components/Tooltip";

export type BranchRuleFormValue = {
    permissions: readonly RuleFieldOption[];
    ruleType: RuleType | "*";
    tags: readonly RuleFieldOption[];
    branch: string;
};

const createRuleFieldOptions = (arr: string[]) =>
    arr.map((s: string) => ({
        label: s,
        value: s,
    }));

const createPermissionOptions = (arr: BranchPermission[]) =>
    arr.map(createPermissionOption);

const createPermissionOption = (permission: BranchPermission) => ({
    label: BRANCH_PERMISSION_LABELS[permission],
    value: permission,
});

const PERMISSION_OPTS = [
    BranchPermission.VIEW_ENTITY_DEFINITION,
    BranchPermission.EDIT_ENTITY_DEFINITION,
    BranchPermission.READ_ENTITY_DATA,
    BranchPermission.WRITE_ENTITY_DATA,
].map(createPermissionOption);

function BranchRuleDrawer({
    open,
    onSubmit,
    onOpenChange,
    ...props
}: {
    open: boolean;
    onSubmit: SubmitHandler<BranchRuleFormValue>;
    onOpenChange: (open: boolean) => void;
    existing: BranchRule;
}) {
    const { branches } = useCurrentUser();
    const [branch, setBranch] = useState<BranchRuleFormValue["branch"]>(
        props.existing?.branch || "*"
    );

    const [permissions, setPermissions] = useState<
        BranchRuleFormValue["permissions"]
    >(
        createPermissionOptions(
            props.existing?.tag_permissions?.permissions || []
        )
    );

    const [ruleType, setRuleType] = useState<BranchRuleFormValue["ruleType"]>(
        () => {
            if (
                props.existing?.tag_permissions?.rule_type === RuleType.ALLOW &&
                props.existing?.tag_permissions?.tags?.includes("*")
            ) {
                return "*";
            }

            return props.existing?.tag_permissions?.rule_type || "*";
        }
    );
    const [tags, setTags] = useState<BranchRuleFormValue["tags"]>(() => {
        if (props.existing?.tag_permissions?.tags) {
            if (
                props.existing?.tag_permissions?.tags.length === 1 &&
                props.existing?.tag_permissions?.tags.includes("*")
            ) {
                return [];
            }
            return createRuleFieldOptions(
                props.existing?.tag_permissions?.tags || []
            );
        }
        return [];
    });

    const handleSubmit = () => {
        onSubmit({
            branch,
            permissions,
            ruleType,
            tags,
        });
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={styles.drawer}>
                <DialogTitle>Create Branch Rule</DialogTitle>
                <div className={styles.steps}>
                    <div className={styles.step}>
                        <div className={styles.step_title}>
                            <CubeIcon />
                            Branches
                        </div>
                        <SelectInput
                            onChange={setBranch}
                            value={branch}
                            className={styles.step_selector}
                        >
                            <SelectItem value="*">All Branches</SelectItem>
                            {branches?.map(({ name }) => (
                                <SelectItem key={name} value={`${name}`}>
                                    {name}
                                </SelectItem>
                            ))}
                        </SelectInput>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.step_title}>
                            <CheckVerifiedIcon />
                            Actions
                        </div>
                        <MultiSelect
                            isMulti
                            onChange={(v) => setPermissions(v)}
                            noOptionsMessage={({ inputValue }) =>
                                inputValue
                                    ? `No actions matching "${inputValue}"`
                                    : "All actions selected."
                            }
                            value={permissions}
                            placeholder="View Only"
                            options={PERMISSION_OPTS}
                        />
                    </div>
                    {permissions.length > 0 ? (
                        <div className={styles.step}>
                            <div className={styles.step_title}>
                                <TagIcon />
                                Scope rule to tags
                            </div>
                            <SelectInput
                                onChange={(v) => {
                                    setRuleType(v as RuleType);
                                }}
                                value={ruleType}
                                className={styles.step_selector}
                            >
                                <SelectItem value="*">All Tags</SelectItem>
                                <SelectItem value={RuleType.ALLOW}>
                                    Selected Tags
                                </SelectItem>
                                <SelectItem value={RuleType.DENY}>
                                    All but selected Tags
                                </SelectItem>
                            </SelectInput>
                            {ruleType !== "*" ? (
                                <TagsInput
                                    onChange={(v) => setTags(v)}
                                    value={tags}
                                />
                            ) : null}
                        </div>
                    ) : null}
                </div>
                {/** TODO: Test when content is very long, form actions should be sticky */}
                <DialogActions>
                    <DialogClose>
                        <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <Tooltip
                        side="top"
                        disabled={
                            !(
                                permissions.length > 0 &&
                                ruleType !== "*" &&
                                tags.length === 0
                            )
                        }
                        content="Please select at least 1 tag"
                    >
                        <Button
                            color="primary"
                            disabled={
                                permissions.length > 0 &&
                                ruleType !== "*" &&
                                tags.length === 0
                            }
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Tooltip>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default BranchRuleDrawer;
