import { useEffect, useState } from "react";
import { DataPoint } from "../../utils/prometheus";
import MultiNumberSeries, { LineConfig } from "./MultiNumberSeries";
import { NumberSeriesProps } from "./Graph";

interface Props extends NumberSeriesProps, LineConfig {
    annotationLabelFormat?: (label: number) => string;
    loadFunc?: () => Promise<DataPoint[]>;
    data?: DataPoint[];
    yTickFormat?: (current: string) => string;
    yTickAutoscale?: boolean;
    type?: string;
    isLoaded?: boolean;
    hideTooltip?: boolean;
}

function NumberSeries({
    annotationLabelFormat,
    width,
    height,
    startDate,
    endDate,
    loadFunc,
    yDomain,
    data: propData,
    yTickFormat,
    yTickAutoscale = false,
    type,
    hideTooltip,
    ...restProps
}: Props): JSX.Element {
    const [data, setData] = useState<DataPoint[]>();
    const [isLoaded, setLoaded] = useState(restProps.isLoaded);

    useEffect(() => {
        if (loadFunc) {
            setLoaded(false);
            loadFunc()
                .then((loaded: DataPoint[]) => setData(loaded))
                .catch((err) => {
                    setData([]);
                    console.error(err);
                })
                .finally(() => setLoaded(true));
        }
    }, [loadFunc]);

    useEffect(() => {
        setLoaded(restProps.isLoaded);
    }, [restProps.isLoaded]);

    useEffect(() => {
        if (propData) {
            setLoaded(true);
        }
        setData(propData);
    }, [propData]);

    return (
        <MultiNumberSeries
            width={width}
            height={height}
            startDate={startDate}
            endDate={endDate}
            yDomain={yDomain}
            annotationLabelFormat={annotationLabelFormat}
            yTickFormat={yTickFormat}
            yTickAutoscale={yTickAutoscale}
            configs={{
                data: {
                    ...restProps,
                },
            }}
            data={
                data && {
                    data,
                }
            }
            type={type}
            isLoaded={isLoaded}
            hideTooltip={hideTooltip}
        />
    );
}

export default NumberSeries;
