import styles from "./styles/UsageSettings.module.scss";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import {
    DateValue,
    convertToDate,
    toISOString,
} from "shared/components/date-picker/date-types";
import { INIT_END_DATE, INIT_START_DATE } from "shared/constants/constants";
import DateRangeControl from "shared/components/date-picker/DateRangeControl";
import classNames from "classnames";
import MultiNumberSeriesResponsive from "shared/components/graph/MultiNumberSeries";
import GraphLegends from "shared/components/graph/GraphLegends";
import {
    DataPoint,
    PromResponse,
    parsePromResponse,
} from "shared/utils/prometheus";
import DropdownMenu from "shared/components/DropdownMenu";
import Button from "shared/components/Button";
import MenuItem from "shared/components/MenuItem";
import DropdownIcon from "icons/dropdown.svg";
import { StrokeStyle } from "shared/components/graph/Graph";

const serieColors = ["#F5B93D", "#E26988", "#8981FA", "#5DB999", "#E96A46"];
enum USAGEBY {
    QUERY = "query",
    WORKFLOW = "workflow",
}

export const Metrics = () => {
    const [startDateValue, setStartDateValue] =
        useState<DateValue>(INIT_START_DATE);
    const [endDateValue, setEndDateValue] = useState<DateValue>(INIT_END_DATE);
    const startDate = convertToDate(startDateValue);
    const endDate = convertToDate(endDateValue);
    const [metricsBy, setMetricsBy] = useState<USAGEBY>(USAGEBY.QUERY);

    return (
        <div className={classNames(styles.card, styles.metrics)}>
            <div className={styles.content}>
                <div className={styles.cardHeader}>
                    <div className={styles.cardHeaderTitle}>
                        <h2>Read Units Consumed</h2>
                    </div>
                    <div className={styles.controller}>
                        <DateRangeControl
                            onDateUpdate={(fromDate, toDate) => {
                                setStartDateValue(fromDate);
                                setEndDateValue(toDate);
                            }}
                            initialFromDate={startDateValue}
                            initialToDate={endDateValue}
                            alignment="right"
                        />
                        <div>by</div>
                        <div>
                            <DropdownMenu
                                trigger={
                                    <Button
                                        icon={<DropdownIcon />}
                                        direction="row-reverse"
                                        variant="outline"
                                        size="small"
                                        color="alt"
                                    >
                                        <div className={styles.metricsBy}>
                                            {metricsBy}
                                        </div>
                                    </Button>
                                }
                            >
                                <MenuItem
                                    onClick={() => setMetricsBy(USAGEBY.QUERY)}
                                >
                                    Query
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        setMetricsBy(USAGEBY.WORKFLOW)
                                    }
                                >
                                    Workflows
                                </MenuItem>
                            </DropdownMenu>
                        </div>
                    </div>
                </div>
                <UsageGraph
                    startDate={startDate}
                    endDate={endDate}
                    by={metricsBy}
                />
            </div>
        </div>
    );
};

interface GraphProps {
    startDate: Date;
    endDate: Date;
    by: USAGEBY;
}

const queryConfig = {
    Online: {
        stroke: "#8981FA",
        lineLabel: "Online",
    },
    Offline: {
        stroke: "#5DB999",
        lineLabel: "Offline",
    },
    Total: {
        stroke: "#261584",
        lineLabel: "Total",
    },
};

function UsageGraph({ startDate, endDate, by }: GraphProps): JSX.Element {
    const [graphData, setGraphData] = useState<Record<string, DataPoint[]>>({});
    const [graphDataCopy, setGraphDataCopy] = useState<
        Record<string, DataPoint[]>
    >({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [otherToDisplay, setOtherToDisplay] = useState(0);

    const onLegendClick = (id: string) => {
        graphData?.[id].length
            ? setGraphData({ ...graphData, [id]: [] })
            : setGraphData({
                  ...graphData,
                  [id]: [...(graphDataCopy?.[id] as DataPoint[])],
              });
    };

    useEffect(() => {
        setIsLoaded(false);
        setGraphData({});
        setGraphDataCopy({});
        const totalCall = axios.post(`/billing/timeseries`, {
            start: toISOString(startDate),
            end: toISOString(endDate),
        });
        const groupCall = axios.post(`/billing/timeseries/${by}`, {
            start: toISOString(startDate),
            end: toISOString(endDate),
        });
        Promise.all([totalCall, groupCall])
            .then((resp: AxiosResponse[]) => {
                const totalData = resp[0].data;
                const groupData = resp[1].data;

                let graphRespData = Object.keys(groupData).reduce(
                    (acc: Record<string, DataPoint[]>, key) => {
                        acc[key] = parsePromResponse(
                            (groupData as Record<string, PromResponse>)[key]
                        );
                        return acc;
                    },
                    {}
                );
                if (
                    by === USAGEBY.WORKFLOW &&
                    Object.keys(graphRespData).length > 6
                ) {
                    const allSumPoints = Object.keys(graphRespData)
                        .map((key) => {
                            return {
                                key,
                                sum: graphRespData[key].reduce(
                                    (acc: number, curr: { y: number }) =>
                                        (acc += curr.y),
                                    0
                                ),
                            };
                        })
                        .sort((a, b) => b.sum - a.sum);
                    const top5sumpoints = allSumPoints
                        .slice(0, 5)
                        .map((a) => a.key);
                    setOtherToDisplay(
                        allSumPoints
                            .slice(5)
                            .reduce((acc, curr) => (acc += curr.sum), 0)
                    );
                    graphRespData = Object.entries(graphRespData)
                        .filter(([key]) => top5sumpoints.includes(key))
                        .reduce(
                            (acc, [key, value]) => ({ ...acc, [key]: value }),
                            {}
                        );
                }
                graphRespData["Total"] = parsePromResponse(totalData);
                setGraphData(graphRespData);
                setGraphDataCopy(graphRespData);
            })
            .catch((err) => {
                setGraphData({});
                setGraphDataCopy({});
                console.error(err);
            })
            .finally(() => setIsLoaded(true));
    }, [startDate, endDate, by]);

    const config =
        by === USAGEBY.QUERY
            ? queryConfig
            : Object.keys(graphData).reduce(
                  (
                      acc: Record<
                          string,
                          {
                              stroke: string;
                              lineLabel: string;
                          }
                      >,
                      curr,
                      idx
                  ) => {
                      if (curr === "Total") {
                          acc[curr] = {
                              stroke: "#261584",
                              lineLabel: "Total",
                          };
                      } else {
                          acc[curr] = {
                              stroke: serieColors[
                                  Math.floor(idx % serieColors.length)
                              ],
                              lineLabel: curr,
                          };
                      }
                      return acc;
                  },
                  {}
              );

    const graphLegendLines = Object.keys(graphDataCopy)
        .map((key) => ({
            style: "solid" as StrokeStyle,
            color: (config as Record<string, { stroke: string }>)[key]?.stroke,
            label: key,
            value: graphDataCopy[key].reduce(
                (acc: number, curr: { y: number }) => (acc += curr.y),
                0
            ),
            onClick: onLegendClick,
            disabled: !(graphData[key]?.length > 0),
        }))
        .sort((a, b) => b.value - a.value);

    if (by === USAGEBY.WORKFLOW && otherToDisplay) {
        graphLegendLines.push({
            style: "solid" as StrokeStyle,
            color: "#000000",
            label: "Others",
            value: otherToDisplay,
            onClick: () => {
                return;
            },
            disabled: true,
        });
    }

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graph}>
                <MultiNumberSeriesResponsive
                    width={200}
                    height={340}
                    startDate={startDate}
                    endDate={endDate}
                    configs={config}
                    data={graphData}
                    isLoaded={isLoaded}
                    showColumns={true}
                />
            </div>
            <div className={styles.legend}>
                <GraphLegends lines={graphLegendLines} />
            </div>
        </div>
    );
}