import Avatar from "../../components/Avatar";
import styles from "./styles/OwnerFilter.module.scss";

function OwnerFilter({ name }: { name: string }): JSX.Element {
    return (
        <div className={styles.filter}>
            <Avatar name={name} size="small" />
            <span>{name}</span>
        </div>
    );
}

export default OwnerFilter;
