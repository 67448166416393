import classnames from "classnames";
import Avatar from "./Avatar";

import styles from "./styles/UserDisplay.module.scss";

type UserDisplayProps = {
    name: string;
    /** Additional Information to display with the user. (if size is "small" this is ignored and not rendered) */
    meta?: string;
    size?: "small" | "medium" | "large";
};

function UserDisplay({ name, meta, size = "medium" }: UserDisplayProps) {
    return (
        <div className={classnames(styles.root, styles[size])}>
            <Avatar size={size === "large" ? "xlarge" : size} name={name} />
            <div className={classnames(styles.content, styles[size])}>
                <p className={styles.name}>{name}</p>
                {meta && size !== "small" ? (
                    <p className={styles.meta}>{meta}</p>
                ) : null}
            </div>
        </div>
    );
}

export default UserDisplay;
