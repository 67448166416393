import FeaturesetIcon from "icons/featureset.svg";
import styles from "./styles/FeaturesetDetail.module.scss";
import { isAutogenExtractor } from "shared/utils/utils";
import ViewDefinition from "shared/components/ViewDefinition";
import { useContext } from "react";
import {
    Description,
    Tags,
    Owner,
    ToLineage,
    AutogeneratedPill,
} from "shared/components/entityPage/metadataComponents";
import { CurrentViewContext } from "../../context/CurrentView";
import { Featureset, Feature, Extractor } from "shared/utils/types";
import InspectorPage from "../inspector/shared/InspectorPanel";
import { useUpdateEntityParams } from "../../context/CurrentView";

const NO_EXTRACTOR = "No Extractor";

function FeaturesetLoaded({
    featureset,
}: {
    featureset: Featureset;
}): JSX.Element {
    return (
        <InspectorPage
            header={{
                title: featureset.name,
                icon: <FeaturesetIcon />,
            }}
            sections={[
                {
                    title: "Metadata",
                    section: <MetadataSection featureset={featureset} />,
                },
                {
                    title: "Features",
                    titleAction: (
                        <div className={styles.expandableBadge}>
                            {featureset.features?.length}
                        </div>
                    ),
                    section: <FeaturesSection featureset={featureset} />,
                },
            ]}
        />
    );
}

function FeaturesSection({
    featureset,
}: {
    featureset: Featureset;
}): JSX.Element {
    const { featureSetMap } = useContext(CurrentViewContext);
    const featureGroups = [undefined, ...featureset.extractors].reduce(
        (acc: { [key: string]: Feature[] }, extractor?: Extractor) => {
            const extFeatures = featureset.features?.filter(
                (f) =>
                    featureSetMap?.[featureset.name + "." + f.name] ===
                    featureset.name +
                        (extractor?.name ? "." + extractor.name : "")
            );
            if (extFeatures?.length > 0) {
                acc[extractor?.name || NO_EXTRACTOR] = extFeatures;
            }
            return acc;
        },
        {}
    );

    const extractorObject = featureset.extractors.reduce(
        (acc: { [key: string]: Extractor }, extractor: Extractor) => {
            acc[extractor.name] = extractor;
            return acc;
        },
        {}
    );

    return (
        <div>
            {Object.keys(featureGroups).map((extractorName) => {
                return (
                    <FeatureGroup
                        key={extractorName}
                        extractorName={extractorName}
                        features={featureGroups[extractorName]}
                        featuresetName={featureset.name}
                        isAutogen={
                            extractorName === NO_EXTRACTOR
                                ? false
                                : isAutogenExtractor(
                                      extractorObject[extractorName]
                                  )
                        }
                        code={featureset.code}
                    />
                );
            })}
        </div>
    );
}

function FeatureGroup({
    extractorName,
    features,
    featuresetName,
    isAutogen,
    code,
}: {
    extractorName: string;
    features: Feature[];
    featuresetName: string;
    isAutogen: boolean;
    code?: string;
}): JSX.Element {
    const { updateEntityParams: navigate } = useUpdateEntityParams();
    return (
        <div className={styles.featureGroup}>
            <div className={styles.featureGroupHeader}>
                <div className={styles.featureGroupTitle}>{extractorName}</div>
                <div className={styles.featureGroupActions}>
                    <ToLineage
                        node={
                            extractorName !== NO_EXTRACTOR
                                ? `${featuresetName}.${extractorName}`
                                : featuresetName
                        }
                    />
                    {extractorName !== NO_EXTRACTOR &&
                        (isAutogen ? (
                            <AutogeneratedPill />
                        ) : (
                            <ViewDefinition
                                className={styles.viewContainer}
                                name={featuresetName}
                                code={code || ""}
                            />
                        ))}
                </div>
            </div>
            {features.map((feature) => (
                <div
                    className={styles.featureGroupContent}
                    key={feature.name}
                    onClick={() =>
                        navigate("feature", feature.name, featuresetName)
                    }
                >
                    <div className={styles.featureGroupLeft}>
                        <div className={styles.featureName}>
                            {feature.name}     
                        </div>
                    </div>
                    <div className={styles.featureGroupRight}>
                        <div className={styles.featureType}>{feature.type}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

function MetadataSection({ featureset }: { featureset: Featureset }) {
    return (
        <>
            <Description description={featureset.description} />
            <Owner owner={featureset.owner} />
            <Tags
                tags={featureset.tags}
                lineage_tags={featureset.lineage_tags}
            />
        </>
    );
}

export default FeaturesetLoaded;
