import styles from "./styles/LoadingError.module.scss";

import AlertIcon from "icons/alert-triangle.svg";
import ReloadIcon from "icons/reload.svg";

import Button from "./Button";

interface Props {
    message?: string;
    onReload: () => void;
}

function LoadingError({ message, onReload }: Props): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.alertIcon}>
                <AlertIcon width={40} height={40} viewBox="0 0 24 24" />
            </div>
            <span className={styles.wrong}>Something went wrong!</span>
            {message ? <span className={styles.message}>{message}</span> : null}
            <Button
                className={styles.button}
                type="submit"
                variant="outline"
                icon={<ReloadIcon />}
                onClick={onReload}
            >
                Try Again
            </Button>
        </div>
    );
}

export default LoadingError;
