import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";

import { branchedHome } from "shared/utils/utils";
import { Branch } from "shared/models";

import CheckCircleIcon from "icons/check-circle.svg";
import Chevron from "icons/chevron-selector-vertical.svg";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import BranchIcon from "icons/branch_v2.svg";
import styles from "./styles/BranchSelector.module.scss";
import { useCurrentUser } from "../../context/CurrentUser";

function BranchSelector(): JSX.Element {
    const [showBranchesMenu, setShowBranchesMenu] = useState(false);
    const { branchName } = useParams();

    const branchRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onMouseDown = (e: Event) => {
            if (showBranchesMenu) {
                if (
                    menuRef.current &&
                    !menuRef.current.contains(e.target as Node)
                ) {
                    setShowBranchesMenu(false);
                }
            } else {
                if (
                    branchRef.current &&
                    branchRef.current.contains(e.target as Node)
                ) {
                    setShowBranchesMenu(true);
                }
            }
        };

        document.addEventListener("mousedown", onMouseDown);
        return () => {
            document.removeEventListener("mousedown", onMouseDown);
        };
    }, [branchRef, menuRef, showBranchesMenu]);

    if (!branchName) {
        return <div className={styles.selectorContainer}></div>;
    }

    return (
        <div className={styles.selectorContainer}>
            <div
                className={classnames(styles.tierAndChevy, {
                    [styles.no_tier]: !branchName,
                })}
                ref={branchRef}
            >
                <div className={styles.selectedBranch}>
                    <div className={styles.branchIcon}>
                        <BranchIcon />
                    </div>
                    <div className={styles.branchText}>
                        {branchName || "Select a branch..."}
                    </div>
                </div>
                <div className={styles.chevronContainer}>
                    <Chevron viewBox="0 0 24 24" width="16" height="16" />
                </div>
            </div>
            {showBranchesMenu ? (
                <div ref={menuRef}>
                    <BranchesMenu onClose={() => setShowBranchesMenu(false)} />
                </div>
            ) : null}
        </div>
    );
}

function BranchesMenu({ onClose }: { onClose: () => void }): JSX.Element {
    const { branches } = useCurrentUser();
    const { branchName: activeBranch } = useParams();
    const navigate = useNavigate();

    const handleBranchClick = useCallback((branchName: string) => {
        navigate(branchedHome(branchName));
        onClose();
    }, []);

    return (
        <div className={styles.menu}>
            <div className={styles.title}>Your Branches</div>
            <div className={styles.branchesList}>
                {branches?.map((branch) => (
                    <BranchItem
                        key={branch.name}
                        branch={branch}
                        isActive={activeBranch == branch.name}
                        onClick={handleBranchClick}
                    />
                )) || null}
            </div>
            <div
                className={classnames(styles.viewAllBranches, styles.title)}
                onClick={() => {
                    navigate("/branches/");
                    onClose();
                }}
            >
                View All Branches <ArrowNarrowUpRightIcon />{" "}
            </div>
        </div>
    );
}

function BranchItem({
    branch,
    isActive,
    onClick,
}: {
    branch: Branch;
    isActive: boolean;
    onClick: (branchName: string) => void;
}): JSX.Element {
    return (
        <div
            className={classnames(styles.item, isActive && styles.active)}
            onClick={() => onClick(branch.name)}
        >
            {branch.name}
            {isActive ? (
                <CheckCircleIcon width={16} height={16} viewBox="0 0 24 24" />
            ) : null}
        </div>
    );
}

export default BranchSelector;
