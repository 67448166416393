import classnames from "classnames";

import { useCurrentUser } from "../../../../context/CurrentUser";
import { BranchRule, RuleType } from "shared/models";
import { BRANCH_PERMISSION_LABELS } from "shared/constants/constants";

import styles from "./BranchRuleDisplay.module.scss";

const BranchRuleDisplay = ({
    className,
    rule,
}: {
    className?: string;
    rule: BranchRule;
}) => {
    const { tag_permissions } = rule || {};
    const { branches } = useCurrentUser();

    return (
        <div className={classnames(styles.root, className)}>
            <div className={styles.thead}>
                <div>
                    <p>In</p>
                </div>
                <div>
                    <p>Can</p>
                </div>
                <div>
                    <p>
                        If tags{" "}
                        <b>
                            {tag_permissions?.rule_type === RuleType.DENY
                                ? "do not"
                                : ""}{" "}
                            include
                        </b>
                        :
                    </p>
                </div>
            </div>
            <div className={styles.tbody}>
                <div className={styles.list_cell}>
                    <p className={styles.tag}>
                        {rule.branch === "*"
                            ? "All Branches"
                            : branches?.find(({ name }) => name === rule.branch)
                                  ?.name || rule.branch}
                    </p>
                </div>
                <div className={styles.list_cell}>
                    {tag_permissions?.permissions?.length ? (
                        tag_permissions?.permissions.map((p, i) => (
                            <p className={styles.tag} key={i}>
                                {BRANCH_PERMISSION_LABELS[p]}
                            </p>
                        ))
                    ) : (
                        <p className={styles.tag}>View Only</p>
                    )}
                </div>
                <div className={styles.tag_cell}>
                    {tag_permissions?.tags?.length ? (
                        tag_permissions?.tags.map((t, i) => (
                            <p
                                className={
                                    t === "*" ? styles.na_tags : styles.tag
                                }
                                key={i}
                            >
                                {t === "*" ? "Any Tag" : t}
                            </p>
                        ))
                    ) : (
                        <p className={styles.na_tags}>N/A</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BranchRuleDisplay;
