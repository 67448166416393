import ELK from "elkjs";
import type { ElkNode, LayoutOptions } from "elkjs";
import { Node, Edge } from "reactflow";
import { DAGNodeData } from "./types";

const elk = new ELK();

const getNodeHeight = (data: DAGNodeData) => {
    if (data.isExpanded) {
        return ((data.children?.length || 1) + 1) * 40;
    }
    return 40;
};

export const getTreeLayout = (
    nodes: Node[],
    edges: Edge[],
    options: LayoutOptions = {}
) => {
    const isHorizontal = options?.["elk.direction"] === "RIGHT";

    const graph: ElkNode = {
        id: "root",
        layoutOptions: options,
        children: nodes.map((node) => ({
            ...node,
            targetPosition: isHorizontal ? "left" : "top",
            sourcePosition: isHorizontal ? "right" : "bottom",
            // NOTE: This is not exactly accurate, however it's very close and MUCH more performant than rendering all the nodes offscreen, measuring them and then re-calc'ing the layout with the correct sizing.
            // 7.5 is an approximate character width, and 24 represents the 12px horizontal padding
            // NOTE: Any change to the CSS styling of the DAG elements will likely mean changing the calculation below - i.e. more/less padding or changing the icon/font size will break the math
            width: 300,
            height: 75 + getNodeHeight(node.data),
            layoutOptions: {
                ...node.data.layoutOptions,
            },
        })),
        edges: edges.map(({ id, source, target, type, focusable }) => ({
            id,
            type,
            focusable,
            sources: [source],
            targets: [target],
        })),
    };

    8.2;
    return elk.layout(graph).then((layoutedGraph) => {
        let children = layoutedGraph?.children;
        if (!children) children = [];

        return {
            nodes: children.map((node) => ({
                ...node,
                position: { x: node.x, y: node.y },
            })),
            edges: layoutedGraph.edges || [],
        };
    });
};
