import LogoAndName from "./LogoAndName";
import styles from "./styles/OnYourWay.module.scss";
import CheckVerifiedIcon from "icons/check-verified.svg";
import ResendButton from "shared/components/ResendButton";

interface Props {
    email: string;
}

function OnYourWay({ email }: Props): JSX.Element {
    return (
        <div className={styles.gridContainer}>
            <div className={styles.container}>
                <LogoAndName />
                <div className={styles.messageTitle}>
                    <CheckVerifiedIcon />
                    <span>{"You're re on your way!"}</span>
                </div>
                <div className={styles.message}>
                    Check your inbox for a verification message. Please click
                    the link to confirm and complete your account setup.
                </div>
                <div className={styles.buttonTip}>
                    {
                        "Please check your spam folder and mark our emails as safe, so you don't miss future messages."
                    }
                </div>
                <ResendButton
                    email={email}
                    href="/users/resend_confirmation"
                    text="Resend Confirmation Mail"
                />
            </div>
        </div>
    );
}

export default OnYourWay;
