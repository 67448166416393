import * as DialogPrimitive from "@radix-ui/react-dialog";
import classnames from "classnames";

import styles from "./styles/Dialog.module.scss";
import { ForwardedRef, PropsWithChildren, forwardRef } from "react";

export const DialogContent = forwardRef(
    (
        {
            className,
            children,
            ...props
        }: PropsWithChildren<{ className?: string }>,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <DialogPrimitive.Portal>
                <DialogPrimitive.Overlay className={styles.overlay} />
                <DialogPrimitive.Content
                    {...props}
                    className={classnames(styles.content, className)}
                    ref={ref}
                >
                    {children}
                </DialogPrimitive.Content>
            </DialogPrimitive.Portal>
        );
    }
);

DialogContent.displayName = "DialogContent";

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = ({ children }: PropsWithChildren) => (
    <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
);

export const DialogTitle = ({ children }: PropsWithChildren) => (
    <div className={styles.title}>
        <DialogPrimitive.Title>{children}</DialogPrimitive.Title>
    </div>
);

export const DialogDescription = ({ children }: PropsWithChildren) => (
    <DialogPrimitive.Description className={styles.description}>
        {children}
    </DialogPrimitive.Description>
);

export const DialogActions = ({ children }: PropsWithChildren) => (
    <div className={styles.actions}>{children}</div>
);

export const DialogClose = ({
    children,
    ...props
}: PropsWithChildren<DialogPrimitive.DialogCloseProps>) => (
    <DialogPrimitive.Close {...props} asChild>
        {children}
    </DialogPrimitive.Close>
);
