import axios from "axios";
import classNames from "classnames";
import Button from "shared/components/Button";
import { FormEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import CheckYourInbox from "./CheckYourInbox";
import LogoAndName from "./LogoAndName";
import styles from "./styles/ForgotPasswordPage.module.scss";
import { Field, TextInput } from "shared/components/TextInput";

function ForgotPasswordPage(): JSX.Element {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const onSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        axios.post("/users/send_reset_pwd", { email });
        setSubmitted(true);
    };

    if (submitted) {
        return <CheckYourInbox email={email} />;
    }

    return (
        <div className={styles.gridContainer}>
            <div className={styles.container}>
                <div className={styles.logoAndName}>
                    <LogoAndName />
                </div>
                <div className={styles.titleRow}>
                    <span className={styles.title}>Forgot Password</span>
                    <Link to="/login" className={styles.link}>
                        Back to login
                    </Link>
                </div>
                <form onSubmit={onSubmit}>
                    <div className={classNames(styles.inputGroup)}>
                        <Field>
                            <TextInput
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Field>
                    </div>
                    <Button
                        type="submit"
                        color="primary"
                        className={styles.button}
                    >
                        Send password reset link
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default ForgotPasswordPage;
