import { ForwardedRef, forwardRef } from "react";
import classnames from "classnames";

import styles from "./styles/Button.module.scss";
import Spinner from "./Spinner";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: "neutral" | "primary" | "alt" | "danger";
    icon?: JSX.Element;
    direction?: "row" | "row-reverse";
    loading?: boolean;
    size?: "small" | "large";
    shape?: "rounded" | "pill";
    variant?: "default" | "outline" | "flat";
}

function Button(
    {
        children,
        className,
        direction = "row",
        color = "neutral",
        icon,
        loading = false,
        shape = "rounded",
        size = "large",
        type = "button",
        variant = "default",
        ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
): JSX.Element {
    return (
        <button
            {...rest}
            type={type}
            className={classnames(
                styles.root,
                styles[direction],
                styles[color],
                styles[variant],
                styles[size],
                styles[shape],
                className,
                {
                    [styles.icon]: !!icon,
                    [styles.loading]: loading,
                }
            )}
            ref={ref}
        >
            {icon || null}
            <p>{children}</p>
            <div className={classnames(styles.loader)}>
                <Spinner />
            </div>
        </button>
    );
}

Button.displayName = "Button";

export default forwardRef(Button);
