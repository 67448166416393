import { ReactElement, Fragment } from "react";
import {
    ColumnDef,
    ColumnFiltersState,
    ExpandedState,
    FilterFn,
    OnChangeFn,
    Row,
    TableState,
    Updater,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";
import classnames from "classnames";

import styles from "./styles/ReactTable.module.scss";

const fuzzyFilter: FilterFn<string> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
        itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

type ReactTableProps<T, U = string> = {
    className?: string;
    columns: ColumnDef<T, U>[];
    data: T[];
    onRowClick?: (row: Row<T>) => void;
    state?: Partial<TableState>;
    onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
    onGlobalFilterChange?: (e: string) => void;
    renderEmptyState?: (props: { filtering: boolean }) => ReactElement;
    renderSubComponent?: (props: { row: Row<T> }) => ReactElement;
    onExpandedChange?: (state: Updater<ExpandedState>) => void;
};

function ReactTable<T extends object, U = string>({
    className,
    columns,
    data,
    onRowClick,
    onExpandedChange,
    onColumnFiltersChange,
    onGlobalFilterChange,
    renderEmptyState,
    renderSubComponent,
    state,
}: ReactTableProps<T, U>) {
    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state,
        onExpandedChange,
        onColumnFiltersChange,
        onGlobalFilterChange,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <table className={classnames(styles.base, className)}>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="tr">
                        {headerGroup.headers.map((header) => (
                            <th colSpan={header.colSpan} key={header.id}>
                                {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.length ? (
                    table.getRowModel().rows.map((row) => {
                        const expanded = row.getIsExpanded();
                        return (
                            <Fragment key={row.id}>
                                <tr
                                    className={classnames({
                                        [styles.clickable]:
                                            !!onRowClick ||
                                            !!renderSubComponent,
                                    })}
                                    onClick={() => onRowClick?.(row)}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            key={cell.id}
                                            style={{
                                                width: cell.column.getSize(),
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    ))}
                                </tr>
                                {expanded && renderSubComponent ? (
                                    <tr className={styles.subcomponent}>
                                        <td
                                            colSpan={
                                                row.getVisibleCells().length
                                            }
                                        >
                                            {renderSubComponent({ row })}
                                        </td>
                                    </tr>
                                ) : null}
                            </Fragment>
                        );
                    })
                ) : (
                    <tr className={styles.subcomponent}>
                        <td colSpan={columns?.length || 1}>
                            {renderEmptyState?.({
                                filtering: Boolean(
                                    !!state?.globalFilter ||
                                        state?.columnFilters?.length
                                ),
                            }) || null}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default ReactTable;
