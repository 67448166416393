import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import axios from "axios";

import Button from "shared/components/Button";
import { Dialog } from "shared/components/Dialog";
import { Helper, TextInput } from "shared/components/TextInput";
import RoleSelector from "shared/components/RoleSelector";
import SettingsCard from "../shared/SettingsCard/SettingsCard";
import TokenConfirmDialog from "./TokenConfirmDialog";

import { toast_json_error } from "shared/utils/toast";
import { useCurrentUser } from "../../../context/CurrentUser";

import InfoSquareIcon from "icons/info-square.svg";

import styles from "./styles/TokenCreateForm.module.scss";
import Tooltip from "shared/components/Tooltip";

type CreateTokenFormValues = {
    token_name: string;
    role_id?: number;
};

function TokenCreateForm(props: { onSubmit: () => void }) {
    const [createdToken, setCreatedToken] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const { role } = useCurrentUser();

    const defaultValues = useMemo(
        () => ({
            token_name: "",
            role_id: role?.role.id,
        }),
        [role]
    );

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
    } = useForm<CreateTokenFormValues>({
        defaultValues,
    });

    const onSubmit: SubmitHandler<CreateTokenFormValues> = useCallback(
        async (values) => {
            setLoading(true);
            await axios
                .post(
                    `/api/v1/auth/token?token_name=${values.token_name}&role_id=${values.role_id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    setLoading(false);
                    reset();
                    setCreatedToken(res.data);

                    if (props.onSubmit) {
                        return props.onSubmit();
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    toast_json_error(e, "Something went wrong.");
                });
        },
        [props.onSubmit]
    );

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SettingsCard
                    Footer={
                        <div className={styles.form_footer}>
                            <Helper type="neutral">
                                <InfoSquareIcon />
                                Tokens you create will inherit your role.
                            </Helper>
                            <Tooltip
                                disabled={isValid}
                                content={
                                    !isValid ? `Token Name is required.` : ""
                                }
                            >
                                <Button
                                    disabled={!isDirty || !isValid}
                                    color="primary"
                                    size="small"
                                    loading={loading}
                                    type="submit"
                                >
                                    Create Token
                                </Button>
                            </Tooltip>
                        </div>
                    }
                >
                    <div className={styles.input_row}>
                        <Controller
                            control={control}
                            name="token_name"
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextInput {...field} placeholder="Name" />
                            )}
                        />
                        <Controller
                            control={control}
                            name="role_id"
                            rules={{ required: true }}
                            render={({ field }) => (
                                <RoleSelector disabled {...field} />
                            )}
                        />
                    </div>
                </SettingsCard>
            </form>
            <Dialog
                open={createdToken !== undefined}
                onOpenChange={(open) =>
                    setCreatedToken((prev) => (!open ? undefined : prev))
                }
            >
                <TokenConfirmDialog token={createdToken} />
            </Dialog>
        </>
    );
}

export default TokenCreateForm;
