import axios from "axios";
import { FormEventHandler, useEffect, useState } from "react";
import styles from "./styles/ResendButton.module.scss";

interface Props {
    text: string;
    email: string;
    href: string;
    wait?: number; // in seconds, default to 30secons
}

const WAIT_DEFAULT = 30;

function ResendButton({ email, text, href, ...rest }: Props): JSX.Element {
    const [submitted, setSubmitted] = useState(false);
    const [wait, setWait] = useState<number>();

    const onSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        axios.post(href, {
            email,
        });

        const wait = rest.wait ? rest.wait : WAIT_DEFAULT;
        setWait(wait);
        setSubmitted(true);
    };
    useEffect(() => {
        if (submitted && wait) {
            if (wait > 1) {
                const timeoutId = setTimeout(() => setWait(wait - 1), 1000);
                return () => clearTimeout(timeoutId);
            } else {
                const timeoutId = setTimeout(() => setSubmitted(false), 1000);
                return () => clearTimeout(timeoutId);
            }
        }
    }, [submitted, wait]);

    return (
        <form onSubmit={onSubmit} className={styles.form}>
            <button className={styles.button} disabled={submitted}>
                {submitted ? `Wait ${wait}s before trying to resend` : text}
            </button>
        </form>
    );
}

export default ResendButton;
