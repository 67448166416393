// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WHETjWEz_M1sXuvOxS9K{position:relative}.rz5E7Fi9s0qSv4ZPugAC{display:flex;align-items:center;gap:8px}.rz5E7Fi9s0qSv4ZPugAC svg{flex-grow:0;flex-shrink:0}", "",{"version":3,"sources":["webpack://./src/console/shared/components/date-picker/styles/DateControl.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,0BACI,WAAA,CACA,aAAA","sourcesContent":[".container {\n    position: relative;\n}\n\n.innerContainer {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    & svg {\n        flex-grow: 0;\n        flex-shrink: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WHETjWEz_M1sXuvOxS9K",
	"innerContainer": "rz5E7Fi9s0qSv4ZPugAC"
};
export default ___CSS_LOADER_EXPORT___;
