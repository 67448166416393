// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UlClxiOTMafLdoGHCS5w{display:flex;gap:8px;align-items:center}", "",{"version":3,"sources":["webpack://./src/console/shared/components/search/styles/OwnerFilter.module.scss","webpack://./src/console/shared/components/search/styles/_search.scss"],"names":[],"mappings":"AAEA,sBCsBI,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":["@use \"./search\";\n\n.filter {\n    @include search.filter;\n}\n","@use \"shared/styles/common\" as *;\n@use \"../../../styles/menu\";\n\n@mixin select-dropdown {\n    position: absolute;\n    top: calc(32px + 10px);\n    left: 0;\n\n    min-width: 240px;\n\n    @include menu.menu;\n\n    .title {\n        font-weight: 600;\n    }\n}\n\n@mixin hover {\n    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;\n    border-radius: 8px;\n    cursor: pointer;\n}\n\n@mixin filter {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "UlClxiOTMafLdoGHCS5w"
};
export default ___CSS_LOADER_EXPORT___;
