// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes rwtCCUHSDBjZuYjd7_c1{0%{background-position:-1200px 0}100%{background-position:1200px 0}}.uTGIYwwjc1I_rJWluKPr{background-color:#fff;background-image:linear-gradient(to right, #ffffff 0%, #edeef1 20%, #f6f7f8 40%, #ffffff 100%);background-repeat:no-repeat;background-size:1200px 100%;animation-duration:1s;animation-fill-mode:forwards;animation-iteration-count:infinite;animation-name:rwtCCUHSDBjZuYjd7_c1;animation-timing-function:linear;margin:10px 5px}", "",{"version":3,"sources":["webpack://./src/console/shared/components/graph/styles/Loader.module.scss"],"names":[],"mappings":"AACA,gCACI,GACE,6BAAA,CAGF,KACE,4BAAA,CAAA,CAIJ,sBACE,qBAAA,CACA,8FAAA,CAOA,2BAAA,CACA,2BAAA,CACA,qBAAA,CACA,4BAAA,CACA,kCAAA,CACA,mCAAA,CACA,gCAAA,CACA,eAAA","sourcesContent":["\n@keyframes placeholderShimmer {\n    0% {\n      background-position: -1200px 0;\n    }\n  \n    100% {\n      background-position: 1200px 0;\n    }\n  }\n  \n  .shimmerBG {\n    background-color: #ffffff;\n    background-image: linear-gradient(\n      to right,\n      #ffffff 0%,\n      #edeef1 20%,\n      #f6f7f8 40%,\n      #ffffff 100%\n    );\n    background-repeat: no-repeat;\n    background-size: 1200px 100%;\n    animation-duration: 1s;\n    animation-fill-mode: forwards;\n    animation-iteration-count: infinite;\n    animation-name: placeholderShimmer;\n    animation-timing-function: linear;\n    margin: 10px 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shimmerBG": "uTGIYwwjc1I_rJWluKPr",
	"placeholderShimmer": "rwtCCUHSDBjZuYjd7_c1"
};
export default ___CSS_LOADER_EXPORT___;
