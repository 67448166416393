import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

import { toast_json_error, toast_success } from "../../../shared/utils/toast";

import Button from "../../../shared/components/Button";
import { Field, TextInput } from "../../../shared/components/TextInput";
import {
    Dialog,
    DialogActions,
    DialogClose,
    DialogContent,
    DialogTitle,
    DialogTrigger,
} from "../../../shared/components/Dialog";
import SettingsCard from "../shared/SettingsCard/SettingsCard";
import QRCode from "react-qr-code";

import styles from "./styles/UpdatePasswordForm.module.scss";
import useSWR from "swr";
import { axiosFetcher } from "../../../shared/utils/utils";
import EmptyState from "../../../shared/components/EmptyState";

function ConfigureMFA() {
    const [open, setOpen] = useState(false);
    const { data: isMFAEnabled } = useSWR(
        ["get", "/users/get_mfa_config"],
        axiosFetcher
    );
    return (
        <SettingsCard>
            <div className={styles.content}>
                <div className={styles.title}>
                    <p>Two Factor Authentication</p>
                    <p>Configure 2FA for your account</p>
                </div>
                <div className={styles.input_wrapper}>
                    <Dialog open={open} onOpenChange={setOpen}>
                        <DialogTrigger>
                            <Button color="primary">
                                {isMFAEnabled ? "Configure 2FA" : "Enable 2FA"}
                            </Button>
                        </DialogTrigger>
                        <ConfigureMFADialog onSuccess={() => setOpen(false)} />
                    </Dialog>
                </div>
            </div>
        </SettingsCard>
    );
}

type MFAConfig = {
    otp: string;
};

const defaultValues = {
    otp: "",
};

const schema = yup
    .object({
        otp: yup.string().required("This is required"),
    })
    .required();

function OtpQrCode() {
    const [isLoading, setLoading] = useState(false);
    const [url, setURL] = useState("");

    useEffect(() => {
        axios
            .get("/users/get_authenticator_uri")
            .then((resp) => {
                setURL(resp.data);
            })
            .catch((e) => {
                toast_json_error(e, "Some error occurred");
            })
            .finally(() => setLoading(false));
    }, []);

    return isLoading ? (
        <EmptyState loading={true} />
    ) : (
        <>
            <div className={styles.content}>
                <div>Use an authenticator app from your phone to scan</div>
                <div>
                    <QRCode
                        size={256}
                        style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                        }}
                        value={url}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>
        </>
    );
}

function ConfigureMFADialog({ onSuccess }: { onSuccess: () => void }) {
    const [loading, setLoading] = useState(false);
    const { data: isMFAEnabled } = useSWR(
        ["get", "/users/get_mfa_config"],
        axiosFetcher
    );

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
    } = useForm<MFAConfig>({
        defaultValues,
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    const onDisable = useCallback(() => {
        axios
            .post("/users/disable_mfa")
            .then(() => {
                toast_success("2FA disabled");
                onSuccess();
            })
            .catch((e) => {
                toast_json_error(e, "Failed to update configuration");
            });
    }, []);

    const onSubmit: SubmitHandler<MFAConfig> = useCallback(({ otp }) => {
        setLoading(true);

        axios
            .post("/users/enable_mfa", {
                otp,
            })
            .then(() => {
                setLoading(false);
                toast_success("MFA configuration updated");
                reset();
                onSuccess();
            })
            .catch((e) => {
                setLoading(false);
                toast_json_error(e, "Failed to update configuration");
            });
    }, []);

    return (
        <DialogContent>
            <DialogTitle>{isMFAEnabled && "Re-"}Configure 2FA</DialogTitle>
            <div>
                Use a phone app like Google Authenticator, 1Password, Authy, or
                Microsoft Authenticator, etc. to get 2FA codes when prompted
                during sign-in.
            </div>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <>
                    <OtpQrCode />
                    <Controller
                        control={control}
                        name="otp"
                        render={({ field, fieldState }) => (
                            <Field
                                label="Verify the code from the app"
                                state={fieldState}
                            >
                                <TextInput type="text" {...field} />
                            </Field>
                        )}
                    />
                </>
                <DialogActions>
                    {isMFAEnabled && (
                        <Button color="danger" onClick={onDisable}>
                            Disable 2FA
                        </Button>
                    )}
                    <DialogClose>
                        <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <Button
                        disabled={!isDirty || !isValid}
                        loading={loading}
                        color="primary"
                        type="submit"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    );
}

export default ConfigureMFA;
