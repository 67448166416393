import classnames from "classnames";

import styles from "./styles/Spinner.module.scss";

function Spinner({
    className,
    size = "small",
}: {
    className?: string;
    size?: "small" | "medium" | "large";
}) {
    return <div className={classnames(styles.root, styles[size], className)} />;
}

export default Spinner;
