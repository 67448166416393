import Logo from "icons/logo_full.svg";
import styles from "./styles/LogoAndName.module.scss";

function LogoAndName(): JSX.Element {
    return (
        <div className={styles.container}>
            <Logo width={"6rem"} height={"4rem"} />
        </div>
    );
}

export default LogoAndName;
