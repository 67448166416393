import {
    GraphProps,
    Gridline,
    XDateAxis,
    YAxis,
    xAccessor,
    yAccessor,
} from "./Graph";
import { LineSeries, XYChart } from "@visx/xychart";

interface Props extends GraphProps {
    yMin?: number;
    yMax?: number;
}

function EmptyGraph({
    width,
    height,
    yMin,
    yMax,
    startDate,
    endDate,
}: Props): JSX.Element {
    return (
        <XYChart
            width={width}
            height={height}
            xScale={{
                type: "time",
                domain: [startDate, endDate],
            }}
            yScale={{ type: "linear", domain: [yMin || 0, yMax || 1] }}
        >
            <XDateAxis />
            <YAxis />
            <Gridline />
            <LineSeries
                dataKey="emptyGraph"
                data={[
                    { x: startDate, y: NaN },
                    { x: endDate, y: NaN },
                ]}
                xAccessor={xAccessor}
                yAccessor={yAccessor}
            />
        </XYChart>
    );
}

export default EmptyGraph;
