// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClHhxsw7GAA3YE3MOez2{display:flex;flex-direction:column;align-items:stretch;gap:0}.rX9SxNYg_Y3EYJBsX_2U{display:flex;align-items:center;gap:1rem;flex-shrink:0}.lMI1l4vA96sSFMwW8bWQ>svg{opacity:.64}", "",{"version":3,"sources":["webpack://./src/console/features/settings/OrganizationServiceAccountSettings/styles/OrganizationServiceAccountSettings.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,KAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,QAAA,CACA,aAAA,CAIA,0BACI,WAAA","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    gap: 0;\n}\n\n.actions {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n    flex-shrink: 0;\n}\n\n.row_actions {\n    &>svg {\n        opacity: 0.64;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ClHhxsw7GAA3YE3MOez2",
	"actions": "rX9SxNYg_Y3EYJBsX_2U",
	"row_actions": "lMI1l4vA96sSFMwW8bWQ"
};
export default ___CSS_LOADER_EXPORT___;
