import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import DatasetIcon from "icons/dataset.svg";
import ArrowIcon from "icons/arrow-narrow-up-right.svg";
import { stringCompare, branchedMetricsLink } from "shared/utils/utils";
import PipelineIcon from "icons/pipeline.svg";
import styles from "./styles/DatasetDetail.module.scss";
import {
    convertToDate,
    toISOString,
} from "shared/components/date-picker/date-types";
import { INIT_END_DATE, INIT_START_DATE } from "shared/constants/constants";
import {
    DataPoint,
    PromResponse,
    parsePromResponse,
} from "shared/utils/prometheus";
import {
    FULL_GRAPH_HEIGHT,
    FULL_GRAPH_WIDTH,
} from "shared/components/graph/Graph";
import Table, { Column } from "shared/components/Table";
import MultiNumberSeries from "shared/components/graph/MultiNumberSeries";
import {
    SourcedFrom,
    Version,
    Description,
    Tags,
    Owner,
    ToLineage,
    SchemaSection,
    ToInspect,
    IngestionSection,
} from "shared/components/entityPage/metadataComponents";
import { DOC_LINKS } from "shared/constants/docs";
import { Dataset, Pipeline, Source } from "shared/utils/types";
import ViewDefinition from "shared/components/ViewDefinition";
import { CurrentViewContext } from "../../context/CurrentView/CurrentViewProvider";
import InspectorPage from "../inspector/shared/InspectorPanel";
import { useUpdateEntityParams } from "../../context/CurrentView";

function DatasetLoaded({ dataset }: { dataset: Dataset }): JSX.Element {
    const startDate = convertToDate(INIT_START_DATE);
    const endDate = convertToDate(INIT_END_DATE);

    const { sourceInfo } = useContext(CurrentViewContext);
    const datasetSourceKeys = Object.keys(sourceInfo || {}).filter((key) =>
        sourceInfo?.[key].datasets.find((ds) => ds.name === dataset.name)
    );
    const datasetSources = datasetSourceKeys?.map(
        (dkey) => sourceInfo?.[dkey].metadata
    );

    return (
        <InspectorPage
            header={{
                title: dataset.name,
                icon: <DatasetIcon />,
                actions: [
                    <ToLineage node={dataset.name} key="to-lineage" />,
                    <ToInspect datasetName={dataset.name} key="to-inspect" />,
                    <ViewDefinition
                        code={dataset.code || ""}
                        name={dataset.name}
                        label={undefined}
                        key="view-def"
                    />,
                ],
            }}
            sections={[
                {
                    title: "Metadata",
                    section: (
                        <MetadataSection
                            dataset={dataset}
                            sources={datasetSources as Source[]}
                        />
                    ),
                },
                dataset.is_source_dataset
                    ? {
                          title: "Ingestion",
                          titleAction: (
                              <Link
                                  className={styles.metadataTitleLink}
                                  to={`./ingestion?dataset=${dataset.name}`}
                              >
                                  All Jobs
                                  <ArrowIcon />
                              </Link>
                          ),
                          section: (
                              <IngestionSection
                                  sources={datasetSources as Source[]}
                                  datasetName={dataset.name}
                              />
                          ),
                      }
                    : null,
                {
                    title: "Schema",
                    section: <SchemaSection schema={dataset.dsschema} />,
                },
                {
                    title: "Row Updates",
                    subtitle: "Last 24h",
                    titleAction: (
                        <Link
                            className={styles.metadataTitleLink}
                            to={`./metrics?dataset=${dataset.name}`}
                        >
                            View Metrics
                            <ArrowIcon />
                        </Link>
                    ),
                    section: (
                        <GraphSection
                            datasetName={dataset.name}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    ),
                },
                dataset.outgoing_pipelines?.length > 0
                    ? {
                          title: "Outgoing Pipelines",
                          section: (
                              <PipelinesSection
                                  pipelines={dataset.outgoing_pipelines}
                                  datasetOwner={dataset.owner}
                              />
                          ),
                      }
                    : null,
            ]}
        />
    );
}

function MetadataSection({
    dataset,
    sources,
}: {
    dataset: Dataset;
    sources?: Source[];
}) {
    return (
        <>
            <Version version={(dataset.version || "0").toString()} />
            {sources?.length && (
                <SourcedFrom
                    sources={sources}
                    datasets={dataset.pipelines?.flatMap(
                        (p) => p.input_dataset_names
                    )}
                    isSourced={dataset.is_source_dataset}
                />
            )}
            <Description description={dataset.description} />
            <Owner owner={dataset.owner} />
            <Tags tags={dataset.tags} lineage_tags={dataset.lineage_tags} />
        </>
    );
}

interface GraphSectionProps {
    startDate: Date;
    endDate: Date;
    datasetName: string;
}

interface MetricsResponse {
    rows_update: PromResponse;
    backlog: PromResponse;
    event_ts_seconds: PromResponse;
}

interface MetricsData {
    rowsUpdate: DataPoint[];
    backlog: DataPoint[];
    eventTime: DataPoint[];
}

function GraphSection({
    startDate,
    endDate,
    datasetName,
}: GraphSectionProps): JSX.Element {
    const { branchName } = useParams();
    const [data, setData] = useState<MetricsData>();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        axios
            .post(branchedMetricsLink(branchName, "dataset"), {
                dataset_name: datasetName,
                start: toISOString(startDate),
                end: toISOString(endDate),
            })
            .then((resp: AxiosResponse<MetricsResponse>) => {
                const d = {
                    rowsUpdate: parsePromResponse(resp.data.rows_update),
                    backlog: parsePromResponse(resp.data.backlog),
                    eventTime: parsePromResponse(
                        resp.data.event_ts_seconds
                    ).map((d) => ({
                        x: d.x,
                        y: d.y,
                    })),
                };

                setData(d);
            })
            .finally(() => setIsLoaded(true));
    }, [startDate, endDate]);

    return (
        <div className={styles.graphSection}>
            <div>
                <div className={styles.content}>
                    <MultiNumberSeries
                        configs={{
                            rows: { stroke: "#8474DC", lineLabel: "Updates" },
                        }}
                        data={data && { rows: data.rowsUpdate }}
                        annotationLabelFormat={(v) =>
                            `${Math.round(v * 100) / 100}`
                        }
                        width={FULL_GRAPH_WIDTH}
                        height={FULL_GRAPH_HEIGHT}
                        startDate={startDate}
                        endDate={endDate}
                        isLoaded={isLoaded}
                        hideTooltip
                    />
                </div>
            </div>
        </div>
    );
}

function PipelinesSection({
    pipelines,
    datasetOwner,
}: {
    pipelines: Pipeline[];
    datasetOwner: string;
}): JSX.Element {
    const { updateEntityParams } = useUpdateEntityParams();
    const columns: Column<Pipeline>[] = [
        {
            header: "Name",
            renderFunc: (p) => (
                <div>
                    <PipelineIcon />
                    <span>{p.name}</span>
                </div>
            ),
            sortFunc: (x, y) => stringCompare(x.name, y.name),
        },
        {
            header: "Dataset",
            renderFunc: (p) => (
                <div
                    className={styles.cursorPointer}
                    onClick={() =>
                        updateEntityParams("dataset", p.dataset_name)
                    }
                >
                    <DatasetIcon />
                    <span>{p.dataset_name}</span>
                </div>
            ),
            sortFunc: (x, y) =>
                stringCompare(x.owner || datasetOwner, y.owner || datasetOwner),
        },
        {
            header: "Code",
            renderFunc: (p) =>
                p.code ? <ViewDefinition code={p.code} name={p.name} /> : null,
        },
    ];

    return (
        <Table
            className={styles.pipelines}
            data={pipelines}
            columns={columns}
            rowKeyFunc={(p) => p.name}
            dataUnit="Pipeline"
            emptyText="This dataset does not have outgoing pipelines"
            learnMore={DOC_LINKS.pipelines}
        />
    );
}

export default DatasetLoaded;
