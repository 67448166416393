import classnames from "classnames";

import Spinner from "../Spinner";
import styles from "./EmptyState.module.scss";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";

function EmptyState({
    className,
    icon,
    loading,
    spinnerSize = "medium",
    text = "Nothing to show.",
    learnMore,
}: {
    className?: string;
    icon?: JSX.Element;
    loading?: boolean;
    spinnerSize?: "small" | "medium";
    text?: string;
    learnMore?: string;
}) {
    return (
        <div className={classnames(styles.root, className)}>
            {!loading ? (
                icon || null
            ) : (
                <Spinner className={styles.spinner} size={spinnerSize} />
            )}
            {!loading ? (
                <div className={styles.text}>
                    <p>{text}</p>
                    {learnMore ? (
                        <a
                            href={learnMore}
                            target="_blank"
                            className={styles.link}
                            rel="noreferrer"
                        >
                            Learn More <ArrowNarrowUpRightIcon />
                        </a>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}

export default EmptyState;
