import classnames from "classnames";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { FormEventHandler, useState } from "react";
import { pwdValidationRules } from "shared/constants/constants";
import LogoAndName from "./LogoAndName";
import styles from "./styles/ResetPasswordPage.module.scss";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast_json_error } from "shared/utils/toast";

function ResetPasswordPage(): JSX.Element {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        setSubmitting(true);
        axios
            .post("/users/reset_pwd", {
                token: searchParams.get("token"),
                new_password: password,
            })
            .then(() => {
                navigate("/login");
                setSubmitting(false);
            })
            .catch((e) => {
                toast_json_error(e, "Failed to reset password");
                setSubmitting(false);
            });
    };

    return (
        <div className={styles.gridContainer}>
            <div className={styles.container}>
                <div className={styles.logoAndName}>
                    <LogoAndName />
                </div>
                <div className={styles.titleRow}>
                    <span className={styles.title}>Reset Password</span>
                </div>
                <form onSubmit={onSubmit}>
                    <div className={styles.inputGroup}>
                        <label htmlFor="newPassword">New Password</label>
                        <Input
                            id="newPassword"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            validationRules={pwdValidationRules}
                        />
                    </div>
                    <div
                        className={classnames(
                            styles.inputGroup,
                            styles.confirmPassword
                        )}
                    >
                        <label htmlFor="confirmPassword">
                            Confirm New Password
                        </label>
                        <Input
                            id="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            validationRules={[
                                {
                                    checkFn: (cp) => cp == password,
                                    message: "Passwords don't match",
                                },
                            ]}
                        />
                    </div>
                    <Button
                        type="submit"
                        color="primary"
                        className={styles.button}
                        disabled={submitting}
                    >
                        Confirm new password
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default ResetPasswordPage;
