import { useCallback, useEffect, useMemo, useState } from "react";
import axios, { AxiosError } from "axios";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { toast_json_error, toast_success } from "shared/utils/toast";
import { useCurrentUser } from "../../../context/CurrentUser";

import {
    DialogActions,
    DialogClose,
    DialogContent,
    DialogTitle,
} from "shared/components/Dialog";
import Button from "shared/components/Button";
import RoleSelector from "shared/components/RoleSelector";
import { Field, TextInput } from "shared/components/TextInput";

import styles from "./styles/CreateServiceAccountDialog.module.scss";
import { ServiceAccount } from "shared/models";

type FormValues = {
    service_account_name?: string;
    service_account_id?: number;
    role_id?: number;
};

const CreateServiceAccountDialog = ({
    onSuccess,
    updating,
}: {
    onSuccess: () => void;
    updating?: ServiceAccount;
}) => {
    const { user: currentUser } = useCurrentUser();
    const [loading, setLoading] = useState<boolean>(false);

    const defaultValues = useMemo(
        () => ({
            service_account_name: updating?.name || "",
            service_account_id: updating?.id,
            role_id: updating?.role.id || undefined,
            created_by: updating?.owner || currentUser?.email || "",
        }),
        [currentUser, updating]
    );

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
    } = useForm<FormValues>({
        defaultValues,
        mode: "all",
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const onSubmit: SubmitHandler<FormValues> = useCallback(
        async (values) => {
            setLoading(true);
            try {
                const method = updating ? "put" : "post";

                await axios[method](`/api/v1/auth/service_account`, values, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                setLoading(false);
                toast_success(
                    `${values.service_account_name} ${
                        updating ? "updated" : "created"
                    }.`
                );
                onSuccess();
                reset();
            } catch (e) {
                setLoading(false);
                toast_json_error(
                    e as AxiosError<{ detail?: string }>,
                    "Something went wrong"
                );
            }
        },
        [currentUser, updating]
    );

    return (
        <DialogContent>
            <DialogTitle>
                {!updating ? "Create" : "Update"} Service Account
            </DialogTitle>
            <div className={styles.content}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="service_account_name"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                            <>
                                <Field label="Name" state={fieldState}>
                                    <TextInput {...field} />
                                </Field>
                            </>
                        )}
                    />
                    <Controller
                        name="role_id"
                        control={control}
                        rules={{ required: "Role is required." }}
                        render={({ field, fieldState }) => (
                            <>
                                <Field label="Select a role" state={fieldState}>
                                    <RoleSelector {...field} />
                                </Field>
                            </>
                        )}
                    />
                    <DialogActions>
                        <DialogClose>
                            <Button variant="outline">Cancel</Button>
                        </DialogClose>
                        <Button
                            disabled={!isDirty || !isValid}
                            loading={loading}
                            type="submit"
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </DialogContent>
    );
};

export default CreateServiceAccountDialog;
