import LineageSection from "shared/components/LineageSection";
import { LINEAGE_NAV } from "shared/constants/navigation";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ConsolePage from "../console-page/ConsolePage";

function LineagePage(): JSX.Element {
    const [searchParams] = useSearchParams();
    const [nodeId, setNodeId] = useState(searchParams.get("node"));
    useEffect(() => {
        setNodeId(searchParams.get("node"));
    }, [searchParams.get("node")]);
    return (
        <ConsolePage
            header={{
                title: LINEAGE_NAV.title,
                icon: LINEAGE_NAV.icon,
            }}
            content={
                <LineageSection
                    focusedNodes={nodeId ? [{ id: nodeId }] : undefined}
                />
            }
        />
    );
}

export default LineagePage;
