import styles from "./styles/IngestionPage.module.scss";
import DatasetIcon from "icons/dataset.svg";
import {
    stringCompare,
    branchedLink,
    axiosFetcherWithParams,
    elapsedTimeFormatterHMS,
    getElapsedTime,
    nFormatter,
    valueCompare,
} from "shared/utils/utils";
import { useParams, useSearchParams } from "react-router-dom";
import SearchBar from "shared/components/search/SearchBar";
import TimerIcon from "icons/timer.svg";
import { useCallback } from "react";
import { Config, defaultVerb, Filter } from "shared/components/search/filters";
import Table, { Column } from "shared/components/Table"
import { BATCH_SINK_RUNS_NAV } from "shared/constants/navigation";
import {
    BatchSinkStatus,
    BatchSinkRun,
} from "../../shared/utils/types";
import {
    IngestionDataset,
    BatchSinkIcon
} from "shared/components/entityPage/metadataComponents/Ingestion";
import useSWR from "swr";
import DatasetFilter from "shared/components/search/SourceFilter";
import EmptyState from "shared/components/EmptyState";
import ConsolePage from "../console-page/ConsolePage";
import { VERSION_SEPARATOR } from "shared/constants/constants";

const BatchSinksPage = () => {
    const { branchName } = useParams();
    const [searchParams] = useSearchParams();
    const dataset = searchParams.get("dataset");

    const apiPath = `batch_sink_stats`;

    const { data, isLoading } = useSWR(
        ["post", branchedLink(branchName, apiPath), { dataset }],
        axiosFetcherWithParams,
        {
            refreshInterval: 120000,
        }
    );

    if (isLoading || !data || !data.stats) {
        return (
            <ConsolePage
                header={{
                    title: BATCH_SINK_RUNS_NAV.title,
                    icon: BATCH_SINK_RUNS_NAV.icon,
                }}
                content={
                    <div className={styles.loaderContainter}>
                        <EmptyState
                            loading={isLoading}
                            text="No batch sink jobs found"
                        />
                    </div>
                }
            />
        );
    }

    return <BatchSinksTableLoaded batchSinks={data.stats} />;
};

function BatchSinksTableLoaded({
    batchSinks: allDatasetBatchSinks,
}: {
    batchSinks: BatchSinkRun["stats"];
}): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchConfig = buildSearchConfig(
        allDatasetBatchSinks,
    );

    const defaultFilters: Filter[] = [];
    searchParams.forEach((value, key) => {
        if (searchConfig.filterConfigs[key]) {
            defaultFilters.push({
                key,
                value: [value],
                verb: defaultVerb(searchConfig.filterConfigs[key], [value]),
            });
        }
    });

    const onDatasetClick = useCallback(
        (clickedDataset: string) => {
            const datasetParam = searchParams.get("dataset");
            if (datasetParam === clickedDataset) {
                return;
            }

            const newSearchParams = new URLSearchParams();
            newSearchParams.set("dataset", clickedDataset);
            setSearchParams(newSearchParams);
        },
        [searchParams, setSearchParams]
    );


    const columns: Column<BatchSinkRun["stats"][number]>[] = [
        {
            header: "Started At",
            renderFunc: (ds) => (
                <div>{new Date(ds.started_at.micros/1000).toLocaleString()}</div>
            ),
            sortFunc: (x, y) => valueCompare(x.started_at.micros, y.started_at.micros),
        },
        {
            header: "Dataset",
            renderFunc: (ds) => (
                <IngestionDataset
                    dataset={ds.dataset_name}
                    onClick={() =>
                        onDatasetClick(
                            ds.dataset_name.split(VERSION_SEPARATOR)[0]
                        )
                    }
                />
            ),
            sortFunc: (x, y) => stringCompare(x.dataset_name, y.dataset_name),
        },
        {
            header: "Table Suffix",
            renderFunc: (ds) => ds.table_suffix,
            sortFunc: (x, y) => stringCompare(x.table_suffix, y.table_suffix),
        },
        {
            header: "Status",
            renderFunc: (ds) => (
                <StatusPill
                    status={ds.status}
                />
            ),
            sortFunc: (x, y) => stringCompare(x.status, y.status),
        },
        {
            header: "Inserts",
            renderFunc: (ds) =>
                    <div className={styles.rowsInfo}>
                        <div className={styles.rowCount}>
                            {nFormatter(ds.num_inserts, 3)}
                        </div>
                    </div>
        },
        {
            header: "Deletes",
            renderFunc: (ds) =>
                    <div className={styles.rowsInfo}>
                        <div className={styles.rowCount}>
                            {nFormatter(ds.num_deletes, 3)}
                        </div>
                    </div>
        },
        {
            header: "Elapsed",
            renderFunc: (ds) => {
                return ds.status !== BatchSinkStatus.COMPLETED ? (
                    <div className={styles.timer}>
                        <TimerIcon />
                        {elapsedTimeFormatterHMS(
                            getElapsedTime(
                                ds.started_at.micros/1000,
                                true
                            )
                        )}
                    </div>
                ) : (
                    <div className={styles.timer}>
                        <TimerIcon />
                        {elapsedTimeFormatterHMS(
                            getElapsedTime(
                                ds.started_at.micros/1000,
                                true,
                                ds.finished_at.micros/1000
                            )
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <ConsolePage
            header={{
                title: BATCH_SINK_RUNS_NAV.title,
                icon: BATCH_SINK_RUNS_NAV.icon,
            }}
            subheader={
                <SearchBar
                    config={searchConfig}
                    onSearch={(text, filters, force) => {
                        filterDatasetBatchSinks(
                            filters,
                            setSearchParams,
                            force
                        );
                    }}
                    searchHide
                    defaultFilters={defaultFilters}
                />
            }
            content={
                <Table
                    className={styles.table}
                    data={allDatasetBatchSinks}
                    columns={columns}
                    rowKeyFunc={(ds) =>
                        ds.job_id +
                        "_" +
                        ds.dataset_name +
                        "_" +
                        ds.started_at
                    }
                    dataUnit="Batch Sink"
                    emptyText={
                        allDatasetBatchSinks.length > 0
                            ? "No batch sink jobs found"
                            : "No batch sink jobs created yet."
                    }
                />
            }
        />
    );
}

function filterDatasetBatchSinks(
    filters: Filter[],
    setSearchParams: (params: URLSearchParams) => void,
    force?: boolean
): BatchSinkRun["stats"] {
    const params = new URLSearchParams();
    if (filters.length) {
        filters.forEach((f) => {
            params.set(f.key.toString(), f.value.toString());
        });
        setSearchParams(params);
    } else if (force) {
        setSearchParams(params);
    }

    return [];
}

function buildSearchConfig(
    batchSinks: BatchSinkRun["stats"],
): Config {
    const allDatasetSet = new Set(
        batchSinks.map((d) => d.dataset_name.split(VERSION_SEPARATOR)[0])
    );
    const datasets = Array.from(allDatasetSet.keys()).sort();
    const datasetConfig = {
        icon: DatasetIcon,
        propertyName: "Dataset",
        kind: {
            kind: "single-select" as const,
            options: datasets.map((o) => ({ key: o, name: o, value: o })),
            valueCategory: "dataset",
            component: DatasetFilter,
            relationship: "has-one" as const,
        },
    };

    const filterOrder: string[] = [];
    filterOrder.push("dataset");

    return {
        filterOrder,
        filterConfigs: {
            dataset: datasetConfig,
        },
    };
}

const getBatchSinkText = (status: BatchSinkStatus) => {
    switch (status) {
        case BatchSinkStatus.COMPLETED:
            return "Finished";
        default:
            return "In Progress";
    }
};

const StatusPill = ({
    status,
}: {
    status: BatchSinkStatus;
}) => {
    return (
        <div className={styles.statusPill}>
            <BatchSinkIcon status={status} />
            {getBatchSinkText(status)}
        </div>
    );
};

export default BatchSinksPage;
