import SourceIcon from "icons/source.svg";
import KafkaIcon from "icons/kafka.svg";
import MySQLIcon from "icons/mysql.svg";
import PostgresIcon from "icons/postgres.svg";
import SnowflakeIcon from "icons/snowflake.svg";
import BigQueryIcon from "icons/bigquery.svg";
import WebhookIcon from "icons/webhook.svg";
import AWSIcon from "icons/aws.svg";
import styles from "./styles/SourcesFilter.module.scss";

function SourceTypeIcon({ type }: { type: string }): JSX.Element {
    switch (type) {
        case "S3":
            return <AWSIcon />;
        case "Bigquery":
            return <BigQueryIcon />;
        case "Snowflake":
            return <SnowflakeIcon />;
        case "MySql":
            return <MySQLIcon />;
        case "Postgres":
            return <PostgresIcon />;
        case "Webhook":
            return <WebhookIcon />;
        case "Kafka":
            return <KafkaIcon />;
        default:
            return <SourceIcon />;
    }
}

function SourcesFilter({
    name,
    metadata,
}: {
    name: string;
    metadata?: { [key: string]: string };
}): JSX.Element {
    return (
        <div className={styles.filter}>
            <div className={styles.sourceIcon}>
                <SourceTypeIcon type={metadata?.source_type || ""} />
            </div>
            <div className={styles.metadataContainer}>
                <div>{name}</div>
                <div className={styles.metadata}>{metadata?.table}</div>
            </div>
        </div>
    );
}

export default SourcesFilter;
